import { NgModule, Component, Input, forwardRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';

import { GlobalModule } from '@global/global.module';
import { MediaIconModule } from '@app/media/media-icon';

import { TypeMedia, MediaService } from '@app/media';
import { isEmpty } from '@helpers/utils';

@Component({
	selector: 'type-media-selector',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => TypeMediaSelectorComponent),
			multi: true
		}
	],
	template: `
		<p-multiSelect
			#multiSelect
			[name]="name"
			[required]="required"
			[options]="options"
			[(ngModel)]="_selected"
			[disabled]="disabled"
			[readonly]="readonly"
			[placeholder]="_placeholder"
			[showClear]="nullOption"
			[filter]="options.length > 5"
			filterBy="tym_libelle"
			[filterPlaceHolder]="filterPlaceHolder"
			[resetFilterOnHide]="true"
			optionValue="tym_code"
			optionLabel="tym_libelle"
			(ngModelChange)="updateValue($event)"
			appendTo="body"
			*ngIf="multiple"
		>
			<ng-template let-item pTemplate="item">
				<div class="flex align-items-center">
					<media-icon [tym_code]="item.tym_code" class="mr-2"></media-icon>
					<span>{{item.tym_libelle}}</span>
				</div>
			</ng-template>
		</p-multiSelect>

		<p-dropdown
			[name]="name"
			[required]="required"
			[options]="options"
			[(ngModel)]="value"
			[placeholder]="_placeholder"
			[disabled]="disabled"
			[showClear]="nullOption && options.length > 1"
			optionValue="tym_code"
			optionLabel="tym_libelle"
			[filter]="options.length > 5"
			filterBy="tym_libelle"
			[filterPlaceholder]="filterPlaceHolder"
			appendTo="body"
			*ngIf="!multiple"
		>
			<ng-template let-item pTemplate="item">
				<div class="flex align-items-center">
					<media-icon [tym_code]="item.tym_code" class="mr-2"></media-icon>
					<span>{{item.tym_libelle}}</span>
				</div>
			</ng-template>
		</p-dropdown>
	`
})
export class TypeMediaSelectorComponent implements ControlValueAccessor, OnInit {

	@Input() name: string = '';
	@Input('value') innerValue: string|string[]|null = null;
	@Input() required: boolean = false;
	@Input() disabled: boolean = false;
	@Input() readonly: boolean = false;
	@Input() placeholder: string = 'Sélectionnez';
	@Input() nullIfInvalid: boolean = false;
	@Input() nullOption: boolean = false;
	@Input() multiple: boolean = false;
	@Input() filterPlaceHolder: string = 'Filtrer';
	@Input() valuesAsIds: boolean = false;

	@Input() options: TypeMedia[] = [];

	_placeholder: string = ' ';
	_selected: any;

	constructor(private mediaService: MediaService) {

	}

	ngOnInit() {
		this.mediaService.getTypesMedias().subscribe(
			(response: any) => {
				this.prepareOptions(response)
			}
		);
	}

	onChange: any = () => { };
	onTouched: any = () => { };

	get value() {
		return this.innerValue;
	}

	set value(value: string|string[]|null) {
		this.innerValue = value;
		this.onChange(value);
		this.onTouched();
	}

	registerOnChange(fn: Function) {
		this.onChange = fn;
	}

	registerOnTouched(fn: Function) {
		this.onTouched = fn;
	}

	writeValue(value: string|string[]|null) {
		if (typeof value != 'undefined') {
			this.innerValue = value;
		}
	}

	updateValue(event: any) {
		let values: string|string[]|null = null;

		this.innerValue = values;
		this.onChange(values);
	}

	prepareOptions(types: any) {

		this._placeholder = (types.length)? this.placeholder : 'Aucun type disponible';
		this.options = types;
		this.setInitialValue();
	}

	setInitialValue() {
		if (this.innerValue) {
			let selected: any = [];
			let keys: any[] = (this.multiple && Array.isArray(this.innerValue))? [...<string[]>this.innerValue] : [this.innerValue];

			if (selected.length) {
				let value = (this.multiple)? selected : selected[0];
				if (!this.multiple && isEmpty(value)) value = null;
				this._selected = value;
			}
			else {
				this._selected = (this.multiple)? [] : null;
			}
		}
	}

	get label() {
		let value = this._selected || [];
		return value.length ? value.tym_libelle : this.placeholder;
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		MultiSelectModule,
		DropdownModule,
		GlobalModule,
		MediaIconModule,
	],
	exports: [TypeMediaSelectorComponent],
	declarations: [TypeMediaSelectorComponent]
})
export class TypeMediaSelectorModule { }
