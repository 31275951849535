export const environment = {
	production: true,
	aviva: {
		title: 'AvaDoc (preprod)',
		api: `https://${window.location.hostname}/api`, // https://avanet.aviva-preprod.grizzlydev.com
		foc: 'https://aviva.franchiseoncloud.fr',
		client_id: '6380c14a3032861d2cac0685',
		client_secret: 'XN7fX6i9d8VZzCjnf96xUHcRzf6emp6m72BUQ9CfdUsySyXxq2ZecVfoNxR2',
		analyticsId: ''
	},
	elton: {
		title: 'EltonDoc (preprod)',
		api: `https://${window.location.hostname}/api`, // https://eltonnet.aviva-preprod.grizzlydev.com
		foc: 'https://elton.franchiseoncloud.fr',
		client_id: '6380c1de3032861d2cac0689',
		client_secret: 'P4JCt3j6dDSqBebh6DEHsWfcuyP4eZnBw29W3tQdKLJVuai855pJ7bxSXxfG',
		analyticsId: ''
	},
	oauth_redirect: `https://${window.location.hostname}/callback`,
	refresh_token_minimal_timeout: 900000,
	websocket_heartbeat_interval: 60000,
	default_rows: 25,
	maxSectionDepth: 4,
	patternSlug: '[a-z0-9-]+'
};

export const enseigne = window.location.hostname.includes('elton') ? 'elton' : 'aviva';
