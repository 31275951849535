import { Component, NgModule, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIRouterModule } from '@uirouter/angular';
import { StateService } from '@uirouter/angular';

import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { MenuModule } from 'primeng/menu';

import { GlobalModule } from '@global/global.module';
import { IEventListener, EventManagerService } from '@global/event-manager.service';

import { BackButtonModule } from '@global/back-button';

import { Ressource } from '@app/ressource';
import { Media } from '@app/media';
import { MediaService } from '@app/media/media.service';
import { MediaDisplayModule } from '@app/media/media-display';
import { MediaIconModule } from '@app/media';

import { uid } from '@helpers/utils'

@Component({
	selector: 'media-view',
	templateUrl: './media-view.html'
})
export class MediaViewComponent implements OnInit, IEventListener  {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	@Input() media!: Media;
	@Input() ressource: Ressource|null = null;
	@Input() modal: boolean = false;

	menuItems: any[] = [];
	loading: boolean = false;

	constructor(
		private eventManager: EventManagerService,
		private mediaService: MediaService,
		private stateService: StateService,
	) {

	}

	ngOnInit() {
		// this.eventManager.registerEvent('media-click-event-manager', this, (args: any) => {
		// 	console.log('media-click-event-manager', args);
		// });
		// console.log('ress', this.ressource)

		if (this.media.med_url) {
			this.menuItems.push({
				label: 'Suivre le lien',
				icon: 'pi pi-external-link',
				url: this.media.med_url
			});
		}

		if (this.media.med_fichier) {
			this.menuItems.push({
				label: 'Télécharger',
				icon: 'pi pi-download',
				url: this.mediaService.getDownloadUrl(this.media),
				target: '_blank'
			});
		}

		this.menuItems.push({
			label: 'Voir la ressource parente',
			icon: `pi pi-eye`,
			command: () => {
				this.stateService.go('ressource', {res_code: this.media.res_code});
			}
		});

		this.menuItems.push({
			label: 'Copier le lien de partage',
			icon: `pi pi-share-alt`,
			command: () => {
				this.copyShareLink();
			}
		});


	}

	ngOnDestroy(): void {
		// this.eventManager.unregisterEvent('media-click-event-manager', this);
	}

	copyShareLink() {
		this.mediaService.copyShareLink(this.media);
	}


}
@NgModule({
	declarations: [
		MediaViewComponent
	],
	imports: [
		CommonModule,
		UIRouterModule,
		CardModule,
		GlobalModule,
		ButtonModule,
		MenuModule,
		MediaDisplayModule,
		MediaIconModule,
		BackButtonModule,
	],
})
export class MediaViewModule { }
