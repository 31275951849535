import { NgModule, Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { UIRouterModule, UIRouterGlobals } from '@uirouter/angular';
import { Observable } from 'rxjs';

import { TagModule } from 'primeng/tag';
import { TreeModule, Tree } from 'primeng/tree';
import { TreeNode } from 'primeng/api';

import { Section, SectionService } from '@app/section';
import { GlobalModule } from '@global/global.module';
import { IEventListener, EventManagerService } from '@global/event-manager.service';
import { VignetteDisplayModule } from '@app/vignette/vignette-display';

import { listToTree, uid } from '@helpers/utils';

@Component({
	selector: 'section-tree',
	templateUrl: './section-tree.html',
	providers: []
})
export class SectionTreeComponent implements OnInit, IEventListener {


	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	@ViewChild('sectionTree', {static: true}) treeComponent!: Tree;

	@Input() allExpanded: boolean = false;
	@Input() allSelectable: boolean = false;
	@Input() objectsOfUserOnly: boolean = true;
	@Input() selectionMode: string = '';
	@Input() expandedPath: number[] = [];
	@Input() orphanEntry: boolean = false;
	@Input() selectFirstWithStuff: boolean = false;
	@Input() showFilter: boolean = false;

	@Output() onNodeExpand: EventEmitter<any> = new EventEmitter();
	_onNodeExpand(event: any) {
		this.onNodeExpand.emit(event);
	}
	@Output() onNodeCollapse: EventEmitter<any> = new EventEmitter();
	_onNodeCollapse(event: any) {
		this.onNodeCollapse.emit(event);
	}
	@Output() onNodeSelect: EventEmitter<any> = new EventEmitter();
	_onNodeSelect(event: any) {
		this.onNodeSelect.emit(event);
	}

	values: Section[]= [];
	totalRecords: number = 0;
	loading: boolean = false;

	selection: any;
	selectedSecId: number|null = null
	expanded: TreeNode[] = [];

	tree: TreeNode[] = [];

	uiRouterGlobals: UIRouterGlobals;

	constructor(
		private sectionService: SectionService,
		private eventManager: EventManagerService,
		uiRouterGlobals: UIRouterGlobals
	) {
		this.uiRouterGlobals = uiRouterGlobals;
	}

	ngOnInit(): void {
		this.eventManager.registerEvent('section-updated', this, (args: any) => {
			this.sectionService.reset();
			this.loadSections(true);
		});
		this.eventManager.registerEvent('ressource-updated', this, (args: any) => {
			this.sectionService.reset();
			this.loadSections(true);
		});

		this.loadSections();
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('section-updated', this);
		this.eventManager.unregisterEvent('ressource-updated', this);
	}

	loadSections(refresh: boolean = false) {
		// https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
		Promise.resolve(null).then(() => this.loading = true);

		let getTree: Observable<any>|null = new Observable<any>();
		// this.saveParamSet();
		if (this.objectsOfUserOnly) {
			getTree = this.sectionService.getMesSections(refresh);
		}
		else {
			getTree = this.sectionService.getList(refresh);
		}

		getTree.subscribe((response: any) => {
			this.totalRecords = response.length;
			this.values = [...response];
			this.expanded = [];
			let tmpTree: any[] = listToTree(this.values, 'sec_id', 'sec_id_parent', null, 'children', true, 'sec_titre', undefined,  this.expandSection);
			if (this.orphanEntry) {
				let tmp : any = {sec_id: 0, label: 'Orphelines'};
				tmp = {
					label: tmp.label,
					data: tmp
				}
				tmpTree.unshift(tmp);
			}
			this.tree = tmpTree;
			this.autoselect();
		})
		.add(() => { this.loading = false; });
	}

	expandSection = (node: TreeNode) => {
		node.selectable = !!node.data.nb_ressources || this.allSelectable;
		if (
			this.allExpanded
			|| node.key && this.expandedPath.includes(parseInt(node.key.toString()))
			|| node.data.sec_ouvert_par_defaut
		) {
			node.expanded = true;
			if (this.expandedPath.includes(parseInt(node.key!.toString()))) {
				this.expanded.push(node);
			}
		}
	}

	autoselect() {
		if (this.selectionMode == 'single') {
			if (this.expandedPath.length && this.expandedPath.length == this.expanded.length) {
				this.selection = this.expanded.find((node: TreeNode) => {
					return node.key
						&& node.selectable
						&& parseInt(node.key.toString()) == this.expandedPath[this.expandedPath.length -1]
					;
				});
				if (this.selection) {
					this.onNodeSelect.emit({node: this.selection});
				}
				else {
					this.maybeOpenFirstWithStuff();
				}
			}
			else {
				this.maybeOpenFirstWithStuff();
			}
		}
	}

	maybeOpenFirstWithStuff() {
		if (this.selectFirstWithStuff) {
			let found: any = this.findFirstWithStuff();
			if (found) {
				this.onNodeSelect.emit({node: {data: found}, reload: true});
			}
		}
	}

	findFirstWithStuff(level: number = 1): Section|null{
		let candidates: Section[] = this.values.filter((section: Section) => { return section.sec_niveau == level; });
		let gotcha: Section|null = null;
		if (candidates.length == 0) {
			return null;
		}
		for (let i = 0; i < candidates.length; i++) {
			if (candidates[i].nb_ressources > 0) {
				gotcha = candidates[i];
				break;
			}
		}
		if (gotcha) return gotcha;
		return this.findFirstWithStuff(level +1);
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		UIRouterModule,
		TagModule,
		TreeModule,
		GlobalModule,
		VignetteDisplayModule,
	],
	exports: [SectionTreeComponent],
	declarations: [SectionTreeComponent]
})
export class SectionTreeModule { }

