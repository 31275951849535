<p-toolbar class="md:mb-2 flex-shrink-0" styleClass="pb-0">
	<ng-template pTemplate="left">
		<div class="p-fluid formgrid grid flex-grow-1">
			<div class="field p-inputgroup col-fixed max-width-md">
				<input pInputText type="text" [(ngModel)]="params.search" (onDebounce)="refresh()" placeholder="Rechercher..." />
				<button type="button" class="p-inputgroup-addon" icon="pi pi-times" pButton label="" [disabled]="!params.search || !params.search" (click)="reset();"></button>
			</div>

			<div class="field col-fixed">
				<type-media-selector
					name="sec_id"
					[(ngModel)]="params.tym_code"
					[nullOption]="true"
					placeholder="Type de média..."
					[disabled]="loading"
					(ngModelChange)="refresh()"
				></type-media-selector>
			</div>

			<div class="field col-fixed">
				<p-calendar
					name="date_debut"
					[(ngModel)]="params.date_debut"
					dateFormat="'Depuis le' dd/mm/yy"
					yearRange=":2030"
					[maxDate]="params.date_fin"
					[showIcon]="true"
					placeholder="Depuis le..."
					(ngModelChange)="refresh()"
				></p-calendar>
			</div>

			<div class="field col-fixed">
				<p-calendar
					name="date_fin"
					[(ngModel)]="params.date_fin"
					dateFormat="'Jusqu'''au dd/mm/yy"
					yearRange=":2030"
					[minDate]="params.date_debut"
					[showIcon]="true"
					placeholder="Jusqu'au..."
					(ngModelChange)="refresh()"
				></p-calendar>
			</div>

		</div>
	</ng-template>
</p-toolbar>

<p-table
	#tableBrowse
	[value]="values"
	[lazy]="true"
	(onLazyLoad)="load($event)"
	[loading]="loading"
	[paginator]="true"
	[rows]="rows"
	[showCurrentPageReport]="true"
	[totalRecords]="totalRecords"
	currentPageReportTemplate="{first} à {last} de {{totalRecords | number}} élément(s)"
	[rowsPerPageOptions]="[10,25,50]"
	styleClass="p-datatable-sm p-datatable-gridlines table-scrollable-hover"
	[autoLayout]="true"
	responsiveLayout="scroll"
	[scrollable]="true"
	scrollHeight="flex"
>
	<ng-template pTemplate="header">
		<tr>
			<th class="nowrap"></th>
			<th class="text-center nowrap">Type</th>
			<th class="">Nom</th>
			<th class="text-center nowrap">Date</th>
			<th></th>
		</tr>
	</ng-template>
	<ng-template pTemplate="emptymessage">
		<tr>
			<td class="text-center" colspan="100">Aucun résultat pour ces critères</td>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-item>
		<tr class="ligne-item">
			<td class="liste-media-thumb text-center">
				<div class="center-all">
					<vignette-display [vignette]="item.vig_id" *ngIf="!item.med_id"></vignette-display>

					<ng-container *ngIf="item.med_id" [ngSwitch]="item.tym_code">
						<media-display [media]="item" [showTitle]="false" *ngSwitchCase="'image'"></media-display>
						<media-icon [tym_code]="item.tym_code" *ngSwitchDefault=""></media-icon>
					</ng-container>
				</div>

			</td>
			<td class="nowrap text-center">
				<span *ngIf="item.med_id">Média</span>
				<span *ngIf="item.res_id && !item.med_id">Ressource</span>
				<span *ngIf="item.sec_id && !item.res_id">Section</span>
			</td>
			<td class="item-titre">
					<!--ressource-->
					<div *ngIf="!item.med_id && item.res_id">
						<a class="ressource-name text-color font-bold" [uiSref]="item._uiSref" [uiParams]="item._uiParams">{{item.res_titre}}</a>
						<div class="ressource-date-modification text-sm font-italic font-normal">Mis à jour le {{item.res_date_modification | date }}</div>
					</div>
					<!--media-->
					<ligne-media class="inline-grid" [media]="item" [titleAsLink]="true" [showThumb]="false" *ngIf="item.med_id"></ligne-media>
			</td>
			<td class="nowrap text-center text-sm">
				<div>{{item.his_date_consultation | date:'medium'}}</div>
			</td>

			<td class="text-left nowrap">
				<a
					pButton
					icon="pi pi-eye"
					class="p-button-rounded p-button-text"
					[uiSref]="item._uiSref"
					[uiParams]="item._uiParams"
				></a>
			</td>
		</tr>
	</ng-template>
</p-table>

