<h2 class="mt-0 flex-shrink-0 flex align-items-center">
	<back-button class="flex-shrink-0"></back-button>
	<vignette-display [vignette]="section.vig_id" class="mr-2 flex-shrink-0"></vignette-display>
	<span>{{section.sec_titre}}</span>
	<div class="flex-shrink-0 ml-auto">
		<button
			type="button"
			pButton
			icon="pi pi-bars"
			class="label-md-none"
			(click)="menu.toggle($event)"
			*ngIf="menuItems.length"
		></button>
		<p-menu #menu [popup]="true" [model]="menuItems" appendTo="body"></p-menu>
	</div>
</h2>
<dataview-ressources [sec_id]="section.sec_id"></dataview-ressources>
