import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { StateService } from '@uirouter/core';

import { IEventListener, EventManagerService } from '@global/event-manager.service';
import { Ressource } from '@app/ressource/ressource.model';
import { RelationService } from '@app/relation';
import { Media, MediaService } from '@app/media';

import {
	clone,
	copyTextToClipboard,
	createDownloadFromHttpResponse,
	prepareQueryParams,
	arrayOfObjectsToArrayOfValues,
	uid,
} from '@helpers/utils';

@Injectable({ providedIn: 'root' })
export class RessourceService implements IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	constructor(
		private eventManager: EventManagerService,
		private http: HttpClient,
		private mediaService: MediaService,
		private relationService: RelationService,
		private stateService: StateService,
	) {

	}

	ngOnInit() {
		this.eventManager.registerEvent('logout', this, (args: any) => {

		});
	}

	ngOnDestroy() {
		this.eventManager.unregisterEvent('logout', this);
	}


	public getList(params: any, admin?: boolean): Observable<any> {
		const tmpParams: any = prepareQueryParams(params);
		let url: string = '/ressources';
		if (admin) url = `/admin${url}`;
		return this.http.get<any>(url, tmpParams)
		.pipe(map(
			(response: any) => {
				response.ressources = this.prepareRessourcesFromServer(response.ressources);
				return response;
			}
		));
	}

	public get(res_id: number, admin?: boolean) {
		let url: string = '/ressources';
		if (admin) url = `/admin${url}`;
		url = `${url}/${res_id}`;
		return this.http.get<any>(url)
		.pipe(map(
			(response: any) => {
				return this.prepareRessourceFromServer(response);
			}
		));
	}

	public getByCode(res_code: number) {
		let url: string = '/ressources/code';
		url = `${url}/${res_code}`;
		return this.http.get<any>(url)
		.pipe(map(
			(response: any) => {
				return this.prepareRessourceFromServer(response);
			}
		));
	}

	public post(ressource: Ressource) {
		let tmp: any = this.prepareForPost(ressource);
		const url: string = '/admin/ressources';
		return this.http.post<any>(url, tmp);
	}

	public put(ressource: Ressource) {
		let tmp: any = this.prepareForPost(ressource);
		const url: string = `/admin/ressources/${ressource.res_id}`;
		return this.http.put<any>(url, tmp);
	}

	public prepareForPost(ressource: Ressource) {
		let tmp: any = clone(ressource);
		tmp.restrictions = this.relationService.prepareForPost(tmp.restrictions);
		tmp.responsabilites = this.relationService.prepareForPost(tmp.responsabilites);
		tmp.sections = arrayOfObjectsToArrayOfValues(tmp.sections, 'sec_id');
		Ressource.convertDateFieldsToString(tmp);
		// on gère les médias séparément
		delete tmp.medias;
		return tmp;
	}

	public delete(res_id: number) {
		const url: string = `/admin/ressources/${res_id}`;
		return this.http.delete<any>(url);
	}

	export(params: any) {
		const tmpParams = prepareQueryParams(params, true);
		return this.http.get<any>(`/admin/ressources/export`, tmpParams)
		.pipe(
			map((response: any) => {
				createDownloadFromHttpResponse(response);
				return true;
			})
		);
	}

	public prepareRessourceFromServer(ressource: Ressource): Ressource {
		ressource = Object.assign(new Ressource(), ressource);
		if (ressource.restrictions) {
			this.relationService.format(ressource.restrictions);
		}
		if (ressource.responsabilites) {
			this.relationService.format(ressource.responsabilites);
		}
		Ressource.convertDateFieldsToDate(ressource, true);
		ressource.mots_clefs = ressource.mots_clefs? ressource.mots_clefs.map((one: any) => {return one.moc_libelle;}) : [];
		if (ressource.medias && Array.isArray(ressource.medias)) {
			this.mediaService.prepareMediasFromServer(ressource.medias);
			ressource.nb_medias_telechargeables = ressource.medias.filter((one: Media) => { return !one.med_habillage; }).length;
		}
		return ressource;
	}

	public prepareRessourcesFromServer(ressources: Ressource[]): Ressource[] {
		ressources.forEach((ressource: Ressource, index: number) => {
			ressources[index] = this.prepareRessourceFromServer(ressource);
		});
		return ressources;
	}

	public addArchive(res_id: number) {
		let url: string = `/admin/ressources/${res_id}/archive`;
		return this.http.post<any>(url, undefined);
	}

	public deleteArchive(res_id: number) {
		let url: string = `/admin/ressources/${res_id}/archive`;
		return this.http.delete<any>(url);
	}

	public addFavoris(res_id: number) {
		let url: string = `/ressources/${res_id}/favoris`;
		return this.http.post<any>(url, undefined);
	}

	public deleteFavoris(res_id: number) {
		let url: string = `/ressources/${res_id}/favoris`;
		return this.http.delete<any>(url);
	}

	public getShareLink(ressource: Ressource) {
		return this.stateService.href('ressource_direct', {res_code: ressource.res_code});
	}

	public copyShareLink(ressource: Ressource) {
		const state: string = this.getShareLink(ressource);
		const href = `${window.location.origin}${state}`;
		if (copyTextToClipboard(href)) {
			this.eventManager.emit('toast', {severity: 'success', summary: 'Lien copié', detail: href});
		}
	}

}
