

	<div class="flex justify-content-between align-items-center flex-shrink-0 mb-2">
		<h1 class="flex align-items-center m-0" *ngIf="!modal">
			<back-button class="flex-shrink-0"></back-button>

			<div class="vignette-display mr-2" [ngSwitch]="media.tym_code">
				<div *ngSwitchCase="'image'" class="center-all">
					<media-display [media]="media" [showTitle]="false"></media-display>
				</div>

				<ng-container *ngSwitchDefault="">
					<media-icon [tym_code]="media.tym_code"></media-icon>
				</ng-container>
			</div>

			<span>{{media.med_titre}}</span>

		</h1>

		<div class="flex-shrink-0">
			<button
				type="button"
				pButton
				icon="pi pi-bars"
				class="label-md-none"
				[loading]="loading"
				(click)="menu.toggle($event)"
				*ngIf="menuItems.length"
			></button>
			<p-menu #menu [popup]="true" [model]="menuItems" appendTo="body"></p-menu>
		</div>
	</div>

	<p-card>

		<media-display [media]="media" [consultation]="true" [showPdf]="true" *ngIf="media._type != 'lien'"></media-display>

		<ng-template pTemplate="footer">

			<div *ngIf="media.med_url">
				<div>Url : <a href="{{media.med_url}}">{{media.med_url}}</a></div>
			</div>
			<div *ngIf="media.med_fichier">
				<div class="text-sm" *ngIf="media.med_fichier != media.med_titre">Nom fichier: {{media.med_fichier}}</div>
				<div class="text-sm">Taille : {{media.med_taille!|formatSize}}</div>
			</div>

			<div class="mt-2" *ngIf="media.med_description">{{media.med_description}}</div>
		</ng-template>

	</p-card>

