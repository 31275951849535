<p-tree
	[value]="tree"
	scrollHeight="flex"
	[(selection)]="selection"
	[selectionMode]="selectionMode"
	[filter]="showFilter"
	filterPlaceholder="Filtrer les sections..."
	(onNodeSelect)="_onNodeSelect($event)"
	(onNodeExpand)="_onNodeExpand($event)"
	(onNodeCollapse)="_onNodeCollapse($event)"
>
	<ng-template let-node pTemplate="default">
		<div class="flex align-items-center" [ngClass]="{'p-highlight': node.data.sec_id == uiRouterGlobals.params.sec_id}">
			<vignette-display [vignette]="node.data"></vignette-display>
			<span>{{node.label}}</span>
			<p-tag class="tag-soft ml-2" value="{{node.data.nb_ressources}}" icon="pi pi-file" *ngIf="node.data.nb_ressources"></p-tag>
		</div>
	</ng-template>

</p-tree>
