<p-dataView
	#tableRessources
	[value]="_medias"
	[paginator]="false"
	[rows]="999"
	[showCurrentPageReport]="false"
	[layout]="layout"
>

	<ng-template let-media pTemplate="listItem">

		<div class="grid m-0 col-12" *ngIf="media.med_visibilite_totale || showVisibilitePartielle && media.med_visibilite_totale == false">
			<div
				class="col-fixed liste-media-thumb center-all"
				title="{{ media.med_titre }}"
				[ngSwitch]="media._type"
			>
				<media-display
					[media]="media"
					[showTitle]="false"
					class="center-all"
					[ngClass]="{'cursor-pointer': media._displayableImage}"
					(onMediaClick)="_onMediaClick($event)"
					*ngSwitchCase="'image'"
				></media-display>

				<media-icon [tym_code]="media.tym_code" *ngSwitchDefault=""></media-icon>
			</div>

			<div class="col">
				<div class="font-bold flex align-items-start">
					<span class="cursor-pointer word-break-all" title="{{media.med_titre}}" uiSref="media" [uiParams]="{med_code: media.med_code}">{{media.med_titre}}</span>
					<button type="button" pButton icon="pi pi-sort-down" class="p-button-xs p-button-text flex-shrink-0" (click)="menu_media.toggle($event)"></button>
				</div>
				<ng-container *ngIf="media.med_fichier">
					<div class="text-sm" *ngIf="media.med_fichier != media.med_titre">Nom fichier: {{media.med_fichier}}</div>
					<div class="text-sm">Taille: {{ media.med_taille!|formatSize}}</div>
				</ng-container>

				<ng-container *ngIf="['lien', 'video'].includes(media.tym_code)">
					<div class="text-sm word-break-all">
						<a href="{{media.med_url}}">{{media.med_url}}</a>
					</div>
				</ng-container>
				<div class="font-italic">{{media.med_description}}</div>
			</div>
		</div>
		<p-menu #menu_media id="menu-{{media.med_id}}" [model]="media._menu_items" [popup]="true" appendTo="body"></p-menu>
	</ng-template>

	<ng-template let-media pTemplate="gridItem">
		<div class="col-12 lg:col-fixed" *ngIf="media.med_visibilite_totale || showVisibilitePartielle && media.med_visibilite_totale == false">
			<div class="border-1 border-round-sm surface-border grid m-0 align-items-center h-full">
				<div
					class="col-fixed liste-media-thumb center-all"
					title="{{ media.med_titre }}"
					[ngSwitch]="media._type"
				>
					<media-display [media]="media" [showTitle]="false" (onMediaClick)="_onMediaClick($event)" *ngSwitchCase="'image'"></media-display>
					<media-icon [tym_code]="media.tym_code" *ngSwitchDefault=""></media-icon>
				</div>

				<div class="col-fixed">
					<div class="font-bold grid grid-nogutter align-items-center">
						<span class="col flex-shrink-1 overflow-hidden white-space-nowrap text-overflow-ellipsis" title="{{media.med_titre}}" >
							<span class="cursor-pointer" uiSref="media" [uiParams]="{med_code: media.med_code}">{{media.med_titre}}</span>
						</span>
						<button
							type="button"
							pButton
							icon="pi pi-sort-down"
							class="p-button-xs p-button-text col-fixed"
							(click)="menu_media.toggle($event)"
						></button>
						<i class="pi pi-question-circle" pTooltip="{{media.med_description}}" tooltipPosition="left" *ngIf="media.med_description"></i>
					</div>

					<div class="text-sm" *ngIf="media.med_fichier">Taille: {{ media.med_taille!|formatSize}}</div>

					<div class="text-sm overflow-hidden white-space-nowrap text-overflow-ellipsis"  *ngIf="['lien', 'video'].includes(media.tym_code)">
						<a href="{{media.med_url}}">{{media.med_url}}</a>
					</div>

				</div>
			</div>
			<p-menu #menu_media id="menu-{{media.med_id}}" [model]="media._menu_items" [popup]="true" appendTo="body"></p-menu>
		</div>


	</ng-template>




</p-dataView>
