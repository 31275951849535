import { NgModule, Component, Input, Output, EventEmitter} from '@angular/core';
import { CommonModule } from '@angular/common';

import { Media } from '@app/media';
import { MediaDisplayModule } from '@app/media/media-display';
import { MediaIconModule } from '@app/media';
import { GlobalModule } from '@global/global.module';

@Component({
	selector: 'ligne-media',
	templateUrl: './ligne-media.html',
	providers: []
})
export class LigneMediaComponent {

	@Input() media!: Media;
	@Input() showId: boolean = false;
	@Input() showThumb: boolean = true;
	@Input() showSize: boolean = true;
	@Input() showLink: boolean = true;
	@Input() showDateCreation: boolean = true;
	@Input() titleAsLink: boolean = false;
	@Output() onTitleClick: EventEmitter<any> = new EventEmitter();

	constructor() { }

	titleClick() {
		this.onTitleClick.emit(this.media);
	}
}


@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		MediaDisplayModule,
		MediaIconModule
	],
	exports: [LigneMediaComponent],
	declarations: [LigneMediaComponent]
})
export class LigneMediaModule { }

