export class Vignette {
	vig_id: number = 0;
	vig_date_creation: string|Date = new Date();
	vig_libelle: string = '';
	vig_fichier: string = '';
	vig_standard: boolean = false;
	_vig_mot_clef: string|null = null;
	get vig_mot_clef(): string|null {
		return this._vig_mot_clef;
	}
	set vig_mot_clef(value: string|null) {
		this._vig_mot_clef = (typeof value == 'string')? value.trim() : null;
	}

	url: string = '';
}
