import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({name: 'formatSize'})
export class FormatSizePipe implements PipeTransform {

	constructor(private decimalPipe: DecimalPipe) {}

	transform(bytes?: number): string {
		if (typeof bytes == 'undefined' || bytes == 0) {
			return '0 B';
		}
		let k = 1024;
		let dm = 3;
		let sizes = ['o', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'];
		let i = Math.floor(Math.log(bytes) / Math.log(k));
		let formatted = this.decimalPipe.transform(parseFloat((bytes / Math.pow(k, i)).toFixed(dm)));
		return `${formatted} ${sizes[i]}`;
	}
}
