<form class="mb-2" #vignetteForm="ngForm">
	<div class="p-fluid formgrid grid">

		<div class="field col-12">
			<label>Libellé</label>
			<input
				type="text"
				pInputText
				name="vig_libelle"
				#vig_libelle
				ngModel
				[(ngModel)]="vignette.vig_libelle"
				[required]="!!vignette.vig_id"
			>
		</div>

		<div class="field col-12">
			<label>Mot clef optionnel</label>
			<p-autoComplete
				name="vig_mot_clef"
				#vig_mot_clef
				[(ngModel)]="vignette.vig_mot_clef"
				[suggestions]="availableKeyWords"
				[dropdown]="true"
				[maxlength]="100"
				(onKeyUp)="onKeywordAutoCompleteKeyUp($event)"
				(completeMethod)="searchKeywords($event)"
				appendTo="body"
			></p-autoComplete>
		</div>

		<div class="col-12 grid field">
			<div class="field col-6 flex flex-column justify-content-end">
				<div class="field-checkbox mb-2">
					<p-checkbox
						name="vig_standard"
						#vig_standard
						ngModel
						[(ngModel)]="vignette.vig_standard"
						[ngModelOptions]="{standalone: true}"
						[binary]="true"
						label='Vignette "standard"'
					></p-checkbox>
				</div>
			</div>
		</div>
	</div>

	<div class="formgrid grid">
		<div class="field col-12 md:col-12" *ngIf="!vignette.vig_id">
			<p-fileUpload
				#uploader
				name="file"
				chooseLabel="Fichier..."
				[url]="urlUpload"
				[accept]="acceptedMimeTypes"
				[fileLimit]="fileLimit"
				[maxFileSize]="maxFileSize"
				[showUploadButton]="false"
				[showCancelButton]="false"
				[disabled]="uploader.uploading"
				[invalidFileLimitMessageDetail]="invalidFileLimitMessageDetail"
				[invalidFileLimitMessageSummary]="invalidFileLimitMessageSummary"
				[invalidFileSizeMessageDetail]="invalidFileSizeMessageDetail"
				[invalidFileSizeMessageSummary]="invalidFileSizeMessageSummary"
				[invalidFileTypeMessageDetail]="invalidFileTypeMessageDetail"
				[invalidFileTypeMessageSummary]="invalidFileTypeMessageSummary"
				(onBeforeUpload)="onBeforeUpload($event)"
				(onUpload)="onUpload($event)"
				(onError)="onError($event)"
			>

				<ng-template pTemplate="toolbar">
					<button
						type="button"
						pButton
						icon="pi pi-times"
						label="Vider"
						[disabled]="!uploader.files.length || uploader.uploading"
						(click)="clear()"
					></button>
					<button
						type="button"
						pButton
						icon="pi pi-upload"
						label="Envoyer"
						[disabled]="!uploader.files.length || uploader.uploading"
						(click)="upload()"
					></button>
					<button
						type="button"
						pButton
						icon="pi pi-ban"
						label="Annuler"
						[disabled]="!uploader.uploading"
						(click)="abort()"
					></button>
				</ng-template>

				<ng-template pTemplate="content" let-files>
					<div class="text-center clickable" *ngIf="!uploader.files.length" (click)="uploader.choose()">
						<div><i class="pi pi-icon pi-file"></i>Déposez ou sélectionnez un fichier</div>
						<div><small>Fichiers acceptés : {{acceptedExtensions}} </small></div>
						<div><small>Taille fichier maximum : {{maxFileSize|formatSize}}</small></div>
					</div>
				</ng-template>

				<ng-template let-file let-index="index" pTemplate="file">
					<div class="flex align-items-center justify-content-stretch">
						<div class="p-fileupload-thumb">
							<media-icon [file]="file"></media-icon>
						</div>
						<div class="p-fileupload-file-info">
							<div>
							<div class="overflow-hidden white-space-nowrap text-overflow-ellipsis pr-2">{{file.name}}</div>
							<div><small>{{ file.size | formatSize }}</small></div>
						</div>
						</div>

						<div class="p-fileupload-button ml-auto flex-shrink-0">
							<button
								type="button"
								pButton
								class="p-button-xs"
								icon="pi pi-times"
								[disabled]="uploader.uploading"
								(click)="remove(file)"
							></button>
						</div>
					</div>
				</ng-template>

			</p-fileUpload>
		</div>

	</div>

	<div class="text-center" *ngIf="vignette.vig_id">
		<button
			type="button"
			pButton
			class="btn btn-primary"
			label="Enregistrer"
			[disabled]="!vignetteForm.form.valid || (uploader && uploader.uploading)"
			(click)="submit()"
		></button>
	</div>

</form>
