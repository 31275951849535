import {
	NgModule,
	Component,
	OnInit,
	Input,
	ChangeDetectionStrategy,
	Output,
	EventEmitter,
	ElementRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIRouterModule } from '@uirouter/angular';

import { GlobalModule } from '@global/global.module';

import { Ressource } from '@app/ressource';

@Component({
	selector: 'ressource-link',
	template: `<a uiSref="ressource_direct_id" [uiParams]="{res_id:res_id}"><ng-content></ng-content></a>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RessourceLinkComponent implements OnInit {

	@Input() ressource: Ressource|null = null;
	@Input() res_id: number = 0;
	@Input() maxThumbSize: number = 0;
	@Output() onRessourceClick: EventEmitter<any> = new EventEmitter();

	el: any;

	constructor(
		private elementRef: ElementRef,
	) {
		this.el = this.elementRef.nativeElement;
	}

	ngOnInit(): void {

	}

}


@NgModule({
	declarations: [RessourceLinkComponent],
	exports: [
		RessourceLinkComponent,
	],
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule
	]
})
export class RessourceLinkModule { }
