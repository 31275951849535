<p-dataView
	#tableRessources
	[value]="values"
	(onLazyLoad)="load($event)"
	[loading]="loading"
	[paginator]="true"
	[rows]="rows"
	[showCurrentPageReport]="true"
	[totalRecords]="totalRecords"
	currentPageReportTemplate="{first} à {last} de {{totalRecords | number}} élément(s)"
	[rowsPerPageOptions]="[10,25,50]"
>
<!-- 	<ng-template pTemplate="header">
		<div class="flex flex-column md:flex-row md:justify-content-between">
			<p-dataViewLayoutOptions></p-dataViewLayoutOptions>
		</div>
	</ng-template> -->

	<ng-template let-ressource pTemplate="listItem">
		<div class="col-12 surface-card border-round-sm overflow-hidden mb-2 shadow-1">
			<a class="grid ressource-entry m-0 align-items-center text-color-inherit" uiSref=".ressource" [uiParams]="{res_code: ressource.res_code}">

				<div class="col-fixed flex-shrink-0 ressource-entry-vignette">
					<vignette-display [vignette]="ressource.vig_id"></vignette-display>
				</div>

				<div class="col flex-grow-1 ressource-entry-title">
					<div class="grid align-items-center">
						<div class="col flex-grow-1">
							<h3 class="ressource-name flex align-items-center">
								<div>{{ressource.res_titre}}</div>
								<favori-toggle-button class="flex-shrink-0" [ressource]="ressource"></favori-toggle-button>
							</h3>
							<div class="ressource-date-modification text-sm font-italic font-normal">Mis à jour le {{ressource.res_date_modification | date }}</div>
						</div>
					</div>
				</div>
			</a>

			<div class="grid grid-nogutter pl-0 pr-0 col-12 align-items-stretch" *ngIf="ressource.hasMediaVisibiliteTotale()">
				<div class="col-fixed flex-shrink-0 liste-media-thumb-fake"></div>
				<div class="col">
					<medias-display [medias]="ressource.medias" layout="grid"></medias-display>
				</div>

			</div>

		</div>

	</ng-template>

	<ng-template let-ressource pTemplate="gridItem">

	</ng-template>
</p-dataView>

