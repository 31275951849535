import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UIRouterModule, UIView } from '@uirouter/angular';

import { routerConfigFn } from '@global/app-routing.config';
import { states } from '@global/app-routing.states';

@NgModule({
	imports: [
		UIRouterModule.forRoot({
			states: states,
			useHash: false,
			initial: { state: 'app' },
			otherwise: { state: 'home', params: {}, options: { reload: true }},
			config: routerConfigFn,
		})
	],
	exports: [UIRouterModule]
})
export class AppRoutingModule { }
