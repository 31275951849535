import { NgModule, Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { DataViewModule } from 'primeng/dataview';
import { TooltipModule } from 'primeng/tooltip';

import { Media } from '@app/media';
import { MediaDisplayModule } from '@app/media/media-display';
import { MediaIconModule } from '@app/media';
import { GlobalModule } from '@global/global.module';


@Component({
	selector: 'medias-display',
	templateUrl: './medias-display.html',
})
export class MediasDisplayComponent implements OnInit {


	@Input() layout: 'list'|'grid' = 'list';
	@Input() showVisibilitePartielle: boolean = false;
	@Output() onMediaClick: EventEmitter<any> = new EventEmitter();

	_medias: Media[] = [] ;
	@Input('medias')
	set medias(value: Media[]) {
		this._medias = (Array.isArray(value))? value.filter((one: Media) => { return !one.med_habillage; }) : [];
	}
	get medias(): Media[] {
		return this._medias;
	}


	constructor() {

	}

	ngOnInit(): void {

	}

	_onMediaClick(media: Media) {
		this.onMediaClick.emit(media);
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		MenuModule,
		DataViewModule,
		GlobalModule,
		MediaDisplayModule,
		MediaIconModule,
		TooltipModule
	],
	exports: [MediasDisplayComponent],
	declarations: [MediasDisplayComponent]
})
export class MediasDisplayModule { }

