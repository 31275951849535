import { NgModule, AfterViewInit, ComponentRef, Component, ElementRef, EventEmitter, OnDestroy, Self, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIRouterModule } from '@uirouter/angular';

import { DynamicComponentFactory, DynamicComponentFactoryFactory } from '@app/dynamic-component';

import { EmbedVideoService } from '@app/embed-video';
import { MediaDisplayModule, MediaDisplayComponent } from '@app/media';
import { RessourceLinkModule, RessourceLinkComponent } from '@app/ressource/ressource-link';
import { Media } from '@app/media';
import { Ressource } from '@app/ressource';

import {
	removeTrailingChars,
	removeLeadingChars,
} from '@helpers/utils';


@Component({
	selector: 'ressource-renderer',
	template: ``,
	providers: [
		DynamicComponentFactoryFactory,
	],
})
export class RessourceRendererComponent implements AfterViewInit, OnDestroy {

	@Input() ressource!: Ressource;
	@Output() onMediaClick: EventEmitter<any> = new EventEmitter();

	private readonly factories: DynamicComponentFactory<any>[] = [];
	private readonly hostElement: Element;

	// components' classes that will need to be handled
	components: any[] = [
		MediaDisplayComponent,
		RessourceLinkComponent
	];

	constructor(
		private cmpFactory: DynamicComponentFactoryFactory,
		private elementRef: ElementRef,
		private embedVideoService: EmbedVideoService
	) {
		this.hostElement = elementRef.nativeElement;
	}

	ngAfterViewInit(): void {
		if (this.ressource.res_html) {
			this.hostElement.innerHTML = this.replaceShortCodes(this.ressource.res_html);
		}
		// console.log('result', this.hostElement.innerHTML);
		this.initFactories();
		this.createAllComponents();
	}

	private initFactories(): void {
		this.components.forEach(oneComponent => {
			const factory = this.cmpFactory.create(oneComponent);
			this.factories.push(factory);
		});
	}

	// Create components dynamically
	private createAllComponents(): void {
		const el = this.hostElement;
		const compRefs: ComponentRef<any>[] = [];
		this.factories.forEach(factory => {
			const comps = factory.create(el);
			compRefs.push(...comps);
			// // Here you can make use of compRefs, filter them, etc.
			// // to perform any custom operations, if required.
			// compRefs
			// 	.filter(c => c.instance instanceof YourComponent2)
			// 	.forEach(c => {
			// 		c.instance.name = 'hello';
			// 		c.instance.filtering = 'false';
			// 		c.instance.someFoo('welcome');
			// 	});
			compRefs
				.filter((oneComponent: ComponentRef<any>) => { return oneComponent.instance instanceof MediaDisplayComponent; })
				.forEach((oneComponent: ComponentRef<any>) => {
					let found: Media|undefined = this.ressource.medias.find((one: Media) => {
						return one.med_id == oneComponent.instance.med_id;
					});
					if (found) {
						oneComponent.instance.media = found;
						oneComponent.instance.onMediaClick.subscribe(this._onMediaClick);
					}
				});

		});
	}

	private removeAllComponents(): void {
		this.factories.forEach(f => f.destroy());
	}

	ngOnDestroy(): void {
		this.removeAllComponents();
	}

	// onImgClick(event: any) {
	// 	console.log('onImgClick', event)
	// 	this.imgclick.emit(event);
	// }


	replaceShortCodes(str: string): string {
		let result: string = str;

		result = this.replaceMediaShortcodes(result);
		result = this.replaceRessourceShortcodes(result);

		return result;
	}

	replaceRessourceShortcodes(content: string) {
		return content.replace(
			/\[ressource=(?<res_id>[^\D]+)(?<attrs> *[^\[]*)\](?<title>[^\[]+)\[\/ressource\]/g,
			(match: any,
				res_id: string,
				captureAttrs: string,
				title: string,
				offset: any,
				sourceString: any,
				groups: any
			) => {

				let embed: string = '';
				let styleAttrs: string = this.prepareStyleAttrs(captureAttrs);
				embed = `<ressource-link res_id="${res_id}" ${styleAttrs}>${title}</ressource-link>`;
				return embed as string;
			}
		);
	}

	replaceMediaShortcodes(content: string) {
		return content.replace(
			/\[media=(?<med_id>[^\D]+)(?<attrs> *[^\[]*)\]/g,
			(match: any,
				med_id: string,
				captureAttrs: string,
				offset: any,
				sourceString: any,
				groups: any
			) => {
				// console.log('capture media', med_id);
				// console.log('captureAttrs', captureAttrs);
				// console.log('offset', offset);
				// console.log('sourceString', sourceString);
				// console.log('groups', groups);
				let found: Media|undefined = this.ressource.medias.find((one: Media) => {
					return one.med_id == Number.parseInt(med_id);
				});
				if (!found) {
					// console.log('media not found on ressource', med_id);
					return match;
				}
				// console.log('media found on ressource', found);

				let embed: string = '';
				let styleAttrs: string = this.prepareStyleAttrs(captureAttrs);
				embed = `<media-display med_id="${found.med_id}" ${styleAttrs}></media-display>`;
				return embed as string;
			}
		);
	}

	prepareStyleAttrs(captureAttrs: any) {
		let tailleVignetteMax: string = '';
		let styleClass: string = '';
		let style: string = '';

		if (captureAttrs) {
			let regex = / *(?<attr>[^=]+)=(?<value>(("[^"]+")|\w+)) */g;
			var matchAttr = regex.exec(captureAttrs);
			if (!!matchAttr && !!matchAttr[1]) {
				regex.lastIndex = 0;

				while ((matchAttr = regex.exec(captureAttrs)) !== null) {
					if (matchAttr.index === regex.lastIndex) {
						regex.lastIndex++;
					}
					let key = matchAttr[1].trim();
					let value = matchAttr[2].trim();
					// console.log('matchAttr[1]', key, value);
					if (typeof key != 'undefined' && typeof value != 'undefined') {
						value = removeLeadingChars(value, '"');
						value = removeTrailingChars(value, '"');
						switch (key) {
							case 'tailleVignetteMax':
								tailleVignetteMax = value;
								break;
							case 'style':
								style = value;
								break;
							case 'class':
								styleClass = value;
								break;
						}
					}
				}
			}
		}

		let result: string = '';
		if (tailleVignetteMax) result = `${result} maxThumbSize="${tailleVignetteMax}"`;
		if (style) result = `${result} style="${style}"`;
		if (styleClass) result = `${result} class="${styleClass}"`;
		return result;
	}

	_onMediaClick = (event: any) => {
		// console.log('ressource-renderer', 'onMediaClick', event);
		this.onMediaClick.emit(event);
	}

}
@NgModule({
	declarations: [
		RessourceRendererComponent
	],
	imports: [
		CommonModule,
		MediaDisplayModule,
		RessourceLinkModule,
		UIRouterModule
	],
	exports: [
		RessourceRendererComponent
	]
})
export class RessourceRendererModule { }
