import { NgModule, Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GlobalModule } from '@global/global.module';
import { MediaService } from '@app/media/media.service';

@Component({
	selector: 'media-icon',
	template: `
		<ng-container	[ngSwitch]="tym_code">
			<ng-container *ngSwitchCase="'image'">
				<img [src]="file!.objectURL" *ngIf="isFile && displayableImage" loading="lazy"/>
				<span><i class="text-xl pi pi-icon pi-image" *ngIf="!isFile || !displayableImage"></i></span>
			</ng-container>
			<i *ngSwitchCase="'video'" class="text-xl pi pi-video"></i>
			<i *ngSwitchCase="'lien'" class="text-xl pi pi-external-link"></i>
			<i *ngSwitchCase="'excel'" class="text-xl pi pi-file-excel"></i>
			<i *ngSwitchCase="'doc'" class="text-xl pi pi-file-word"></i>
			<i *ngSwitchCase="'pdf'" class="text-xl pi pi-file-pdf"></i>
			<i *ngSwitchCase="'texte'" class="text-xl pi pi-align-left"></i>
			<i *ngSwitchDefault="" class="text-xl pi pi-file"></i>
		</ng-container>
	`,
	providers: [],
})
export class MediaIconComponent implements OnInit {

	@Input() tym_code: any = null;
	@Input() file?: any;

	isFile: boolean = false;
	displayableImage: boolean = false;

	constructor(private mediaService: MediaService) {

	}

	ngOnInit(): void {
		if (this.file) {
			this.isFile = this.file instanceof File;
			this.displayableImage = this.mediaService.isDisplayableImage(this.file);
			this.tym_code = this.mediaService.getFileType(this.file);
		}
	}


}


@NgModule({
	imports: [
		CommonModule,
		GlobalModule
	],
	exports: [
		MediaIconComponent,
	],
	declarations: [MediaIconComponent]
})
export class MediaIconModule { }
