import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Media, MediaService } from '@app/media';
import { Ressource, RessourceService } from '@app/ressource';
import { Section, SectionService } from '@app/section';

import { prepareQueryParams, uid } from '@helpers/utils';


export type TypeElementArborescence = Section|Ressource|Media;


@Injectable({ providedIn: 'root' })
export class BrowseService {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	constructor(
		private http: HttpClient,
		private mediaService: MediaService,
		private ressourceService: RessourceService,
		private sectionService: SectionService,
	) {

	}

	ngOnInit() {

	}


	public getListFavoris(params: any): Observable<any> {
		const tmpParams: any = prepareQueryParams(params);
		let url: string = '/favoris';
		return this.http.get<any>(url, tmpParams)
		.pipe(map(
			(response: any) => {
				response.favoris = this.prepareItemsFromServer(response.favoris);
				return response;
			}
		));
	}

	public getListHistorique(params: any): Observable<any> {
		const tmpParams: any = prepareQueryParams(params);
		let url: string = '/historiques';
		return this.http.get<any>(url, tmpParams)
		.pipe(map(
			(response: any) => {
				response.historiques = this.prepareItemsFromServer(response.historiques);
				return response;
			}
		));
	}

	public getListSearch(params: any): Observable<any> {
		const tmpParams: any = prepareQueryParams(params);
		let url: string = '/recherche';
		return this.http.get<any>(url, tmpParams)
		.pipe(map(
			(response: any) => {
				response.resultats = this.prepareItemsFromServer(response.ressources);
				return response;
			}
		));
	}

	public prepareItemFromServer(item: any): TypeElementArborescence {

		if (item.med_id) {
			// media
			item = this.mediaService.prepareMediaFromServer(item as Media);
			// item._uiParams = {med_code: item.med_code};
		}
		else if (item.res_id && !item.med_id) {
			//ressource
			item = this.ressourceService.prepareRessourceFromServer(item as Ressource);
			// item._uiParams = {res_code: item.res_code};
		}
		else if (item.sec_id && !item.res_id) {
			// section
			item = this.sectionService.prepareSectionFromServer(item as Section);
			// item._uiSref = 'section';
			// item._uiParams = {sec_code: item.sec_code};
		}
		return item;
	}

	public prepareItemsFromServer(items: TypeElementArborescence[]): TypeElementArborescence[] {
		items.forEach((item: TypeElementArborescence, index: number) => {
			items[index] = this.prepareItemFromServer(item);
		});
		return items;
	}



}
