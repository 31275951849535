import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subscriber } from 'rxjs';
import { map} from 'rxjs/operators';

import { IEventListener, EventManagerService } from '@global/event-manager.service';
import { GroupeUtilisateur } from '@app/groupe-utilisateur';
import { UtilisateurService } from '@app/utilisateur';
import {
	clone,
	formatNodePath,
	formatNodesPaths,
	listToTree,
	treeToList,
	uid
} from '@helpers/utils';


@Injectable({ providedIn: 'root' })
export class GroupeUtilisateurService implements IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	private _groupes: GroupeUtilisateur[] = [];
	private _mesGroupesUtilisateurs: GroupeUtilisateur[] = [];
	private _groupesTree: any = null;

	public get groupes() {
		return [...this._groupes];
	}

	public get mesGroupesUtilisateurs() {
		return [...this._mesGroupesUtilisateurs];
	}

	public get groupesTree() {
		return clone(this._groupesTree);
	}


	constructor(
		private http: HttpClient,
		private eventManager: EventManagerService,
		private utilisateurService: UtilisateurService,
	) {

	}

	ngOnInit() {
		this.eventManager.registerEvent('logout', this, (args: any) => {
			this.reset();
		});
	}

	ngOnDestroy() {
		this.eventManager.unregisterEvent('logout', this);
	}

	reset() {
		this._groupes = [];
		this._groupesTree = null;
	}

	getTree(refresh: boolean = false): Observable<any> {
		if (!refresh && this._groupesTree != null) {
			return new Observable<any>((subscriber: Subscriber<any>) => {
				subscriber.next(this.groupesTree);
				subscriber.complete();
			});
		}
		else {
			return this.getList(refresh)
			.pipe(map(
				(response) => {
					return this.groupesTree;
				}
			));
		}
	}

	getList(refresh: boolean = false): Observable<any> {
		if (!refresh && this._groupes.length > 0) {
			return new Observable<any>((subscriber: Subscriber<any>) => {
				subscriber.next(this.groupes);
				subscriber.complete();
			});
		}
		else {
			let url: string = '/groupes_foc';
			return this.http.get<any>(url)
			.pipe(map(
				(response: any) => {
					this._groupes = response;
					this.formatLabels(this._groupes, true);
					this.formatPaths(this._groupes, false);
					// on fait notre arbre
					this._groupesTree = listToTree(this._groupes, 'grf_id', 'grf_id_parent', null, 'children', true);
					// on le remet en mode liste
					this._groupes = treeToList(this.groupesTree, 'children', 'data');
					// on ajoute un index sur chaque élément pour pouvoir conserver l'ordre de l'arbre
					this._groupes.forEach((one: GroupeUtilisateur, index: number)=> {one.index = index;});
					this._mesGroupesUtilisateurs = this.filterMine(this.groupes);
					return this.groupes;
				}
			));
		}
	}

	getMesGroupesUtilisateurs(refresh: boolean = false) {
		if (!refresh && !!this._mesGroupesUtilisateurs.length) {
			return new Observable<any>((subscriber: Subscriber<any>) => {
				subscriber.next(this.mesGroupesUtilisateurs);
				subscriber.complete();
			});
		}
		else {
			return this.getList(refresh)
			.pipe(map(
				(response: any) => {
					return this.mesGroupesUtilisateurs;
				}
			));
		}
	}

	filterMine(groupes: GroupeUtilisateur[]) {
		return groupes.filter((one: GroupeUtilisateur) => {
			return this.utilisateurService.belongsToGroupeUtilisateur(one);
		});
	}

	find(grfCodeOrId: string|number): GroupeUtilisateur|undefined {
		let attr: keyof GroupeUtilisateur = 'grf_id';
		if (typeof grfCodeOrId == 'string') {
			attr = 'grf_code';
		}
		return this._groupes.find((one: GroupeUtilisateur) => {
			return one[attr] == grfCodeOrId;
		});
	}

	formatLabel(groupe: GroupeUtilisateur, showCode: boolean = false) {
		let result: string = groupe.grf_libelle;
		if (showCode) {
			result = `${result} (${groupe.grf_code})`;
		}
		return result;
	}

	formatLabels(groupes: GroupeUtilisateur[], showCode: boolean = false) {
		groupes.forEach((one: any) => {
			one.label = this.formatLabel(one, showCode);
		});
	}

	formatPath(groupe: GroupeUtilisateur, showCode: boolean = false, pathSeparator: string = '/',) {
		return formatNodePath(groupe, this.groupes, 'grf_id', 'grf_id_parent', (item: any) =>{return this.formatLabel(item, showCode)}, pathSeparator);
	}

	formatPaths(groupes: GroupeUtilisateur[], showCode: boolean = false, pathSeparator: string = '/',) {
		formatNodesPaths(groupes, 'grf_id', 'grf_id_parent', (item: any) =>{return this.formatLabel(item, showCode)}, pathSeparator);
	}

}
