import { Injectable } from '@angular/core';

import { arrayOfObjectsToArrayOfValues, clone } from '@helpers/utils';
import { GroupeUtilisateurService } from '@app/groupe-utilisateur';
import { MagasinService } from '@app/magasin';
import { Relation } from '@app/relation/relation.model';
import { UtilisateurService } from '@app/utilisateur';
import { numericSort } from '@helpers/utils';

@Injectable({ providedIn: 'root' })
export class RelationService {

	constructor(
		private groupeUtilisateurService: GroupeUtilisateurService,
		private magasinService: MagasinService,
		private utilisateurService: UtilisateurService,
	) {

	}

	ngOnInit() {

	}

	prepareForPost(relation: Relation): any {
		let tmp: any = clone(relation);
		tmp.magasins = arrayOfObjectsToArrayOfValues(tmp.magasins, 'mag_id');
		numericSort(tmp.magasins);
		tmp.groupes = arrayOfObjectsToArrayOfValues(tmp.groupes, 'grf_id');
		numericSort(tmp.groupes);
		tmp.utilisateurs = arrayOfObjectsToArrayOfValues(tmp.utilisateurs, 'uti_id');
		numericSort(tmp.utilisateurs);
		return tmp;
	}

	format(relation: Relation) {
		this.magasinService.formatLabels(relation.magasins);
		this.utilisateurService.formatLabels(relation.utilisateurs);
		this.groupeUtilisateurService.formatLabels(relation.groupes, true);
		this.groupeUtilisateurService.formatPaths(relation.groupes, true);
	}

	getKeyIdOfRelation(relationObj: 'magasins'|'groupes'|'utilisateurs') {
		switch(relationObj) {
			case 'magasins':
				return 'mag_id';
			case 'groupes':
				return 'grf_id';
			case 'utilisateurs':
				return 'uti_id';
		}
	}

	filter(
		items: any[],
		relationType: 'responsabilites'|'restrictions',
		relationObj: 'magasins'|'groupes'|'utilisateurs',
		ids: number[]|number
	) {
		let key: string = this.getKeyIdOfRelation(relationObj);
		let _ids: number[] = (!Array.isArray(ids))? [ids] : ids;
		return items.filter((item: any)=> {
			return item[relationType][relationObj].some((relation: any) => {
				return _ids.indexOf(relation[key]) > -1;
			});
		});
	}

}
