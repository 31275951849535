import { NgModule, Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GlobalModule } from '@global/global.module';
import { Relation } from '@app/relation/relation.model';

@Component({
	selector: 'relation-display',
	template: `
		<label *ngIf="label">{{label}}</label>
		<span *ngIf="
			!value.magasins.length
			&& !value.groupes.length
			&& !value.utilisateurs.length
			&& showEmptyLabel
			&& emptyLabel
		">{{emptyLabel}}</span>
		<div *ngIf="value && value.groupes.length">
			<span *ngIf="showLabels">Groupes : </span>
			<small class="relation relation-groupe-utilisateur" *ngFor="let groupe of value.groupes">{{groupe.label}}</small>
		</div>
		<div *ngIf="value && value.utilisateurs.length">
			<span *ngIf="showLabels">Collaborateurs : </span>
			<small class="relation relation-utilisateur" *ngFor="let utilisateur of value.utilisateurs">{{utilisateur.label}}</small>
		</div>
		<div *ngIf="value && value.magasins.length">
			<span *ngIf="showLabels">Magasins : </span>
			<small class="relation relation-magasin" *ngFor="let magasin of value.magasins">{{magasin.label}}</small>
		</div>
	`,
	providers: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RelationDisplayComponent {

	@Input() value!: Relation;
	@Input() label: string = '';
	@Input() emptyLabel: string = '—';
	@Input() showEmptyLabel: boolean = false;
	@Input() showLabels: boolean = false;


	constructor() {

	}

}


@NgModule({
	imports: [
		CommonModule,
		GlobalModule
	],
	exports: [RelationDisplayComponent],
	declarations: [RelationDisplayComponent]
})
export class RelationDisplayModule { }
