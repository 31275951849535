import { NgModule, Component, OnInit, OnDestroy, } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Subscription } from 'rxjs';

import { UIRouterModule } from '@uirouter/angular';

import { ButtonModule } from 'primeng/button'

import { StateUtilsService } from '@helpers/state-utils.service';

@Component({
	selector: 'back-button',
	template: `
		<a
			uiSref="^"
			class="media-view previous mr-2 flex-shrink-0"
			*ngIf="!previousState"
		>
			<button
				type="button"
				pButton
				icon="pi pi-arrow-left"
			></button>
		</a>
		<a
			class="media-view previous mr-2 flex-shrink-0"
			(click)="eatPringle()"
			*ngIf="previousState"
		>
			<button
				type="button"
				pButton
				icon="pi pi-arrow-left"
			></button>
		</a>
	`
})
export class BackButtonComponent implements OnDestroy {

	previousState: any;
	uiOptions: any = {
		custom: 'going-back'
	}

	private stateStack$: Subscription;

	constructor(private stateUtilsService: StateUtilsService) {
		this.stateStack$ = stateUtilsService.stateStack$.subscribe((stack: any[]) => {
			this.previousState = stack.pop();
			// console.log('previousState', this.previousState);
		});
	}

	ngOnDestroy() {
		this.stateStack$.unsubscribe();
	}

	eatPringle() {
		this.stateUtilsService.popStack();
		history.back();
	}

}
@NgModule({
	declarations: [ BackButtonComponent	],
	exports: [ BackButtonComponent	],
	imports: [
		CommonModule,
		UIRouterModule,
		ButtonModule,

	],
})
export class BackButtonModule { }
