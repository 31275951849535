import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subscriber } from 'rxjs';
import { map } from 'rxjs/operators';

import { StateService } from '@uirouter/core';

import { EmbedVideoService } from '@app/embed-video';
import { EventManagerService } from '@app/_global/event-manager.service';
import { Media } from '@app/media/media.model';
import { MimeTypePipe } from '@app/_helpers/mime-type.pipe';
import { prefixRoute } from '@global/api-enseigne.interceptor';
import { TypeMedia } from '@app/media/type-media.model';

import {
	copyTextToClipboard,
	extractFileExtension,
	isNumber,
	numericSort,
	prepareQueryParams
} from '@helpers/utils';

export const displayableImageExtensions: string[] = [
	'bmp',
	'gif',
	'ico',
	'jpg', 'jpeg',
	'png',
	'svg',
	'webp'
];

export const fileTypes: any = [
	{
		code: 'doc',
		label: 'Doc',
		extensions: ['doc', 'docx', 'odt'],
		mimetypes: [
			'application/msword',
			'application/vnd.oasis.opendocument.text',
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		]
	},
	{
		code: 'excel',
		label: 'Excel',
		extensions: ['xls', 'xlsx', 'ods'],
		mimetypes: [
			'application/vnd.ms-excel',
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			'application/vnd.oasis.opendocument.spreadsheet',
		]
	},
	{
		code: 'powerpoint',
		label: 'PowerPoint',
		extensions: ['ppt', 'pptx', 'odp'],
		mimetypes: [
			'application/vnd.ms-powerpoint',
			'application/vnd.openxmlformats-officedocument.presentationml.presentation',
			'application/vnd.oasis.opendocument.presentation',
		]
	},
	{
		code: 'pdf',
		label: 'PDF',
		extensions: ['pdf'],
		mimetypes: ['application/pdf']
	},
	{
		code: 'image',
		label: 'Image',
		extensions: ['apng', 'avif', 'gif', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'svg', 'webp', 'tif', 'ico'],
		mimetypes: ['image/*']
	},
	{
		code: 'video',
		label: 'Vidéo',
		extensions: ['mp4', 'avi', 'mkv', 'av1', 'mpeg'],
		mimetypes: ['video/*']
	},
	{
		code: 'texte',
		label: 'Texte',
		extensions: ['txt'],
		mimetypes: ['text/*']
	},
	{
		code: 'lien',
		label: 'Lien',
		extensions: [],
		mimetypes: []
	},
	{
		code: 'autre',
		label: 'Autre',
		extensions: [],
		mimetypes: []
	}
];

@Injectable({ providedIn: 'root' })
export class MediaService {

	private _typesMedia: TypeMedia[] = [];

	public get typesMedia() {
		return [...this._typesMedia];
	}

	constructor(
		private eventManager: EventManagerService,
		private embedVideoService: EmbedVideoService,
		private http: HttpClient,
		private mimeTypePipe: MimeTypePipe,
		private stateService: StateService
	) {

	}

	public prepareMediaFromServer(media: Media): Media {
		media = Object.assign(new Media(), media);

		Media.convertDateFieldsToDate(media, true);
		if (Array.isArray(media.med_liste_tailles)) {
			numericSort(media.med_liste_tailles);
		}

		media._type = this.mimeTypePipe.transform(media.med_mimetype, true) || media.tym_code;
		if (media._type == 'video' && media.med_url) {
			media._embedded_video = this.embedVideoService.embed(media.med_url) as string;
		}
		if (media._type =='image' && media.med_fichier) {
			media._displayableImage = this.isDisplayableImage(media.med_fichier);
		}

		// préparation de menu pour ce média
		media._menu_items = [];
		if (media._type == 'lien') {
			media._menu_items.push({
				label: `Copier l'url`,
				icon: 'pi pi-copy',
				command: () => {
					if (copyTextToClipboard(media.med_url!)) {
						this.eventManager.emit('toast', {severity: 'success', summary: 'Url copiée', detail: media.med_url});
					}
				}
			});
		}
		else {
			media._menu_items.push({
				label: 'Voir le média',
				icon: 'pi pi-eye',
				command: () => {
					this.stateService.go('media', {med_code: media.med_code})
				}
			});
		}
		if (media.med_fichier) {
			media._menu_items.push({
				label: 'Télécharger',
				icon: 'pi pi-download',
				url: this.getDownloadUrl(media),
				target: '_blank'
			});
		}
		if (media.med_url) {
			media._menu_items.push({
				label: 'Suivre le lien',
				icon: 'pi pi-external-link',
				url: media.med_url
			});
		}

		media._menu_items.push({
			label: 'Copier le lien de partage',
			icon: 'pi pi-share-alt',
			command: () => {
				this.copyShareLink(media);
			}
		});

		return media;
	}

	public prepareMediasFromServer(medias: Media[]): Media[] {
		medias.forEach((media: Media, index: number) => {
			medias[index] = this.prepareMediaFromServer(media);
		});
		return medias;
	}

	public getList(params: any, res_id?: number): Observable<any> {
		const tmpParams: any = prepareQueryParams(params);
		let url: string = '';
		if (res_id) {
			url = `/admin/ressources/${res_id}`;
		}
		url += '/medias';
		return this.http.get<any>(url, tmpParams)
		.pipe(map(
			(response: any) => {
				response.medias = this.prepareMediasFromServer(response.medias);
				return response;
			}
		));
	}

	public get(med_id: number) {
		const url: string = `/medias/${med_id}`;
		return this.http.get<any>(url);
	}

	public getByCode(med_code: number) {
		let url: string = '/medias/code';
		url = `${url}/${med_code}`;
		return this.http.get<any>(url)
		.pipe(map(
			(response: any) => {
				return this.prepareMediaFromServer(response);
			}
		));
	}

	public post(media: Media, res_id?: number) {
		let tmp: any = this.prepareForPost(media);
		let url: string = '';
		if (res_id) {
			url = `/admin/ressources/${res_id}`;
		}
		url += '/medias';
		return this.http.post<any>(url, media);
	}

	public put(media: Media) {
		let tmp: any = this.prepareForPost(media);
		const url: string = `/admin/medias/${media.med_id}`;
		return this.http.put<any>(url, media);
	}

	public prepareForPost(media: Media) {
		for (let prop in media) {
			if (prop.startsWith('_')) {
				delete media[prop];
			}
		}
	}

	public delete(med_id: number) {
		const url: string = `/admin/medias/${med_id}`;
		return this.http.delete<any>(url);
	}

	public getUploadUrl(res_id?: number) {
		if (res_id) {
			return `/admin/ressources/${res_id}/medias`;
		}
		return '/admin/medias';
	}

	public getTypesMedias(refresh: boolean = false) {
		if (!refresh && this._typesMedia.length > 0) {
			return new Observable<any>((subscriber: Subscriber<any>) => {
				subscriber.next(this.typesMedia);
				subscriber.complete();
			});
		}
		else {
			let url: string = '/types_medias';
			return this.http.get<any>(url)
			.pipe(map(
				(response: any) => {
					this._typesMedia = response;
					return this.typesMedia;
				}
			));
		}
	}

	public getDownloadUrl(media: number|Media) {
		const med_id = (isNumber(media))? media : (media as Media).med_id;
		return prefixRoute(`/medias/${med_id}/dl`);
	}

	public isDisplayableImage(file: string|File|null): boolean {
		if (!file) return false;
		if (file instanceof File) file = file.name;
		let extension: string = extractFileExtension(<string>file);
		return !!extension && !!displayableImageExtensions.find((one: string) => {return one == extension; });
	}

	public getFileType(file: File|string) {
		if (this.isImage(file)) return 'image';
		if (this.isExcel(file)) return 'excel';
		if (this.isPdf(file)) return 'pdf';
		if (this.isVideo(file)) return 'video';
 		else return 'autre';
	}

	public isImage(file: File|string): boolean {
		if (file instanceof File) {
			return /^image\//.test(file.type);
		}
		return fileTypes.image.extensions.indexOf(extractFileExtension(file)) > -1;
	}

	public isExcel(file: File|string): boolean {
		if (file instanceof File) {
			return /ms-excel|spreadsheetml|spreadsheet/.test(file.type);
		}
		return fileTypes.xls.extensions.indexOf(extractFileExtension(file)) > -1;
	}

	public isPdf(file: File|string): boolean {
		if (file instanceof File) {
			return /\/pdf/.test(file.type);
		}
		return fileTypes.pdf.extensions.indexOf(extractFileExtension(file)) > -1;
	}

	public isVideo(file: File|string): boolean {
		if (file instanceof File) {
			return /^video\//.test(file.type);
		}
		return fileTypes.video.extensions.indexOf(extractFileExtension(file)) > -1;
	}

	public getShareLink(media: Media) {
		return this.stateService.href('media_direct', {med_code: media.med_code});
	}

	public copyShareLink(media: Media) {
		const state: string = this.getShareLink(media);
		const href = `${window.location.origin}${state}`;
		if (copyTextToClipboard(href)) {
			this.eventManager.emit('toast', {severity: 'success', summary: 'Lien copié', detail: href});
		}
	}

}
