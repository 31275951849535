import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StateService } from '@uirouter/core';

import { CardModule } from 'primeng/card';
import { TreeModule } from 'primeng/tree';
import { TreeNode } from 'primeng/api';

import { DataviewRessourcesModule } from '@app/ressource/dataview-ressources';
import { GlobalModule } from '@global/global.module';

import { Section } from '@app/section';
import { SectionTreeComponent, SectionTreeModule } from '@app/section/section-tree';
import { VignetteDisplayModule } from '@app/vignette/vignette-display';

import {
	formatNodePath,
	addLeadingChars,
	removeTrailingChars,
	removeLeadingChars
} from '@helpers/utils'

@Component({
	selector: 'browse',
	templateUrl: './browse.html'
})
export class BrowseComponent implements OnInit  {

	@ViewChild('sectionTree', {static: true}) sectionTree!: SectionTreeComponent;

	totalRecords: number = 0;
	loading: boolean = false;

	expandedPath: number[] = [];
	selectedSection: any;
	selectFirstWithStuff: boolean = false;

	private body: HTMLElement = document.getElementsByTagName('body')[0];

	private breakpointMobile: number = 768;

	constructor(
		private stateService: StateService,

	) {

	}

	ngOnInit() {
		this.expandPath();

		// ouverture de la première section ayant des ressources si la largeur de l'écran le permet
		if (this.body.offsetWidth >= this.breakpointMobile) {
			if (this.stateService.current.name == 'browse') {
				this.selectFirstWithStuff = true;
			}
		}

	}

	ngOnDestroy(): void {
		// this.eventManager.unregisterEvent('media-click-event-manager', this);
	}

	onSectionExpand(event: any) {
		// console.log('node expand', event.node)
	}

	onSectionCollapse(event: any) {
		// console.log('node collapse', event.node)
	}

	onSectionSelect(event: any) {
		// console.log('node select ', event.node)
		this.updatePath(event.node.data, event.reload);
	}

	updatePath(section: Section, reload: boolean = false) {
		let path: string = formatNodePath(section, this.sectionTree.values, 'sec_id', 'sec_id_parent', (section: any) => {return section.sec_id});
		// this.stateService.go('.', {path: addLeadingChars(path)}, {reload: false});
		this.selectedSection = section;
		this.stateService.go('section', {sec_code: section.sec_code, path: addLeadingChars(path)}, {reload: reload});
	}

	expandPath() {
		if (this.stateService.params.path) {
			let tmpParts: string = removeTrailingChars(removeLeadingChars(this.stateService.params.path));
			let parts: any[] = tmpParts.split('/');
			if (parts.length) {
				if (parts.every((one: any) => { return parseInt(one) > 0;})) {
					this.expandedPath	= parts.map((a: any) => { return parseInt(a); });
				}
			}
		}
	}




}
@NgModule({
	declarations: [
		BrowseComponent
	],
	imports: [
		CommonModule,
		GlobalModule,
		TreeModule,
		CardModule,
		DataviewRessourcesModule,
		SectionTreeModule,
		VignetteDisplayModule
	],
})
export class BrowseModule { }
