import { NgModule, Component, OnChanges, Input, ChangeDetectionStrategy } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Vignette } from '@app/vignette/vignette.model';
import { VignetteService } from '@app/vignette/vignette.service';
import { GlobalModule } from '@global/global.module';

import { enseigne } from '@environments/environment';

@Component({
	selector: 'vignette-display',
	template: `
		<img class="vignette" src="/vignettes/{{_vignette.vig_fichier}}" *ngIf="_vignette && _vignette.vig_id">
		<img class="vignette" src="/assets/{{_enseigne}}_placeholder.png" *ngIf="placeholder && (!_vignette || !_vignette.vig_id)">
	`,
	providers: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class VignetteDisplayComponent implements OnChanges {

	@Input() vignette!: Vignette|number|null;
	@Input() placeholder: boolean = true;

	_vignette: Vignette|undefined|null = null;

	_enseigne: string = enseigne;

	constructor(private vignetteService: VignetteService) {

	}

	ngOnChanges(changes: any): void {
		if (changes.vignette && changes.vignette.currentValue != changes.vignette.previousValue) {
			if (typeof changes.vignette.currentValue == 'number') {
				this._vignette = this.vignetteService.find(changes.vignette.currentValue);
			}
			else {
				this._vignette = changes.vignette.currentValue;
			}
		}
	}


}


@NgModule({
	imports: [
		CommonModule,
		GlobalModule
	],
	exports: [VignetteDisplayComponent],
	declarations: [VignetteDisplayComponent]
})
export class VignetteDisplayModule { }
