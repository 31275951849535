import { StateService, Transition } from '@uirouter/core';

import { Observable }  from 'rxjs';

import { AuthService } from '@app/auth/auth.service';
import { GroupeUtilisateurService } from '@app/groupe-utilisateur';
import { MagasinService } from '@app/magasin';
import { MediaService } from '@app/media';
import { RessourceService } from '@app/ressource';
import { Section } from '@app/section';
import { SectionService } from '@app/section';
import { UtilisateurService } from '@app/utilisateur';
import { VignetteService } from '@app/vignette';

export const startRefreshTokenTimerResolve = {
	token: 'startRefreshTokenTimer',
	deps: [AuthService],
	resolveFn: (authService: AuthService) => {
		authService.startRefreshTokenTimer()
		return true;
	}
}

export const getGroupesUtilisateurs = {
	token: 'groupesUtilisateur',
	deps: [GroupeUtilisateurService],
	resolveFn: (groupeUtilisateurService: GroupeUtilisateurService) => {
		return groupeUtilisateurService.getList().toPromise();
	}
}

export const getUtilisateurs = {
	token: 'utilisateurs',
	deps: [UtilisateurService],
	resolveFn: (utilisateurService: UtilisateurService) => {
		return utilisateurService.getList().toPromise();
	}
}

export const getMagasins = {
	token: 'magasins',
	deps: [MagasinService],
	resolveFn: (magasinService: MagasinService) => {
		return magasinService.getList().toPromise();
	}
}

export const getVignettes = {
	token: 'vignettes',
	deps: [VignetteService],
	resolveFn: (vignetteService: VignetteService) => {
		return vignetteService.getCachedList().toPromise();
	}
}

export const getMesSections = {
	token: 'mesSections',
	deps: [SectionService],
	resolveFn: (sectionService: SectionService) => {
		return sectionService.getMesSections().toPromise();
	}
}

export const getTypesMedias = {
	token: 'typesMedia',
	deps: [MediaService],
	resolveFn: (mediaService: MediaService) => {
		return mediaService.getTypesMedias().toPromise();
	}
}

export const getRessource = {
	token: 'ressource',
	deps: [RessourceService, Transition],
	resolveFn: (ressourceService: RessourceService, transition: Transition) => {
		let adminStates: boolean = !!transition.to().name && transition.to().name!.startsWith('admin');
		if (transition.params().res_id) {
			return ressourceService.get(transition.params().res_id, adminStates).toPromise();
		}
		else {
			return ressourceService.getByCode(transition.params().res_code).toPromise();
		}
	}
}

export const getSection = {
	token: 'section',
	deps: [StateService, SectionService, Transition],
	resolveFn: (stateService: StateService, sectionService: SectionService, transition: Transition) => {
		let appCall: Observable<any> = new Observable<any>();
		if (transition.params().sec_id) {
			appCall = sectionService.get(transition.params().sec_id);
		}
		else {
			appCall = sectionService.getByCode(transition.params().sec_code);
		}
		return appCall.toPromise().then(
			(section: Section) => {
				if (section.nb_ressources) return section;
				return Promise.reject('Section sans ressource');
			}
		);
	}
}

export const getMedia = {
	token: 'media',
	deps: [MediaService, Transition],
	resolveFn: (mediaService: MediaService, transition: Transition) => {
		if (transition.params().med_id) {
			return mediaService.get(transition.params().med_id).toPromise();
		}
		else {
			return mediaService.getByCode(transition.params().med_code).toPromise();
		}
	}
}
