import { Media } from '@app/media';
import { MotClef } from '@app/mot-clef';
import { RestrictionAcces, Responsabilite } from '@app/relation/relation.model';
import { Vignette } from '@app/vignette';
import { Section } from '@app/section';

import { convertDateFieldsToDate, convertDateFieldsToString, ExtensibleObject } from '@helpers/utils';

export class Ressource extends ExtensibleObject {
	res_id: number = 0;
	res_titre: string = '';
	res_html: string|null = null;
	res_code: string = '';
	res_date_creation: string|Date|null = null;
	res_date_modification: string|Date|null = null;
	res_date_debut_publication: string|Date|null = null;
	res_date_fin_publication: string|Date|null = null;
	res_date_archivage: string|Date|null = null;
	res_archive: boolean = false;
	res_en_ligne: boolean = false;
	res_version: string|null = null;
	vig_id: number|null = null;

	medias: Media[] = [];
	nb_medias_telechargeables: number = 0;
	mots_clefs: (MotClef|string)[] = [];
	sections: any[] = [];
	vignette: Vignette|null = null;

	fav_date_creation: string|Date|null = null;

	restrictions: RestrictionAcces = new RestrictionAcces();
	responsabilites: Responsabilite = new Responsabilite();

	_uiSref: string = 'ressource';
	get _uiParams() {
		return {res_code: this.res_code};
	}

	static dateFields: string[] = [
		'res_date_creation',
		'res_date_modification',
		'res_date_debut_publication',
		'res_date_fin_publication',
		'res_date_archivage',
	];


	constructor(section?: Section) {
		super();
		if (section) this.sections.push(section);
	}

	static convertDateFieldsToDate(ressource: Ressource, utc?: boolean, short?: boolean) {
		convertDateFieldsToDate(ressource, Ressource.dateFields, utc, short);
	}

	static convertDateFieldsToString(ressource: Ressource, utc?: boolean, short?: boolean) {
		convertDateFieldsToString(ressource, Ressource.dateFields, utc, short);
	}

	hasResponsabilites(): boolean {
		return !!this.responsabilites.groupes.length
			|| !!this.responsabilites.utilisateurs.length
			|| !!this.responsabilites.magasins.length;
	}

	hasMediaVisibiliteTotale(): boolean {
		return this.medias.some((one: Media) => { return one.med_visibilite_totale; });
	}
}
