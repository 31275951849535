<div class="mb-2">
	<div class="flex justify-content-between align-items-center mb-2" *ngIf="!modal">
		<h1 class="flex align-items-center m-0">
			<back-button class="flex-shrink-0"></back-button>
			<vignette-display [vignette]="ressource.vig_id" class="mr-2 flex-shrink-0"></vignette-display>
			<span>{{ressource.res_titre}}</span>
			<favori-toggle-button class="flex-shrink-0" [ressource]="ressource"></favori-toggle-button>
		</h1>
		<div class="flex-shrink-0">
			<button
				type="button"
				pButton
				icon="pi pi-bars"
				class="label-md-none"
				[loading]="loading"
				(click)="menu.toggle($event)"
				*ngIf="menuItems.length"
			></button>
			<p-menu #menu [popup]="true" [model]="menuItems" appendTo="body"></p-menu>
		</div>
	</div>

	<div class="grid" *ngIf="ressource.nb_medias_telechargeables || ressource.res_html">
		<p-card class="col-12 xl:col" *ngIf="ressource.nb_medias_telechargeables">
			<h3>{{mediaPanelTitle}}</h3>
			<medias-display [medias]="ressource.medias" [showVisibilitePartielle]="true" (onMediaClick)="_onMediaClick($event)"></medias-display>
		</p-card>

		<p-card class="col-12 xl:col" *ngIf="ressource.res_html">
			<ressource-renderer [ressource]="ressource" (onMediaClick)="_onMediaClick($event)"></ressource-renderer>
		</p-card>
	</div>


	<p-card class="ressource-meta text-sm">
		<div *ngIf="ressource.res_version">
			Version : {{ressource.res_version}}
		</div>
		<div>Dernière mise à jour : {{ressource.res_date_modification|date: 'medium'}}</div>
		<div *ngIf="ressource.mots_clefs.length">Mots-clefs :
			<span class="ressource-mot-clefs"><small class="keyword" *ngFor="let keyword of ressource.mots_clefs">{{keyword}}</small></span>
		</div>
		<div class="grid grid-nogutter" *ngIf="ressource.hasResponsabilites()">
			<div class="col-fixed flex-shrink-0">Responsable(s) :</div>
			<div class="col pl-1"><relation-display [value]="ressource.responsabilites" [showLabels]="true"></relation-display></div>
		</div>
	</p-card>

</div>



<p-galleria
	[value]="images"
	[(visible)]="showLighbox"
	[circular]="true"
	[fullScreen]="true"
	[(activeIndex)]="activeImageIndex"
	[showItemNavigators]="true"
	[baseZIndex]="1000"
>
	<ng-template pTemplate="item" let-item>
		<div class="text-white flex center-all w-full h-full flex-column align-items-stretch ">
			<div class="text-center font-bold p-3">
				{{item.med_titre}}
			</div>

			<media-display [media]="item" [consultation]="true" class="flex center-all w-full h-full" *ngIf="item"></media-display>

			<div class="text-center p-3" *ngIf="item.med_description">
				{{item.med_description}}
			</div>
		</div>
	</ng-template>
	<ng-template pTemplate="thumbnail" let-item>
		<media-display [media]="item" class="block"></media-display>
	</ng-template>
</p-galleria>
