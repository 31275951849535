import { Component, NgModule, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIRouterModule, StateService } from '@uirouter/angular';

import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { MenuModule } from 'primeng/menu';

import { BackButtonModule } from '@global/back-button';
import { GlobalModule } from '@global/global.module';

import { DataviewRessourcesModule } from '@app/ressource/dataview-ressources';
import { Section } from '@app/section';
import { UtilisateurService } from '@app/utilisateur/utilisateur.service';
import { VignetteDisplayModule } from '@app/vignette/vignette-display';


@Component({
	selector: 'section-view',
	templateUrl: './section-view.html'
})
export class SectionViewComponent implements OnInit  {

	@Input() section!: Section;

	isGestionnaire: boolean = false;
	menuItems: any[] = [];

	constructor(
		private stateService: StateService,
		private utilisateurService: UtilisateurService,
	) {
		this.isGestionnaire = this.utilisateurService.isGestionnaire();
	}

	ngOnInit() {
		if (this.isGestionnaire) {
			this.menuItems = [
				{
					label: 'Créer une ressource sur cette section',
					icon: `pi pi-plus`,
					command: () => {
						this.goCreateRessource();
					}
				},
			];
		}
	}

	goCreateRessource() {
		this.stateService.go('admin.ressources.create', {sec_id: this.section.sec_id});
	}


}
@NgModule({
	declarations: [
		SectionViewComponent
	],
	imports: [
		CommonModule,
		UIRouterModule,
		CardModule,
		GlobalModule,
		ButtonModule,
		MenuModule,
		BackButtonModule,
		DataviewRessourcesModule,
		VignetteDisplayModule
	],
})
export class SectionViewModule { }
