import { Component, NgModule, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Observable } from 'rxjs';

import { ButtonModule } from 'primeng/button';

import { Ressource } from '@app/ressource/ressource.model';
import { RessourceService } from '@app/ressource/ressource.service';

import { IEventListener, EventManagerService } from '@global/event-manager.service';
import { uid } from '@helpers/utils';

@Component({
	selector: 'favori-toggle-button',
	templateUrl: './favori-toggle-button.html'
})
export class FavoriToggleButtonComponent implements OnInit, IEventListener  {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	@Input() ressource!: Ressource;

	loading: boolean = false;

	constructor(
		private eventManager: EventManagerService,
		private ressourceService: RessourceService
	) {

	}

	ngOnInit(): void {
		this.eventManager.registerEvent('favori-added', this, (args: any) => {
			if (args[0].res_id == this.ressource.res_id) {
				this.ressource.fav_date_creation = new Date();
			}
		});
		this.eventManager.registerEvent('favori-removed', this, (args: any) => {
			if (args[0].res_id == this.ressource.res_id) {
				this.ressource.fav_date_creation = null;
			}
		});
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('favori-added', this);
		this.eventManager.unregisterEvent('favori-removed', this);
	}


	toggleFavoris(event: any) {

		event.stopPropagation();
		event.preventDefault();

		let apiCall: Observable<any>;

		this.loading = true;
		if (this.ressource.fav_date_creation) {
			apiCall = this.ressourceService.deleteFavoris(this.ressource.res_id);
		}
		else {
			apiCall = this.ressourceService.addFavoris(this.ressource.res_id);
		}

		apiCall.subscribe(
			(response: any) => {
				if (this.ressource.fav_date_creation) {
					// this.ressource.fav_date_creation = null;
					this.eventManager.emit('toast', {severity: 'success', summary: 'Retiré des favoris'});
					this.eventManager.emit('favori-removed', {res_id: this.ressource.res_id});
				}
				else {
					// this.ressource.fav_date_creation = new Date();
					this.eventManager.emit('toast', {severity: 'success', summary: 'Ajouté aux favoris'});
					this.eventManager.emit('favori-added', {res_id: this.ressource.res_id});
				}

			}
		).add(() => { this.loading = false; });

	}

}
@NgModule({
	declarations: [FavoriToggleButtonComponent],
	imports: [
		CommonModule,
		ButtonModule,
	],
	exports: [FavoriToggleButtonComponent]
})
export class FavoriToggleButtonModule { }
