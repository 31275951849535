export const menuItems = [
	{
		label: 'Documents',
		state: {
			name: 'browse',
			uiOptions: {inherit: false, reload: true}
		},
		icon: 'pi pi-copy'
	},
	{
		label: 'Mes favoris',
		state: {
			name: 'favoris',
		},
		icon: 'pi pi-star'
	},
	{
		label: 'Historique de consultation',
		state: {
			name: 'historique',
		},
		icon: 'pi pi-history'
	},

	{ separator: true, droits: ['editeur', 'publicateur', 'administrateur']},
	{
		label: 'Administration',
		state: {
			abstract: 'admin'
		},
		icon: 'pi pi-cog',
		items: [
			{
				label: 'Gestion des Sections',
				droits: ['administrateur'],
				state: {
					name: 'admin.sections'
				},
			},
			{
				label: 'Gestion des Ressources',
				droits: ['editeur', 'publicateur', 'administrateur'],
				state: {
					name: 'admin.ressources'
				},
			},
			{
				label: 'Vignettes',
				droits: ['administrateur'],
				state: {
					name: 'admin.vignettes'
				},
			},
			{
				label: 'Audit',
				droits: ['administrateur'],
				state: {
					name: 'admin.audit'
				},
			},
		]
	}

];
