import { Component, NgModule, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Observable } from 'rxjs';

import { StateService, UIRouterModule } from '@uirouter/angular';

import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { MenuModule } from 'primeng/menu';
import { GalleriaModule } from 'primeng/galleria';

import { EventManagerService } from '@global/event-manager.service';
import { BackButtonModule } from '@global/back-button';
import { FavoriToggleButtonModule } from '@app/ressource/favori-toggle/favori-toggle-button';
import { GlobalModule } from '@global/global.module';
import { Media } from '@app/media/media.model';
import { MediaDisplayModule } from '@app/media/media-display';
import { MediasDisplayModule } from '@app/media/medias-display';
import { RelationDisplayModule } from '@app/relation/relation-display';
import { Ressource } from '@app/ressource/ressource.model';
import { RessourceService } from '@app/ressource/ressource.service';
import { RessourceRendererModule } from '@app/ressource/ressource-renderer';
import { UtilisateurService } from '@app/utilisateur/utilisateur.service';
import { VignetteDisplayModule } from '@app/vignette/vignette-display';

import { uid } from '@helpers/utils'

@Component({
	selector: 'ressource-view',
	templateUrl: './ressource-view.html'
})
export class RessourceViewComponent implements OnInit  {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	@Input() ressource!: Ressource;
	@Input() modal: boolean = false;
	// @Output() _onMediaClick: EventEmitter<any> = new EventEmitter();

	loading: boolean = false;
	menuItems: any[] = [];

	images: any[] = [];
	showLighbox: boolean = false;
	activeImageIndex: number = -1;
	mediaPanelTitle: string = `Médias disponibles sur cette ressource`;

	constructor(
		private eventManager: EventManagerService,
		private ressourceService: RessourceService,
		private stateService: StateService,
		private utilisateurService: UtilisateurService,
	) {

	}

	ngOnInit() {
		this.refreshMenu();
		this.images = this.ressource.medias.filter((one: Media)=> {
			// return one.med_fichier && this.mediaService.isDisplayableImage(one.med_fichier);
			return one._displayableImage;
		});
		if (this.ressource.nb_medias_telechargeables == 1) {
			this.mediaPanelTitle = `Média disponible sur cette ressource`;
		}
	}

	refreshMenu() {
		this.menuItems = [
			{
				label: this.ressource.fav_date_creation? 'Retirer des favoris' : 'Ajouter aux favoris',
				icon: `pi pi-star`,
				command: () => {
					this.toggleFavori();
				}
			},
			{
				label: 'Copier le lien de partage',
				icon: `pi pi-share-alt`,
				command: () => {
					this.copyShareLink();
				}
			}
		];

		if (this.utilisateurService.isGestionnaire()) {
			this.menuItems.push({
				label: 'Editer la ressource',
				icon: `pi pi-pencil`,
				command: () => {
					this.stateService.go('admin.ressources.single', {res_id: this.ressource.res_id});
				}
			});

		}
	}

	toggleFavori() {
		let apiCall: Observable<any>;

		this.loading = true;
		if (this.ressource.fav_date_creation) {
			apiCall = this.ressourceService.deleteFavoris(this.ressource.res_id);
		}
		else {
			apiCall = this.ressourceService.addFavoris(this.ressource.res_id);
		}

		apiCall.subscribe(
			(response: any) => {
				if (this.ressource.fav_date_creation) {
					this.ressource.fav_date_creation = null;
					this.eventManager.emit('toast', {severity: 'success', summary: 'Retiré des favoris'});
				}
				else {
					this.ressource.fav_date_creation = new Date();
					this.eventManager.emit('toast', {severity: 'success', summary: 'Ajouté aux favoris'});
				}
			}
		).add(() => {
			this.refreshMenu();
			this.loading = false;
		});
	}

	copyShareLink() {
		this.ressourceService.copyShareLink(this.ressource);
	}

	_onMediaClick(event: any) {
		// this.onMediaClick.emit(event);
		this.activeImageIndex = this.images.findIndex((one: Media)=> { return one.med_id == event; });
		this.showLighbox = true;
	}

}
@NgModule({
	declarations: [ RessourceViewComponent	],
	exports: [ RessourceViewComponent	],
	imports: [
		CommonModule,
		UIRouterModule,
		ButtonModule,
		CardModule,
		MenuModule,
		GalleriaModule,
		BackButtonModule,
		GlobalModule,
		FavoriToggleButtonModule,
		RelationDisplayModule,
		RessourceRendererModule,
		MediaDisplayModule,
		MediasDisplayModule,
		VignetteDisplayModule,
	],
})
export class RessourceViewModule { }
