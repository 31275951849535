import { NgModule, Component, Input, ViewChild, forwardRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ControlValueAccessor,	NG_VALUE_ACCESSOR } from '@angular/forms';

import { Observable } from 'rxjs';

import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { TreeSelectModule, TreeSelect } from 'primeng/treeselect';
import { TreeNode } from 'primeng/api';

import { GlobalModule } from '@global/global.module';
import { VignetteDisplayModule } from '@app/vignette/vignette-display';

import { Section, SectionService } from '@app/section';
import {
	arrayOfObjectsToArrayOfValues,
	expandAllTreeNode,
	findSelectedNodes,
	isEmpty,
} from '@helpers/utils';

@Component({
	selector: 'section-selector',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SectionSelectorComponent),
			multi: true
		}
	],
	template: `
		<ng-container *ngIf="asTree">
			<input
				pInputText
				type="text"
				readonly
				value="{{ label }}"
				*ngIf="readonly"
			>
			<p-treeSelect
				#treeselect
				[name]="name"
				[required]="required"
				[options]="options"
				[(ngModel)]="_selected"
				[disabled]="disabled"
				[placeholder]="placeholder"
				[style]="{width: '240px'}"
				[showClear]="nullOption"
				optionValue="sec_id"
				optionLabel="sec_titre"
				[filter]="true"
				filterBy="label"
				[filterPlaceholder]="filterPlaceHolder"
				[selectionMode]="multiple? 'multiple' : 'single'"
				(ngModelChange)="updateValue($event)"
				[panelStyleClass]="styleClass"
				key="sec_id"
				appendTo="body"
				*ngIf="!readonly"
			>
				<ng-template let-node pTemplate="default">
					<div class="flex align-items-center" title="{{node.label}}">
						<vignette-display [vignette]="node.data"></vignette-display>
						<span class="white-space-nowrap overflow-hidden text-overflow-ellipsis">{{node.label}}</span>
					</div>
				</ng-template>
				<ng-template let-value pTemplate="value">
					<div class="flex align-items-center" title="{{placeholder}}" *ngIf="!value">
					<span class="white-space-nowrap overflow-hidden text-overflow-ellipsis">{{placeholder}}</span>
					</div>
					<div class="flex align-items-center" title="{{value.label}}" *ngIf="value">
						<vignette-display [vignette]="value.data" class="flex-shrink-0"></vignette-display>
						<span class="white-space-nowrap overflow-hidden text-overflow-ellipsis">{{value.label}}</span>
					</div>
				</ng-template>
			</p-treeSelect>
		</ng-container>

		<ng-container *ngIf="!asTree">
			<p-multiSelect
				#multiSelect
				[name]="name"
				[required]="required"
				[options]="options"
				[(ngModel)]="_selected"
				[disabled]="disabled"
				[readonly]="readonly"
				[placeholder]="_placeholder"
				[showClear]="nullOption"
				[filter]="options.length > 5"
				filterBy="sec_id,sec_titre,sec_code"
				[filterPlaceHolder]="filterPlaceHolder"
				[resetFilterOnHide]="true"
				[styleClass]="styleClass"
				(ngModelChange)="updateValue($event)"
				appendTo="body"
				*ngIf="multiple"
			>
				<ng-template let-section pTemplate="item">
					<div [title]="!section.sec_avec_ressources && avecRessourcesOnly ? 'Cette section ne peut être liée à une ressource' : ''">
						<div class="flex align-items-center">
							<tree-level-wedge [level]="section.depth -1"></tree-level-wedge>
							<vignette-display [vignette]="section.vig_id"></vignette-display>
							<div>
								{{section.label}}
								<div class="text-sm" *ngIf="section.depth > 1">{{section.path}}</div>
							</div>
						</div>
					</div>
				</ng-template>
			</p-multiSelect>
			<p-dropdown
				[name]="name"
				[required]="required"
				[options]="options"
				[(ngModel)]="value"
				[placeholder]="_placeholder"
				[disabled]="disabled"
				[showClear]="nullOption && options.length > 1"
				optionValue="sec_id"
				[filter]="options.length > 5"
				filterBy="sec_id,sec_titre,sec_code"
				[filterPlaceholder]="filterPlaceHolder"
				appendTo="body"
				*ngIf="!multiple"
			>
				<ng-template let-section pTemplate="item">
					<div [title]="!section.sec_avec_ressources && avecRessourcesOnly ? 'Cette section ne peut être liée à une ressource' : ''">
						<div class="flex align-items-center">
							<tree-level-wedge [level]="section.depth -1"></tree-level-wedge>
							<vignette-display [vignette]="section.vig_id"></vignette-display>
							<div>
								{{section.label}}
								<div class="text-sm" *ngIf="section.depth > 1">{{section.path}}</div>
							</div>
						</div>

					</div>
				</ng-template>
			</p-dropdown>
		</ng-container>
	`
})
export class SectionSelectorComponent implements OnInit, ControlValueAccessor{

	@Input() name: string = '';
	@Input('value') innerValue: number|number[]|null = null;
	@Input() required: boolean = false;
	@Input() disabled: boolean = false;
	@Input() readonly: boolean = false;
	@Input() placeholder: string = 'Sélectionnez';
	@Input() nullIfInvalid: boolean = false;
	@Input() nullOption: boolean = false;
	@Input() multiple: boolean = false;
	@Input() filterPlaceHolder: string = 'Filtrer';
	@Input() objectsOfUserOnly: boolean = false;
	@Input() avecRessourcesOnly: boolean = false;
	@Input() disableItemsFunction?: Function;
	@Input() filterItemsFunction?: Function;
	@Input() valuesAsIds: boolean = false;
	@Input() asTree: boolean = false;
	@Input() orphanEntry: boolean = false;
	@Input() styleClass: string = '';

	@Input() options: Section[]|TreeNode[] = [];

	@ViewChild('treeselect') treeselect!: TreeSelect;

	_placeholder: string = ' ';
	_selected: any;

	constructor(private sectionService: SectionService) {

	}

	ngOnInit() {
		let getOptions: Observable<any>|null = new Observable<any>();
		if (this.objectsOfUserOnly) {
			getOptions = (this.asTree)? this.sectionService.getMesSectionsTree() : this.sectionService.getMesSections();
		}
		else {
			getOptions = (this.asTree)? this.sectionService.getSectionsTree() : this.sectionService.getList();
		}

		getOptions.subscribe(
			(response: any) => {
				this.prepareOptions(response)
			}
		);
	}

	ngOnChanges(changes: any) {
		if (changes.readonly) {
			this.disabled = changes.readonly.currentValue;
		}
	}

	onChange: any = () => { };
	onTouched: any = () => { };

	get value() {
		return this.innerValue;
	}

	set value(value: number|number[]|null) {
		this.innerValue = value;
		this.onChange(value);
		this.onTouched();
	}

	registerOnChange(fn: Function) {
		this.onChange = fn;
	}

	registerOnTouched(fn: Function) {
		this.onTouched = fn;
	}

	writeValue(value: number|number[]|null) {
		if (typeof value != 'undefined') {
			this.innerValue = value;
			this.setInitialValue();
		}
	}

	updateValue(event: any) {
		let values: number|number[]|null = null;
		if (this._selected) {
			if (this.valuesAsIds) {
				if (this.multiple) {
					values = arrayOfObjectsToArrayOfValues(this._selected, 'sec_id');
				}
				else {
					let obj: any;
					if (Array.isArray(this._selected)) {
						obj = !this._selected[0]? null : (!this.asTree)? this._selected[0] : this._selected[0].data;
					}
					else {
						obj = !this._selected? null : (!this.asTree)? this._selected : this._selected.data;
					}
					values = obj? obj['sec_id'] : null;
				}
			}
			else {
				if (this.multiple) {
					values = [...this._selected];
				}
				else {
					if (Array.isArray(this._selected)) {
						values = this._selected[0] ? this._selected[0] : null;
					}
					else {
						values = this._selected ? this._selected : null;
					}
				}
			}
		}
		this.innerValue = values;
		this.onChange(values);
	}

	prepareOptions(sections: any) {

		if (typeof this.filterItemsFunction != 'undefined') {
			sections = this.filterItemsFunction(sections);
		}

		if (typeof this.disableItemsFunction != 'undefined') {
			this.disableItemsFunction(sections);
		}

		if (this.avecRessourcesOnly) {
			sections.forEach((one: any) => {
				if (!one.sec_avec_ressources) one.disabled = true;
			})
		}

		if (this.asTree) {
			expandAllTreeNode(sections);
		}

		this._placeholder = (sections.length)? this.placeholder : 'Aucune section disponible';
		if (this.orphanEntry) {
			let tmp : any = {sec_id: 0, label: 'Orphelines'};
			if (this.asTree) {
				tmp = {
					label: tmp.label,
					data: tmp
				}
			}
			sections.unshift(tmp);
		}

		this.options = sections;
		this.setInitialValue();
	}

	setInitialValue() {
		if (typeof this.innerValue != 'undefined') {
			let selected: any = [];
			let keys: any[] = (this.multiple && Array.isArray(this.innerValue))? [...<number[]>this.innerValue] : [this.innerValue];

			if (this.asTree) {
				findSelectedNodes(this.options as TreeNode[], 'sec_id', keys, selected);
			}

			if (selected.length) {
				let value = (this.multiple)? selected : selected[0];
				if (!this.multiple && isEmpty(value)) value = null;
				this._selected = value;
			}
			else {
				this._selected = (this.multiple)? [] : null;
			}
		}
		if (this.treeselect) {
			this.treeselect.cd.markForCheck();
		}
	}

	get label() {
		let value = this._selected || [];
		return value.length ? value.map((node: TreeNode) => node.label).join(', ') : !this.multiple && this._selected ? value.label : this.placeholder;
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		InputTextModule,
		MultiSelectModule,
		DropdownModule,
		TreeSelectModule,
		GlobalModule,
		VignetteDisplayModule
	],
	exports: [SectionSelectorComponent],
	declarations: [SectionSelectorComponent]
})
export class SectionSelectorModule { }
