import { NgModule, Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { UIRouterModule } from '@uirouter/angular';
import { StateService } from '@uirouter/core';

import { ButtonModule } from 'primeng/button';
import { CalendarModule } from '@app/primeng-overrides/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { LazyLoadEvent } from 'primeng/api';
import { TableModule, Table } from 'primeng/table';
import { ToolbarModule } from 'primeng/toolbar';

import { GlobalModule } from '@global/global.module';
import { LigneMediaModule } from '@app/media/ligne-media';
import { MediaDisplayModule } from '@app/media';
import { MediaIconModule } from '@app/media';
import { StorageService } from '@global/storage.service';
import { TypeElementArborescence, BrowseService } from '@app/browse';
import { TypeMediaSelectorModule } from '@app/media/type-media-selector';
import { VignetteDisplayModule } from '@app/vignette/vignette-display';

import { environment } from '@environments/environment';
import { convertDateFieldsToString, ExtensibleObject, isDateString, startOfDay, endOfDay } from '@helpers/utils'

@Component({
	selector: 'historique-consultation',
	templateUrl: './historique-consultation.html'
})
export class HistoriqueConsultationComponent implements OnInit  {

	@ViewChild('tableBrowse', {static: true}) table!: Table;

	values: TypeElementArborescence[]= [];
	totalRecords: number = 0;
	loading: boolean = false;
	rows: number = environment.default_rows;

	params: ExtensibleObject = {
		search: null,
		tym_code: null,
		date_debut: null,
		date_fin: null
	}

	constructor(
		private browseService: BrowseService,
		private stateService: StateService,
		private storageService: StorageService,
	) {

	}

	ngOnInit(): void {
		this.getParamSet();
	}

	getParamSet() {
		let tableParams = this.storageService.getForCurrentState('tableParams', undefined, true);
		if (tableParams) {
			for (let prop in this.params) {
				if (typeof tableParams[prop] != 'undefined') this.params[prop] = tableParams[prop];
				if (isDateString(this.params[prop])) {
					this.params[prop] = new Date(this.params[prop]);
				}
			}
			if (tableParams.first) this.table.first = tableParams.first;
			if (tableParams.rows) this.rows = tableParams.rows;
		}
		if (typeof this.stateService.params.tym_code != 'undefined') {
			this.params.tym_code = this.stateService.params.tym_code;
		}
		this.table.rows = this.rows;
	}

	saveParamSet() {
		if (this.table) {
			let tableParams = Object.assign({}, this.params, {
				first: this.table.first,
				rows: this.table.rows
			});
			this.storageService.setForCurrentState('tableParams', tableParams, true);
		}
		if (typeof this.params.sec_id != 'undefined') {
			this.stateService.go('.', {sec_id: this.params.sec_id}, {reload: false});
		}
	}

	reset() {
		this.params.search = null;
	}

	refresh(stayOnPage?: boolean) {
		if (!stayOnPage) this.table._first = 0;
		this.table.onLazyLoad.emit(this.table.createLazyLoadMetadata());
	}

	load(event: LazyLoadEvent) {
		// https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
		Promise.resolve(null).then(() => this.loading = true);

		let tmpParams = Object.assign(event, this.params);
		if (tmpParams.date_debut) {
			tmpParams.date_debut = startOfDay(tmpParams.date_debut);
		}
		if (tmpParams.date_fin) {
			tmpParams.date_fin = endOfDay(tmpParams.date_fin);
		}
		convertDateFieldsToString(tmpParams, ['date_debut', 'date_fin'], false);

		this.saveParamSet();
		this.browseService.getListHistorique(tmpParams)
		.subscribe(
			(response: any) => {
				this.totalRecords = response.total;
				this.prepareUISref(response.historiques);
				this.values = response.historiques;
			}
		)
		.add(() => { this.loading = false; });
	}

	prepareUISref(values: TypeElementArborescence[]) {
		values.forEach((one: TypeElementArborescence) => {
			one._uiSref = `historique.${one._uiSref}`;
		});
	}

}
@NgModule({
	declarations: [HistoriqueConsultationComponent],
	exports: [HistoriqueConsultationComponent],
	imports: [
		CommonModule,
		FormsModule,
		UIRouterModule,
		ButtonModule,
		CalendarModule,
		CheckboxModule,
		InputTextModule,
		TableModule,
		ToolbarModule,
		GlobalModule,
		LigneMediaModule,
		MediaDisplayModule,
		MediaIconModule,
		TypeMediaSelectorModule,
		VignetteDisplayModule,
	],
})
export class HistoriqueConsultationModule { }
