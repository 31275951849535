import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
	StateService,
	UrlService,
	parseUrl,
	getParams,
	StateRule,
	UrlParts,
	MatchResult,
	StateObject,
	Transition,
	TargetState,
  UIRouter
} from '@uirouter/core';

// import { InternalLinkPipe } from '@helpers/internal-link.pipe';

import { urlType } from '@helpers/utils';

@Injectable({providedIn: 'root'})
export class StateUtilsService {

	private stateStackSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
	readonly stateStack$: Observable<any[]> = this.stateStackSubject.asObservable();


	private unsub: any = null;

	constructor(
		// private internalLinkPipe: InternalLinkPipe,
		private stateService: StateService,
		private urlService: UrlService,
		private uiRouter: UIRouter
	) {
		this.unsub = uiRouter.transitionService.onSuccess({}, (transition: Transition) => this.pushStack(transition));
	}

	ngOnDestroy() {
		this.unsub();
	}

	getFirstNonAbstractParent(state: any): any {
		let parent: any = this.stateService.get('^', state);
		while (!!parent.abstract || parent.redirectTo == state.name) {
			parent = this.stateService.get('^', parent);
		}
		return parent;
	}

	matchUrlToState(url: string): TargetState|null {
		// let formattedUrl = this.internalLinkPipe.transform(url);
		let formattedUrl = url;

		const parts: UrlParts = {
			path: parseUrl(formattedUrl).path,
			// search: getParams(parseUrl(formattedUrl).search),
			hash: parseUrl(formattedUrl).hash,
		}

		const best: MatchResult = this.urlService.match(parts);

		const rule = best && best.rule;
		// If the best match is a state, get the params
		if (rule && rule.type === 'STATE') {
			const state = (rule as StateRule).state;
			const params = best.match;

			// reaffect the search params, because... weird...
			if (parseUrl(formattedUrl).search) {
				Object.assign(params, getParams(parseUrl(formattedUrl).search));
			}

			return this.stateService.target(state, params);
		}
		return null;
	}

	pushStack(transition: Transition): void {
		let currentStack: any[] = this.stateStackSubject.getValue();
		if (currentStack.length > 1000) {
			currentStack.shift();
		}
		let pringle = {name: transition.from().name, params: transition.params('from')}
		// console.log('pringle', pringle);
		currentStack.push(pringle);
		this.stateStackSubject.next(currentStack);
	}

	popStack() {
		let currentStack: any[] = this.stateStackSubject.getValue();
		currentStack.pop();
		this.stateStackSubject.next(currentStack);
	}

}
