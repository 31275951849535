import { Injectable, OnDestroy } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { IEventListener, EventManagerService } from '@global/event-manager.service';

import { uid } from '@helpers/utils';

@Injectable({ providedIn: 'root' })
export class StorageService implements OnDestroy, IEventListener  {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	constructor(
		private stateService: StateService,
		private eventManager: EventManagerService
	) {
		this.eventManager.registerEvent('logout', this, (args: any) => {
				this.reset();
			}
		);
		this.eventManager.registerEvent('resetParamsets', this, (args: any) => {
				this.reset();
			}
		);
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('logout', this);
		this.eventManager.unregisterEvent('resetParamsets', this);
	}

	reset() {
		localStorage.removeItem('paramSets');
		sessionStorage.removeItem('paramSets');
	}

	get(name: string, defaultValue?: any, sessionOnly?: boolean) {
		let value;
		if (!!!sessionOnly) {
			value = localStorage.getItem(name);
			if (value != null && typeof value != 'undefined') {
				value = JSON.parse(value);
			}
		}
		else {
			value = sessionStorage.getItem(name);
			if (value != null && typeof value != 'undefined') {
				value = JSON.parse(value);
			}
		}
		if (typeof value == 'undefined') {
			return (typeof defaultValue != 'undefined')? defaultValue : null;
		}
		return value;
	}

	set(name: string, value: any, sessionOnly?: boolean) {
		if (!!!sessionOnly) {
			localStorage.removeItem(name);
			if (typeof value != 'undefined') {
				localStorage.setItem(name, JSON.stringify(value));
			}
		}
		else {
			sessionStorage.removeItem(name);
			if (typeof value != 'undefined') {
				sessionStorage.setItem(name, JSON.stringify(value));
			}
		}
	}

	getForCurrentState(name: string, defaultValue?: any, sessionOnly?: boolean) {
		const paramSets = this.get('paramSets', defaultValue, sessionOnly);
		const currentState = this.stateService.current.name;
		const params = this.stateService.params;
		let value;


		if (
			currentState
			&& paramSets
			&& paramSets[currentState]
			&& paramSets[currentState][name]
		) {
			value = paramSets[currentState][name];
		}

		if (typeof value == 'undefined') {
			return defaultValue;
		}
		return value;
	}

	setForCurrentState(name: string, value: any, sessionOnly?: boolean) {
		let paramSets = this.get('paramSets', undefined, sessionOnly);
		const currentState = this.stateService.current.name;
		const params = this.stateService.params;

		if (currentState) {
			if (!!!paramSets) paramSets = {};
			if (!!!paramSets[currentState]) paramSets[currentState] = {};

			paramSets[currentState][name] = value;

			this.set('paramSets', paramSets, sessionOnly);
		}
	};

}
