import { Pipe, PipeTransform, Injectable } from '@angular/core';

// import { TypeMedia, MediaService } from '@app/media';
import { TypeMedia } from '@app/media/type-media.model';
import { MediaService } from '@app/media/media.service';

@Injectable({
	providedIn: 'root'
})
@Pipe({name: 'typeMedia'})
export class TypeMediaPipe implements PipeTransform {

	constructor(private mediaService: MediaService) {

	}

	transform(tym_code: string): string {
		let types: TypeMedia[] = this.mediaService.typesMedia;
		let found: TypeMedia|undefined = types.find((one: TypeMedia) => {return one.tym_code == tym_code});
		return (found)? found.tym_libelle : tym_code;
	}
}

