import { NgModule, Component, OnInit, Input, ViewChild, OnChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { UIRouterModule } from '@uirouter/angular';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DataView, DataViewModule } from 'primeng/dataview';
import { InputTextModule } from 'primeng/inputtext';
import { LazyLoadEvent } from 'primeng/api';
import { ToolbarModule } from 'primeng/toolbar';

import { FavoriToggleButtonModule } from '@app/ressource/favori-toggle/favori-toggle-button';
import { GlobalModule } from '@global/global.module';
import { MediasDisplayModule } from '@app/media/medias-display';
import { RelationDisplayModule } from '@app/relation/relation-display';
import { Ressource, RessourceService } from '@app/ressource';
import { StorageService } from '@global/storage.service';
import { VignetteDisplayModule } from '@app/vignette/vignette-display';

import { environment } from '@environments/environment';
import { ExtensibleObject } from '@helpers/utils';

@Component({
	selector: 'dataview-ressources',
	templateUrl: './dataview-ressources.html',
	providers: []
})
export class DataviewRessourcesComponent implements OnInit, OnChanges {

	@Input() get sec_id(): number|null {
		return this.params.sec_id;
	}
	set sec_id(value: number|null) {
		this.params.sec_id = value;
	}

	@ViewChild('tableRessources', {static: true}) table!: DataView;

	values: Ressource[]= [];
	totalRecords: number = 0;
	loading: boolean = false;
	rows: number = environment.default_rows;

	orphan: boolean = false;

	params: ExtensibleObject = {
		search: null,
		sec_id: null
	}

	constructor(
		private ressourceService: RessourceService,
		private storageService: StorageService
	) { }

	ngOnInit(): void {
		this.getParamSet();
	}

	ngOnChanges(changes: any) {
		if (changes.sec_id && changes.sec_id.currentValue != changes.sec_id.previousValue) {
			this.refresh();
		}
	}

	getParamSet() {
		let tableParams = this.storageService.getForCurrentState('tableParams', undefined, true);
		if (tableParams) {
			for (let prop in this.params) {
				if (typeof tableParams[prop] != 'undefined') this.params[prop] = tableParams[prop];
			}
			if (tableParams.first) this.table.first = tableParams.first;
			if (tableParams.rows) this.rows = tableParams.rows;
		}
		this.table.rows = this.rows;
	}

	saveParamSet() {
		if (this.table) {
			let tableParams = Object.assign({}, this.params, {
				first: this.table.first,
				rows: this.table.rows
			});
			this.storageService.setForCurrentState('tableParams', tableParams, true);
		}
	}

	reset() {
		this.params.search = null;
	}

	refresh(stayOnPage?: boolean) {
		if (!stayOnPage) this.table.first = 0;
		this.table.onLazyLoad.emit(this.table.createLazyLoadMetadata());
	}

	load(event: LazyLoadEvent) {

		if (!this.params.sec_id) return;
		// https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
		Promise.resolve(null).then(() => this.loading = true);

		let tmpParams = Object.assign(event, this.params);

		this.saveParamSet();
		this.ressourceService.getList(tmpParams)
		.subscribe(
			(response: any) => {
				this.totalRecords = response.total;
				this.values = response.ressources;
			}
		)
		.add(() => { this.loading = false; });
	}

	filter(event: any) {
		this.table.filter(event.target.value)
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		UIRouterModule,
		ButtonModule,
		CheckboxModule,
		DataViewModule,
		InputTextModule,
		ToolbarModule,
		FavoriToggleButtonModule,
		GlobalModule,
		MediasDisplayModule,
		RelationDisplayModule,
		VignetteDisplayModule,
	],
	exports: [DataviewRessourcesComponent],
	declarations: [DataviewRessourcesComponent]
})
export class DataviewRessourcesModule { }

