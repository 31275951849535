import {
	NgModule,
	Component,
	OnInit,
	OnChanges,
	Input,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Output,
	EventEmitter,
	ElementRef,
	HostListener
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { GlobalModule } from '@global/global.module';
import { IEventListener, EventManagerService } from '@global/event-manager.service';
import { Media } from '@app/media';
import { MediaIconModule } from '@app/media/media-icon';
import { MediaService } from '@app/media/media.service';

import { uid } from '@helpers/utils';

@Component({
	selector: 'media-display',
	templateUrl: './media-display.html',
	providers: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaDisplayComponent implements OnInit, OnChanges, IEventListener {

	@Input() media: any = null;
	@Input() med_id: number = 0;
	@Input() maxThumbSize: number = 0;
	@Output() onMediaClick: EventEmitter<any> = new EventEmitter();
	@Input() showTitle: boolean = true;
	@Input() showMenu: boolean = true;
	@Input() showPdf: boolean = false;
	@Input() iconOnly: boolean = false;
	@Input() consultation: boolean = false;

	@HostListener('window:resize', ['$event'])
	onResizeHandler(event: Event): void {
		this.onResize();
	}

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	srcset: string = '';
	baseUrl: string = '';
	url: SafeResourceUrl = '';
	el: any;

	debouncedResize: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	largestThumbLoaded: number = 0;

	constructor(
		private changeDetector: ChangeDetectorRef,
		private elementRef: ElementRef,
		private eventManager: EventManagerService,
		private mediaService:  MediaService,
		private sanitizer: DomSanitizer
	) {
		this.el = this.elementRef.nativeElement;
		// console.log('this.el', this.el)

		// this.debouncedResize
		// .pipe(debounceTime(500))
		// .subscribe((response: any) => {
		// 	// console.log('debounced');
		// 	this.selectThumb();
		// });
	}

	ngOnInit(): void {
		// console.log('media-display', 'onInit', this.media);
		this.draw();

	}

	ngOnChanges(changes: any) {
		if (typeof changes.media != 'undefined' && changes.media.currentValue != changes.media.previousValue) {
			this.draw();
		}
	}

	ngAfterViewInit() {
		setTimeout(() => {this.onResize()}, 0);
	}

	ngOnDestroy(): void {
		// window.removeEventListener('resize', this.onResize);
	}

	onResize() {
		// console.log('onResize', this.el.offsetWidth);
		if (this.media._type == 'image') {
			this.debouncedResize.next(true);
		}
	}

	draw() {
		this.mediaService.prepareMediaFromServer(this.media);
		this.baseUrl = this.mediaService.getDownloadUrl(this.media.med_id);

		this.elementRef.nativeElement.classList.add(`media-${this.media._type}`);

		if (this.media._displayableImage) {

				this.debouncedResize
				.pipe(debounceTime(500))
				.subscribe((response: any) => {
					// console.log('debounced');
					this.selectThumb();
				});

		}
		else {
			this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.baseUrl);
		}

		this.changeDetector.detectChanges()
	}

	selectThumb() {
		if (Array.isArray(this.media.med_liste_tailles)) {
			let elemWidth = this.el.offsetWidth;
			let result = this.baseUrl;
			for(let i = 0; i < this.media.med_liste_tailles.length; i++) {
				const candidate = this.media.med_liste_tailles[i];
				if (candidate >= elemWidth || (this.maxThumbSize && candidate > this.maxThumbSize)) {
					if (candidate >= this.largestThumbLoaded) {
						let winner: number = this.media.med_liste_tailles[0];
						if (i>0) {
							winner = this.media.med_liste_tailles[i-1];
						}
						result = `${this.baseUrl}?thumb=${winner}`;
						if (this.consultation) result += '&consultation';
						this.largestThumbLoaded = winner;
						break;
					}
				}
			}
			this.url = result;
			this.changeDetector.detectChanges();
		}
	}

	isDisplayableImage(filename: string|null): boolean {
		return this.mediaService.isDisplayableImage(filename);
	}

	onImgClicked(event: any) {
		// console.log('media-display', 'onImgClicked', event);
		this.onMediaClick.emit(event)
		this.eventManager.emit('media-click-event-manager', event);
	}



}


@NgModule({
	declarations: [MediaDisplayComponent],
	exports: [
		MediaDisplayComponent,
	],
	imports: [
		CommonModule,
		GlobalModule,
		MediaIconModule,
	]
})
export class MediaDisplayModule { }
