import { Ng2StateDeclaration } from '@uirouter/angular';
import { StateService } from '@uirouter/core';
import { Transition } from '@uirouter/core';

import { AppComponent } from '@app/app.component';
import { AuthComponent } from '@app/auth';
import { BrowseAdvancedComponent } from '@app/browse/browse-advanced';
import { BrowseComponent } from '@app/browse/browse';
// import { HomeComponent } from '@app/home';
import { HistoriqueConsultationComponent } from '@app/browse/historique-consultation';
import { RessourceViewComponent } from '@app/ressource';
import { MediaViewComponent } from '@app/media';
import { RootComponent } from '@app/root';
import { SectionService } from '@app/section';
import { SectionViewComponent } from '@app/section/section-view';
import { ServiceUnavailableComponent } from'@global/service-unavailable.component';

import { Media } from '@app/media';
import { Ressource } from '@app/ressource';
import { Section } from '@app/section';

import * as resolves from '@global/app-routing.resolves';

export const states: Ng2StateDeclaration[] = [
	{
		name: 'service_unavailable',
		url: '?{error:string}',
		component: ServiceUnavailableComponent
	},

	{
		name: 'app',
		component: AppComponent,
		redirectTo: 'home',
		resolve: [

		]
	},

	{
		parent: 'app',
		name: 'auth',
		url: '/auth',
		component: AuthComponent,
		data: {
			forbiddenWhenAuth: true
		},
	},
	{
		parent: 'app',
		name: 'callback',
		url: '/callback?{code:string}{state:string}{error:string}',
		params: {
			'code': {
				dynamic: true,
				squash: true,
			},
			'state': {
				dynamic: true,
				squash: true
			},
			'error': {
				dynamic: true,
				squash: true
			},
		},
		component: AuthComponent,
		data: {
			forbiddenWhenAuth: true
		},
	},

	{
		parent: 'app',
		name: 'root',
		component: RootComponent,
		redirectTo: 'home',
		data: {
			requiresAuth: true,
			meGuard: true
		},
		resolve: [
			resolves.getTypesMedias,
			resolves.getVignettes,
			resolves.getMesSections
		]
	},

	{
		parent: 'root',
		name: 'home',
		url: '/home',
		// component: HomeComponent,
		redirectTo: 'browse'
	},

	{
		parent: 'root',
		name: 'browse',
		url: '/documents?{path:path}',
		params: {
			path: {
				raw: true,
				dynamic: true,
				squash: true,
			}
		},
		views: {
			'rootContent': { component: BrowseComponent },
		},
		resolve: [

		],
		breadcrumb: () => { return 'Documents'; }
	},

	{
		parent: 'browse',
		name: 'section',
		url: '/section/{sec_code:pathFragment}',
		params: {

		},
		views: {
			'rootContent': { component: BrowseComponent },
			'col2@browse': {component: SectionViewComponent }
		},
		resolve: [
			resolves.getSection
		],
		breadcrumb: (trans: Transition) => {
			const section: Section = trans.injector().get('section');
			return `${section.sec_titre}`;
		}
	},

	{
		name: 'section.ressource',
		url: '/{res_code:pathFragment}',
		views: {
			'rootContent@root': { component: RessourceViewComponent },
		},
		resolve: [
			resolves.getRessource
		],
		breadcrumb: (trans: Transition) => {
			const ressource: Ressource = trans.injector().get('ressource');
			return `${ressource.res_titre}`;
		}
	},

	{
		parent: 'root',
		name: 'media_direct',
		url: '/media/{med_code:pathFragment}',
		redirectTo: (trans: Transition) => {
			return {state: 'media', params: {med_code: trans.params().med_code}, options: {location: 'replace'}};
		}
	},
	{
		parent: 'browse',
		name: 'media',
		url: '/media/{med_code:pathFragment}',  // {path:path} is inherited from parent
		views: {
			'rootContent@root': { component: MediaViewComponent },
		},
		resolve: [
			resolves.getMedia
		],
		breadcrumb: (trans: Transition) => {
			const media: Media = trans.injector().get('media');
			return `${media.med_titre}`;
		}
	},

	{
		parent: 'root',
		name: 'ressource_direct',
		url: '/ressource/{res_code:pathFragment}',
		redirectTo: (trans: Transition) => {
			return {state: 'ressource', params: {res_code: trans.params().res_code}, options: {location: 'replace'}};
		}
	},
	{
		parent: 'root',
		name: 'ressource_direct_id',
		url: '/ressource-by-id/{res_id:int}',
		views: {
			'rootContent@root': { component: RessourceViewComponent },
		},
		resolve: [
			resolves.getRessource
		],
		breadcrumb: (trans: Transition) => {
			const ressource: Ressource = trans.injector().get('ressource');
			return `${ressource.res_titre}`;
		}
	},
	{
		parent: 'browse',
		name: 'ressource',
		url: '/ressource/{res_code:pathFragment}', // {path:path} is inherited from parent
		views: {
			'rootContent@root': { component: RessourceViewComponent },
		},
		resolve: [
			resolves.getRessource
		],
		breadcrumb: (trans: Transition) => {
			const ressource: Ressource = trans.injector().get('ressource');
			return `${ressource.res_titre}`;
		}
	},


	{
		parent: 'root',
		name: 'section_direct',
		url: '/section/{sec_code:pathFragment}',
		resolve: [
			resolves.getMesSections,
			{
				token: 'redirect',
				deps: [StateService, SectionService, Transition],
				resolveFn: (stateService: StateService, sectionService: SectionService, transition: Transition) => {
					let path = sectionService.getPathIdFromCode(transition.params().sec_code);
					if (path) {
						return stateService.go('browse', {path: `/${path}`, options: {location: 'replace'}});
					}
					return stateService.go('home', {options: {location: 'replace'}});
				}
			}
		],
	},

	{
		parent: 'root',
		name: 'favoris',
		url: '/favoris',
		views: {
			'rootContent@root': { component: BrowseAdvancedComponent },
		},
		resolve: [
			{token: 'browseMode', deps: [], resolveFn: () => {return 'favoris';}}
		],
		breadcrumb: () => {
			return 'Favoris';
		}
	},

	{
		name: 'favoris.ressource',
		url: '/{res_code:pathFragment}',
		views: {
			'rootContent@root': { component: RessourceViewComponent },
		},
		resolve: [
			resolves.getRessource
		],
		breadcrumb: (trans: Transition) => {
			const ressource: Ressource = trans.injector().get('ressource');
			return `${ressource.res_titre}`;
		}
	},

	{
		parent: 'root',
		name: 'historique',
		url: '/historique',
		views: {
			'rootContent@root': { component: HistoriqueConsultationComponent },
		},
		breadcrumb: () => {
			return 'Historique de consultation';
		}
	},
	{
		name: 'historique.ressource',
		url: '/ressource/{res_code:pathFragment}',
		views: {
			'rootContent@root': { component: RessourceViewComponent },
		},
		resolve: [
			resolves.getRessource
		],
		breadcrumb: (trans: Transition) => {
			const ressource: Ressource = trans.injector().get('ressource');
			return `${ressource.res_titre}`;
		}
	},
	{
		name: 'historique.media',
		url: '/media/{med_code:pathFragment}',
		views: {
			'rootContent@root': { component: MediaViewComponent },
		},
		resolve: [
			resolves.getMedia
		],
		breadcrumb: (trans: Transition) => {
			const media: Media = trans.injector().get('media');
			return `${media.med_titre}`;
		}
	},


	{
		parent: 'root',
		name: 'recherche',
		url: '/recherche?{search:string}',
		params: {
			path: {
				raw:true,
				dynamic: true,
				squash: true,
			}
		},
		views: {
			'rootContent@root': { component: BrowseAdvancedComponent },
		},
		resolve: [
			{token: 'browseMode', deps: [], resolveFn: () => {return 'recherche';}}
		]
	},

	{
		name: 'recherche.ressource',
		url: '/{res_code:pathFragment}',
		redirectTo: (trans: Transition) => {
			return {state: 'ressource_direct', params: {res_code: trans.params().res_code}, options: {location: 'replace'}};
		}
	},





	// administration
	{
		parent: 'root',
		name: 'admin.**',
		url: '/admin',
		loadChildren: () => import('@app/admin/admin.module').then(m => m.AdminModule),
		data: {

		},
		resolve: [
			resolves.getMagasins,
			resolves.getGroupesUtilisateurs,
			resolves.getUtilisateurs,
		],
	},


];
