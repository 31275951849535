import { Utilisateur } from '@app/utilisateur';
import { GroupeUtilisateur } from '@app/groupe-utilisateur';
import { Magasin } from '@app/magasin';

export class Relation {
	magasins: Magasin[] = [];
	groupes: GroupeUtilisateur[] = [];
	utilisateurs: Utilisateur[] = [];
}

export class RestrictionAcces extends Relation {

}

export class Responsabilite extends Relation {

}

