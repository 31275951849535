import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { DecimalPipe } from '@angular/common';
import { UIView } from '@uirouter/angular';

import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { ScrollTopModule } from 'primeng/scrolltop';
import { ToastModule } from 'primeng/toast';

import { ApiErrorInterceptor } from '@global/api-error.interceptor';
import { AppRoutingModule } from '@global/app-routing.module';
import { EnseigneInterceptor } from '@global/api-enseigne.interceptor';
import { JwtInterceptor } from '@global/api-jwt.interceptor';
import { ResponseTypeToBlobInterceptor } from '@global/api-response-type.interceptor';

import { AppComponent } from '@app/app.component';

import localeFr from '@angular/common/locales/fr';

registerLocaleData(localeFr);

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		AppRoutingModule,
		ConfirmDialogModule,
		DynamicDialogModule,
		ScrollTopModule,
		ToastModule,
		CommonModule,
	],
	providers: [
		DatePipe,
		DecimalPipe,
		{ provide: LOCALE_ID, useValue: 'fr-FR'},
		// attention à l'ordre des intercepteurs
		{ provide: HTTP_INTERCEPTORS, useClass: ApiErrorInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: EnseigneInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ResponseTypeToBlobInterceptor, multi: true },
		DialogService,
		ConfirmationService,
	],
	exports: [
		ConfirmDialogModule,
		DynamicDialogModule,
		ScrollTopModule,
		ToastModule,
		CommonModule,
	],
	bootstrap: [UIView]
})
export class AppModule { }
