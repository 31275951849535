import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subscriber } from 'rxjs';
import { map} from 'rxjs/operators';

import { IEventListener, EventManagerService } from '@global/event-manager.service';
import { Magasin } from '@app/magasin';
import { UtilisateurService } from '@app/utilisateur';
import { uid } from '@helpers/utils';

@Injectable({ providedIn: 'root' })
export class MagasinService implements IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	private _magasins: Magasin[] = [];
	private _mesMagasins: Magasin[] = [];

	public get magasins() {
		return [...this._magasins];
	}

	public get mesMagasins() {
		return [...this._mesMagasins];
	}

	constructor(
		private eventManager: EventManagerService,
		private http: HttpClient,
		private utilisateurService: UtilisateurService,
	) {

	}

	ngOnInit() {
		this.eventManager.registerEvent('logout', this, (args: any) => {
			this.reset();
		});
	}

	ngOnDestroy() {
		this.eventManager.unregisterEvent('logout', this);
	}

	reset() {
		this._magasins = [];
	}

	getList(refresh: boolean = false): Observable<any> {
		if (!refresh && !!this._magasins.length) {
			return new Observable<any>((subscriber: Subscriber<any>) => {
				subscriber.next(this.magasins);
				subscriber.complete();
			});
		}
		else {
			let url: string = '/magasins';
			return this.http.get<any>(url)
			.pipe(map(
				(response: any) => {
					this.formatLabels(response);
					this._magasins = response;
					this._mesMagasins = this.filterMine(this._magasins);
					return this.magasins;
				}
			));
		}
	}

	getMesMagasins(refresh: boolean = false) {
		if (!refresh && !!this._mesMagasins.length) {
			return new Observable<any>((subscriber: Subscriber<any>) => {
				subscriber.next(this.mesMagasins);
				subscriber.complete();
			});
		}
		else {
			return this.getList(refresh)
			.pipe(map(
				(response: any) => {
					return this.mesMagasins;
				}
			));
		}
	}

	filterMine(magasins: Magasin[]) {
		return magasins.filter((one: Magasin) => {
			return this.utilisateurService.belongsToMagasin(one);
		});
	}

	formatLabel(magasin: Magasin) {
		let label;
		label = magasin.mag_nom_court || magasin.mag_nom;
		if (magasin.mag_id) {
			label = `${label} (${magasin.mag_id})`;
		}
		return label;
	}

	formatLabels(magasins: Magasin[]) {
		magasins.forEach((one: any) => {
			one.label = this.formatLabel(one);
		});
	}

}
