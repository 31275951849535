import { NgModule, Component, OnChanges, Input, ChangeDetectionStrategy } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'tree-level-wedge',
	template: `
		<ng-container *ngIf="level > 0 && prefix">{{prefix}}</ng-container>
		<ng-container *ngFor="let c of range">{{wedge}}</ng-container>
		<ng-container *ngIf="level > 0 && suffix">{{suffix}}</ng-container>
	`,
	providers: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeLevelWedgeComponent implements OnChanges {

	@Input() level: number = 0;
	@Input() wedge: string = ' • ';
	@Input() prefix: string = '';
	@Input() suffix: string = ' ';

	range: number[] = [];

	constructor() {

	}

	ngOnChanges(changes: any) {
		if (
			typeof changes.level != 'undefined'
			&& changes.level.currentValue != changes.level.previousValue
			&& !Number.isNaN(changes.level.currentValue)
		) {
			this.range = new Array(changes.level.currentValue);
		}
	}

}
