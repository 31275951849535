import { Injectable, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { HttpClient, HttpParams } from '@angular/common/http';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { Utilisateur } from  '@app/utilisateur';
import { IEventListener, EventManagerService } from '@global/event-manager.service';

import { isEmpty, uid } from '@helpers/utils';
import { environment, enseigne } from '@environments/environment';

export class Token {
	token_type?: string;
	expires_in?: number;
	access_token?: string;
	refresh_token?: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService implements OnInit, IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	private tokenSubject = new BehaviorSubject<Token|null>(null);

	private userSubject = new BehaviorSubject<Utilisateur|null>(null);

	private refreshTokenTimeout: number|undefined;

	constructor(
		private stateService: StateService,
		private http: HttpClient,
		private eventManager: EventManagerService
	) {
		let tmpToken = sessionStorage.getItem('token') || '';

		if (tmpToken) {
			try {
				this.tokenSubject.next(JSON.parse(tmpToken));
			}
			catch(e) {
				console.log('Invalid token from session storage')
				this.tokenSubject.next(null);
			}
		}

		this.eventManager.registerEvent('logout', this, (args: any) => {
			this.logout(args);
		});
	}

	ngOnInit() {
		// this.startRefreshTokenTimer();
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('logout', this);
	}

	public get token(): Token|null {
		return this.tokenSubject.getValue();
	}

	public get user(): Utilisateur|null {
		return this.userSubject.getValue();
	}

	startRefreshTokenTimer(): void {
		this.stopRefreshTokenTimer();
		if (this.token && this.token.access_token) {
			console.log('startRefreshTokenTimer');
			// parse json object from base64 encoded jwt token
			const token = JSON.parse(atob(this.token.access_token.split('.')[1]));
			// calculate expiration timeout
			const expires = new Date(token.exp * 1000);
			let timeout = expires.getTime() - Date.now();
			if (timeout < environment.refresh_token_minimal_timeout) {
				console.log(`Expiration is less than minimal timeout: ${timeout}/${environment.refresh_token_minimal_timeout}`, 'Attempt refresh immediately');
				this.refreshToken().subscribe();
			}
			else {
				// refresh token before it expires
				timeout = environment.refresh_token_minimal_timeout;
				this.refreshTokenTimeout = window.setTimeout(() => this.refreshToken().subscribe(), timeout);
				console.log(`Will refresh token in ${timeout/1000}s`, new Date(Date.now() + timeout));
			}
		}
		else {
			console.log('startRefreshTokenTimer', 'No token, timer NOT started');
		}
	}

	private stopRefreshTokenTimer() {
		clearTimeout(this.refreshTokenTimeout);
	}

	getAuthorizationCode(state?: string): void {
		if (typeof state == 'undefined') state = '';
		const params = [
			'response_type=code',
			'client_id=' + environment[enseigne].client_id,
			'redirect_uri=' + encodeURIComponent(environment.oauth_redirect),
			'state=' + state
		];
		window.location.href = `${environment[enseigne].foc}/extranet/oauth/authorize?` + params.join('&');
	}

	getAccessToken(code: string): Observable<any> {

		const payload = new HttpParams()
		.append('grant_type', 'authorization_code')
		.append('code', code)
		.append('redirect_uri', environment.oauth_redirect)
		.append('client_id', environment[enseigne].client_id)
		.append('client_secret', environment[enseigne].client_secret)

		const options = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		}

		return this.http.post<any>(`${environment[enseigne].foc}/extranet/oauth/access_token`, payload, options)
		.pipe(
			map(
				(token: Token) => {
					sessionStorage.setItem('token', JSON.stringify(token));
					this.tokenSubject.next(token);
					// this.startRefreshTokenTimer();
					return token;
				}
			)
		);
	}

	getMeFOC(): Observable<any> {
		return this.http.get<any>(`${environment[enseigne].foc}/api/extranet/me`)
		.pipe(
			map(
				(response: any) => {
					console.log('getMeFOC', response);
					return response;
				}
			)
		);
	}

	getMe(): Observable<any> {
		return this.http.get<any>(`/me`)
		.pipe(
			map(
				(response: any) => {
					this.userSubject.next(response as Utilisateur);
					return response;
				}
			),
			catchError(
				(error: any) => {
					return throwError(error);
				}
			)
		);
	}

	clear() {
		sessionStorage.clear();
		this.userSubject.next(null);
		this.tokenSubject.next(null);
	}

	logout(options?: any): any {
		if (this.hasToken()) {
			if (typeof options == 'undefined' || isEmpty(options)) {
				this.eventManager.emit('toast', {severity: 'info', summary: 'Session expirée'});
			}
			// this.logoutFOC();
		}

		this.clear();

		// this.stopRefreshTokenTimer();

		if (!this.stateService.includes('auth')) {
			if (isEmpty(options)) {
				// reload normally. UIRouter will take us where we need to be.
				return this.stateService.reload();
			}
			else {
				if (this.stateService.includes('callback')) {
					// don't fully reload: we're still in the auth process, we don't want the view to be re-rendered
					return this.stateService.go('auth', {}, {reload: false});
				}
				else {
					return this.stateService.go('auth', undefined, {reload: true});
				}
			}
		}
	}

	hasToken(): boolean {
		if (this.token && this.token.access_token) {
			return true;
		}
		// console.log('no token in storage');
		return false;
	}

	refreshToken(): Observable<any> {
		const token = this.token;
		if (token && token.refresh_token) {
			const payload = new HttpParams()
				.append('grant_type', 'refresh_token')
				.append('refresh_token',  token.refresh_token)
				.append('client_id', environment.aviva.client_id)
				.append('client_secret', environment.aviva.client_secret)
			;
			return this.http.post<any>(`${environment[enseigne].foc}/extranet/oauth/access_token`, payload)
			.pipe(map(
				(token) => {
					sessionStorage.setItem('token', JSON.stringify(token));
					this.tokenSubject.next(token);
					this.startRefreshTokenTimer();
					return token;
				},
				(error: any) => {
					this.logout();
				}
			));
		}
		else {
			return new Observable(subscriber => {
				this.logout();
				subscriber.error('No token, no refresh');
			});
		}
	}
}
