<div class="grid m-0" title="{{media.med_titre}}">
	<div
		class="col-fixed liste-media-thumb center-all"
		title="{{ media.tym_code | typeMedia}}"
		[ngSwitch]="media.tym_code"
		*ngIf="showThumb"
	>
		<div *ngSwitchCase="'image'" class="center-all">
			<media-display [media]="media" [showTitle]="false"></media-display>
		</div>

		<ng-container *ngSwitchDefault="">
			<media-icon [tym_code]="media.tym_code"></media-icon>
		</ng-container>
	</div>

	<div class="col flex align-items-center">
		<div class="overflow-hidden text-overflow-ellipsis">
			<a class="font-bold text-color overflow-hidden  text-overflow-ellipsis" [uiSref]="media._uiSref" [uiParams]="media._uiParams" *ngIf="titleAsLink">{{media.med_titre}}</a>
			<div class="font-bold overflow-hidden white-space-nowrap text-overflow-ellipsis cursor-pointer" (click)="titleClick()" *ngIf="!titleAsLink">{{media.med_titre}}</div>
			<ng-container *ngIf="media.med_fichier">
				<div class="text-sm" *ngIf="media.med_fichier != media.med_titre">Nom fichier: {{media.med_fichier}}</div>
				<div class="text-sm" *ngIf="showSize">Taille: {{ media.med_taille!|formatSize}}</div>
			</ng-container>

			<ng-container *ngIf="['lien', 'video'].includes(media.tym_code) && showLink">
				<div class="text-sm word-break-all">
					{{media.med_url}}
				</div>
			</ng-container>

			<div class="text-sm" *ngIf="showId">Id : {{media.med_id}}</div>
			<div class="text-sm" *ngIf="showId">Ajouté le : {{media.med_date_creation|date: 'dd/MM/YY HH:mm:ss'}}</div>
		</div>
	</div>

</div>
