import { NgModule, Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { Subscription } from 'rxjs';

import { FilterService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { FileUploadModule, FileUpload } from '@app/primeng-overrides/fileupload';
import { InputTextModule } from 'primeng/inputtext';

import { GlobalModule } from '@global/global.module';
import { MediaIconModule } from '@app/media';
import { Vignette, vignetteFileType, VignetteService } from '@app/vignette';
import { EventManagerService, IEventListener } from '@global/event-manager.service';

import { uid } from '@helpers/utils';
import { localeFR } from '@global/locale-fr';


@Component({
	selector: 'vignette-dialog',
	templateUrl: './vignette-dialog.html',
	providers: []
})
export class VignetteDialogComponent implements OnInit, IEventListener {

	@ViewChild('uploader') uploader!: FileUpload;

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	vignette!: Vignette;

	loading: boolean = false;
	submitted: boolean = false;

	help: string = '';
	acceptedMimeTypes: string = '';
	acceptedExtensions: string = '';
	urlUpload: string = '';
	fileLimit: number = 1;
	// maxFileSize: number|null = 1000000;
	maxFileSize: number = 0;
	error: string|null = null;

	request$: Subscription|null = null;

	invalidFileLimitMessageDetail = localeFR.invalidFileLimitMessageDetail;
	invalidFileLimitMessageSummary = localeFR.invalidFileLimitMessageSummary;
	invalidFileSizeMessageDetail = localeFR.invalidFileSizeMessageDetail;
	invalidFileSizeMessageSummary = localeFR.invalidFileSizeMessageSummary;
	invalidFileTypeMessageDetail = localeFR.invalidFileTypeMessageDetail;
	invalidFileTypeMessageSummary = localeFR.invalidFileTypeMessageSummary;

	availableKeyWords: string[] = [];

	constructor(
		private config: DynamicDialogConfig,
		private dialogRef: DynamicDialogRef,
		private vignetteService: VignetteService,
		private eventManager: EventManagerService,
		private filterService: FilterService
	) {
		this.vignette = config.data.vignette;
		this.acceptedMimeTypes = [
			...vignetteFileType.mimetypes,
		].join(',');
		this.acceptedExtensions = [
			...vignetteFileType.extensions,
		].map(one => {return '*.' + one; }).join(', ');

		this.maxFileSize = config.data.maxFileSize || this.maxFileSize;

		this.invalidFileTypeMessageDetail = this.invalidFileTypeMessageDetail.replace('{0}', this.acceptedExtensions);
		this.urlUpload = this.vignetteService.getUploadUrl();
	}

	ngOnInit(): void {
		this.eventManager.registerEvent('close-modals', this, (args: any) => {
			this.dialogRef.close();
		});
	}

	ngOnDestroy(): void {
		this.abort();
		this.eventManager.unregisterEvent('close-modals', this);
	}

	upload() {
		this.request$ = this.uploader.upload();
	}

	remove(index: number) {
		this.uploader.files.splice(index, 1);
	}

	onBeforeUpload(event: any) {
		event.formData.append('file', this.uploader.files[0]);
		if (this.vignette.vig_libelle) {
			event.formData.append('vig_libelle', this.vignette.vig_libelle);
		}
		if (this.vignette.vig_standard) {
			event.formData.append('vig_standard', this.vignette.vig_standard);
		}
	}

	clear() {
		this.uploader.clear();
	}

	abort() {
		if (this.request$) {
			this.request$.unsubscribe();
			this.eventManager.emit('toast', {severity: 'info', summary: 'Transfert annulé'});
			this.request$ = null;
			this.uploader.uploading = false;
			this.uploader.progress = 0;
			this.uploader.cd.markForCheck();
		}
	}

	onUpload(event: any) {
		this.request$ = null;
		this.eventManager.emit('toast', {severity: 'success', summary: 'Vignette ajoutée'});
		this.vignetteService.reset();
		this.dialogRef.close(event.originalEvent.body);
	}

	onError(event: any) {
		this.request$ = null;
	}

	submit() {
		this.request$ = null;
		this.submitted = true;
		this.loading = true;
		this.vignetteService.put(this.vignette)
		.subscribe(
			(response: any)=> {
				this.eventManager.emit('toast', {severity: 'success', summary: 'Vignette modifiée'});
				this.dialogRef.close({refresh: true});
			},
			(error: any) => {
				console.log('put failure', error);
			}
		)
		.add(() => { this.loading = false; });
	}

	searchKeywords(event: any) {
		this.availableKeyWords = this.filterService.filter(this.vignetteService.mots_clefs, ['value'], event.query, 'contains')
		.map((one: any)=>{return one.value});
	}

	onKeywordAutoCompleteKeyUp(event: KeyboardEvent) {
	if (event.key == 'Enter') {
		let tokenInput = event.srcElement as any;
			if (tokenInput.value) {
				this.vignette.vig_mot_clef = tokenInput.value;
			}
		}
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		AutoCompleteModule,
		ButtonModule,
		CheckboxModule,
		FileUploadModule,
		InputTextModule,
		GlobalModule,
		MediaIconModule,
	],
	exports: [VignetteDialogComponent],
	declarations: [VignetteDialogComponent]
})
export class VignetteDialogModule { }

