import { NgModule, Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { Observable } from 'rxjs';

import { UIRouterModule } from '@uirouter/angular';
import { StateService } from '@uirouter/core';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DataView, DataViewModule } from 'primeng/dataview';
import { InputTextModule } from 'primeng/inputtext';
import { LazyLoadEvent } from 'primeng/api';
import { ToolbarModule } from 'primeng/toolbar';

import { FavoriToggleButtonModule } from '@app/ressource/favori-toggle/favori-toggle-button';
import { GlobalModule } from '@global/global.module';

import { MediaDisplayModule } from '@app/media/media-display';
import { MediaIconModule } from '@app/media/media-icon';
import { MediasDisplayModule } from '@app/media/medias-display';

import { SectionSelectorModule } from '@app/section/section-selector';
import { StorageService } from '@global/storage.service';
import { TypeElementArborescence, BrowseService } from '@app/browse';
import { TypeMediaSelectorModule } from '@app/media/type-media-selector';
import { VignetteDisplayModule } from '@app/vignette/vignette-display';

import { environment } from '@environments/environment';
import { ExtensibleObject } from '@helpers/utils'

@Component({
	selector: 'browse-advanced',
	templateUrl: './browse-advanced.html'
})
export class BrowseAdvancedComponent implements OnInit  {

	@Input() browseMode: 'favoris'|'recherche' = 'recherche';

	@ViewChild('tableBrowse', {static: true}) table!: DataView;

	values: TypeElementArborescence[]= [];
	totalRecords: number = 0;
	loading: boolean = false;
	rows: number = environment.default_rows;

	minChars: number = 3;

	params: ExtensibleObject = {
		search: null,
		sec_id: null,
		tym_code: null,
		obsolete: false
	}

	constructor(
		private browseService: BrowseService,
		private stateService: StateService,
		private storageService: StorageService,
	) {

	}

	ngOnInit(): void {
		this.getParamSet();
	}

	getParamSet() {
		let tableParams = this.storageService.getForCurrentState('tableParams', undefined, true);
		if (tableParams) {
			for (let prop in this.params) {
				if (typeof tableParams[prop] != 'undefined') this.params[prop] = tableParams[prop];
			}
			if (tableParams.first) this.table.first = tableParams.first;
			if (tableParams.rows) this.rows = tableParams.rows;
		}
		if (typeof this.stateService.params.sec_id != 'undefined') {
			this.params.sec_id = this.stateService.params.sec_id;
		}
		if (typeof this.stateService.params.tym_code != 'undefined') {
			this.params.tym_code = this.stateService.params.tym_code;
		}
		this.table.rows = this.rows;

		if (this.stateService.params.search) {
			this.params.search = this.stateService.params.search;
		}
	}

	saveParamSet() {
		if (this.table) {
			let tableParams = Object.assign({}, this.params, {
				first: this.table.first,
				rows: this.table.rows
			});
			this.storageService.setForCurrentState('tableParams', tableParams, true);
		}
		if (typeof this.params.sec_id != 'undefined') {
			this.stateService.go('.', {sec_id: this.params.sec_id}, {reload: false});
		}
	}

	reset() {
		this.params.search = null;
	}

	refresh(stayOnPage?: boolean) {
		// if (!stayOnPage) this.table._first = 0;
		if (!stayOnPage) this.table.first = 0;
		this.table.onLazyLoad.emit(this.table.createLazyLoadMetadata());
	}

	load(event: LazyLoadEvent) {
		let tmpParams = Object.assign(event, this.params);

		this.saveParamSet();
		let apiCall: Observable<any>;
		let valuesAttr: string = this.browseMode;

		switch (this.browseMode) {
			case 'favoris':
				apiCall = this.browseService.getListFavoris(tmpParams);
				break;
			case 'recherche':
				apiCall = this.browseService.getListSearch(tmpParams);
				valuesAttr = 'ressources';
				break;
		}

		if (this.browseMode == 'recherche' && (!!!this.params.search || this.params.search.length < this.minChars)) {
			return;
		}

		// https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
		Promise.resolve(null).then(() => this.loading = true);
		apiCall.subscribe(
			(response: any) => {
				this.totalRecords = response.total;
				this.prepareUISref(response[valuesAttr]);
				this.values = response[valuesAttr];
			}
		)
		.add(() => { this.loading = false; });
	}

	prepareUISref(values: TypeElementArborescence[]) {
		values.forEach((one: TypeElementArborescence) => {
			one._uiSref = `${this.browseMode}.${one._uiSref}`;
		});
	}

}
@NgModule({
	declarations: [BrowseAdvancedComponent],
	exports: [BrowseAdvancedComponent],
	imports: [
		CommonModule,
		FormsModule,
		UIRouterModule,
		ButtonModule,
		CheckboxModule,
		DataViewModule,
		InputTextModule,
		ToolbarModule,
		FavoriToggleButtonModule,
		GlobalModule,
		MediaDisplayModule,
		MediaIconModule,
		MediasDisplayModule,
		SectionSelectorModule,
		TypeMediaSelectorModule,
		VignetteDisplayModule,
	],
})
export class BrowseAdvancedModule { }
