import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { fileTypes } from '@app/media/media.service';

@Injectable({
	providedIn: 'root'
})
@Pipe({name: 'mimeType'})
export class MimeTypePipe implements PipeTransform {

	constructor() {}

	transform(mimetype?: string, asCode: boolean = false): string {
		if (typeof mimetype == 'undefined' || mimetype == null) {
			return '';
		}
		const found: any = fileTypes.find((filetype: any) => {
			return filetype.mimetypes.some((oneMimeType: 'string') => {
				return mimetype.match(oneMimeType);
			});
		})
		if (found) {
			return (asCode)? found.code : found.label;
		}
		return (asCode)? 'autre' : 'Autre';
	}
}

