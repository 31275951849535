import { Ressource } from '@app/ressource';
import { RestrictionAcces, Responsabilite } from '@app/relation/relation.model';

import { convertDateFieldsToDate, convertDateFieldsToString, ExtensibleObject } from '@helpers/utils';

export class Section extends ExtensibleObject {
	sec_id: number = 0;
	ens_id: number = 1;
	sec_id_parent: number|null = null;
	vig_id: number|null = null;
	sec_date_creation: string|Date|null = null;
	sec_date_modification: string|Date|null = null;
	sec_niveau: number = 0;
	sec_titre: string = '';
	sec_description: string|null = null;
	sec_code: string = '';
	sec_ordre: number = 0;
	sec_avec_ressources: boolean = false;
	sec_ouvert_par_defaut: boolean = false;
	nb_ressources: number = 0;
	nb_ressources_total: number = 0;
	parents?: Section[];
	sections?: Section[];
	ressources: Ressource[] = [];

	restrictions: RestrictionAcces = new RestrictionAcces();
	responsabilites: Responsabilite = new Responsabilite();

	path?: string;
	path_id?: string;
	_uiSref: string = 'section';
	get _uiParams() {
		return {sec_code: this.sec_code};
	}

	static dateFields: string[] = [
		'sec_date_creation',
		'sec_date_modification',
	];


	constructor(sec_id_parent?: number) {
		super();
		if (typeof sec_id_parent != 'undefined') {
			this.sec_id_parent = sec_id_parent;
		}
	}

	static convertDateFieldsToDate(section: Section, utc?: boolean, short?: boolean) {
		convertDateFieldsToDate(section, Section.dateFields, utc, short);
	}

	static convertDateFieldsToString(section: Section, utc?: boolean, short?: boolean) {
		convertDateFieldsToString(section, Section.dateFields, utc, short);
	}

	hasResponsabilites() {
		return this.responsabilites.groupes.length
			|| this.responsabilites.utilisateurs.length
			|| this.responsabilites.magasins.length;
	}
}
