import { convertDateFieldsToDate, convertDateFieldsToString, ExtensibleObject } from '@helpers/utils';

export type VisibiliteMedia = 'totale'|'partielle'|'habillage';

export class Media extends ExtensibleObject {
	med_id: number = 0;
	res_id: number = 0;
	ens_id: number = 0;
	med_date_creation: string|Date|null = null;
	med_date_modification: string|Date|null = null;
	med_titre: string = '';
	med_code: string = '';
	med_description?: string|null = null;
	med_fichier?: string|null = null;
	med_taille?: number|null = null;
	med_url?: string|null = null;
	med_hash?: string|null = null;
	med_liste_tailles: number[]|null = null;
	tym_code: string = 'autre';
	res_code: string = '';
	med_habillage: boolean = false;
	med_visibilite_totale: boolean = true;

	_type?: string;
	_displayableImage?: boolean;
	_embedded_video?: string;
	_uiSref: string = 'media';
	get _uiParams() {
		return {med_code: this.med_code};
	}

	static dateFields: string[] = [
		'med_date_creation',
		'med_date_modification',
	];

	constructor(res_id?: number) {
		super();
		if (res_id) {
			this.res_id = res_id;
		}
	}

	static convertDateFieldsToDate(media: Media, utc?: boolean, short?: boolean) {
		convertDateFieldsToDate(media, Media.dateFields, utc, short);
	}

	static convertDateFieldsToString(media: Media, utc?: boolean, short?: boolean) {
		convertDateFieldsToString(media, Media.dateFields, utc, short);
	}
}
