import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subscriber } from 'rxjs';
import { map } from 'rxjs/operators';

import { IEventListener, EventManagerService } from '@global/event-manager.service';
import { Vignette } from '@app/vignette';
import { clone, prepareQueryParams, stringSort, uid } from '@helpers/utils';

export const vignetteFileType = {
	extensions: ['jpg', 'jpeg', 'png'],
	mimetypes: [
		'image/jpeg',
		'image/png',
	]
};

@Injectable({ providedIn: 'root' })
export class VignetteService implements IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	private _vignettes: Vignette[] = [];
	private _mots_clefs: any[] = [];

	public get vignettes() {
		return [...this._vignettes];
	}

	public get mots_clefs() {
		return [...this._mots_clefs];
	}

	constructor(
		private eventManager: EventManagerService,
		private http: HttpClient
	) {

	}

	ngOnInit() {
		this.eventManager.registerEvent('logout', this, (args: any) => {
			this.reset();
		});
		this.eventManager.registerEvent('reset-vignettes', this, (args: any) => {
			this.reset();
		});
	}

	ngOnDestroy() {
		this.eventManager.unregisterEvent('logout', this);
		this.eventManager.unregisterEvent('reset-vignettes', this);
	}

	public reset() {
		this._vignettes = [];
		this._mots_clefs = [];
	}

	public getUploadUrl() {
		return '/vignettes';
	}

	public getCachedList(refresh: boolean = false): Observable<any> {
		if (!refresh && !!this._vignettes.length) {
			return new Observable<any>((subscriber: Subscriber<any>) => {
				setTimeout(() => { // parfois je ne comprends pas pourquoi il faut en passer par là...
					subscriber.next(this.vignettes);
					subscriber.complete();
				}, 0);
			});
		}
		else {
			return this.getList().pipe(map(
				(response: any) => {
					this._vignettes = response;
					this.prepareMotsClefs();
					return this.vignettes;
				}
			));
		}
	}

	public prepareMotsClefs() {
		let tmp: string [] = [];
		this._vignettes.forEach((vignette: Vignette) => {
			if (vignette.vig_mot_clef && vignette.vig_mot_clef.trim() && tmp.indexOf(vignette.vig_mot_clef) < 0) {
				tmp.push(vignette.vig_mot_clef.trim());
			}
		});
		stringSort(tmp);
		this._mots_clefs = tmp.map((one: string) => {
			return {value: one};
		})
	}

	public getList(params?: any): Observable<any> {
		let tmpParams: any = prepareQueryParams(params);
		let url: string = '/vignettes';
		return this.http.get<any>(url, tmpParams);
	}

	public put(vignette: Vignette) {
		let tmp: any = this.prepareForPost(vignette);
		const url: string = `/vignettes/${vignette.vig_id}`;
		return this.http.put<any>(url, tmp)
		.pipe(map((response: any) => {
			this.reset();
			return response;
		}))
	}

	public prepareForPost(vignette: Vignette) {
		let tmp: any = clone(vignette);
		return tmp;
	}

	public delete(vig_id: number) {
		const url: string = `/vignettes/${vig_id}`;
		return this.http.delete<any>(url)
		.pipe(map((response: any) => {
			this.reset();
			return response;
		}))
	}

	public find(vig_id: number) {
		return this._vignettes.find((one: Vignette) => { return one.vig_id == vig_id; });
	}
}
