import { TransitionService, Transition, UrlService, StateObject, HookMatchCriteria, HookMatchCriterion, IStateMatch } from '@uirouter/core';
import { AuthService } from '@app/auth/auth.service';
import { UtilisateurService } from '@app/utilisateur';

import { EventManagerService } from '@global/event-manager.service';


// This hook redirects to FOC oauth when no token was found in session storage
export const requiresAuthHook = function(transitionService: TransitionService) {
	// Matches if the destination state's data property contains certain values
	const criteria: HookMatchCriteria = {
		to: function(state, transition) { return state && state.data && state.data.requiresAuth; }
	};
	const redirect = (transition: Transition) => {
		const authService: AuthService = transition.injector().get(AuthService);
		const urlService: UrlService = transition.injector().get(UrlService);
		const url: string = urlService.url();
		const stateService = transition.router.stateService;
		if (!authService.hasToken()) {
			console.log('No token', 'redirect to FOC authorization');
			authService.getAuthorizationCode(url);
			return false;
		}
		return true;
	};
	transitionService.onBefore(criteria, redirect, {priority: 1000});
}


// hook to prevent accessing auth.* states when user is already logged in
export function forbiddenWhenAuthHook(transitionService: TransitionService) {
		// Matches if the destination state's data property contains certain values
	const criteria: HookMatchCriteria = {
		to: function(state, transition) { return state && state.data && state.data.forbiddenWhenAuth; }
	};
	const redirect = (transition: Transition) => {
		// console.log('forbiddenWhenAuthHook');
		const authService: AuthService = transition.injector().get(AuthService);
		const stateService = transition.router.stateService;
		if (authService.hasToken()) {
			return stateService.target('home', undefined, {location: true});
		}
		return true;
	};
	transitionService.onBefore(criteria, redirect, {priority: 11});
}


// hook to force the fetching of the current user info before entering the substates
export function meGuardHook(transitionService: TransitionService) {
	// Matches if the destination state's data property has a truthy 'meGuard' property
	const criteria: HookMatchCriteria = {
		to: function(state, transition) { return state && state.data && state.data.meGuard; }
	};
	const redirect = (transition: Transition) => {
		// console.log('meGuardHook');
		const authService: AuthService = transition.injector().get(AuthService);
		const stateService = transition.router.stateService;
		const currentUser = authService.user;
		if (!!!currentUser) {
			return authService.getMe().toPromise()
			.then(
				(ok: any) => {
					return true;
				},
				(error: any) => {
					authService.logout({force: true});
					return Promise.reject();
				}
			);
		}
		return true;
	};
	transitionService.onBefore(criteria, redirect, {priority: 800});
}


// hook to prevent the transition to a state the user shouldn't access
export function canAccessHook(transitionService: TransitionService) {
	const criteria: HookMatchCriteria = {
		to: function(state, transition) { return state && state.data && state.data.droits; }
	};
	const redirect = (transition: Transition) => {
		// console.log('canAccessHook');
		const utilisateurService: UtilisateurService = transition.injector().get(UtilisateurService);
		const stateService = transition.router.stateService;
		const to = transition.to();
		if (utilisateurService.checkIfHasRight(to.data.droits)) {
			// console.log('Can access', to.name, to.data.droits);
			return true;
		}
		console.log('Cannot access', to.name, to.data.droits)
		return stateService.target('home', undefined, {location: true});
	};
	transitionService.onBefore(criteria, redirect, {priority: 10});
}

export function eachTransition(transitionService: TransitionService) {
	const criteria: HookMatchCriteria = {
		to: function(state, transition) { return true; } // always
	};
	const doStuff = (transition: Transition) => {
		const eventManager: EventManagerService = transition.injector().get(EventManagerService);
		eventManager.emit('hide-search');
		eventManager.emit('scroll-top');
		eventManager.emit('close-modals');
	};
	transitionService.onBefore(criteria, doStuff, {priority: 10});
}
