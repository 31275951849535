import { Component, NgModule, OnInit } from '@angular/core';

import { UIRouterModule } from '@uirouter/angular';

import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { SidebarModule } from 'primeng/sidebar';

import { AuthService } from '@app/auth/auth.service';
import { EventManagerService } from '@global/event-manager.service';
import { GzMenubarModule } from '@app/gz-menubar';
import { menuItems } from '@global/menu-items';
import { MenuService } from '@global/menu.service';
import { SearchWidgetModule } from '@app/browse/search-widget';

import { environment, enseigne } from '@environments/environment';


@Component({
	selector: 'root',
	templateUrl: './root.html',
	providers: []
})
export class RootComponent implements OnInit {

	menuItems: any[] = [];
	userMenuItems: any[] = [];
	urlFOC: string = environment[enseigne].foc;

	display: boolean = false

	constructor(
		private authService: AuthService,
		private eventManager: EventManagerService,
		private menuService: MenuService,
	) {

	}

	ngOnInit() {
		this.menuItems = this.menuService.prepareItems(menuItems);
		this.menuService.setMenuItems(this.menuItems);

		let userName = '';
		if (this.authService.user) {
			userName = this.authService.user.uti_prenom + ' ' + this.authService.user.uti_nom + '\n' + this.authService.user.uti_email;

		}
		this.userMenuItems = [
			{ label: userName, disabled: true },
		];

		this.userMenuItems = this.userMenuItems.concat([
			{ separator: true },
			{
				label: 'Franchise On Cloud',
				icon: 'pi pi-external-link',
				url: this.urlFOC,
				target: '_blank'
			},
			{
				label: 'Se déconnecter',
				icon: 'pi pi-sign-out',
				command: () => {this.logout()}
			}
		]);
	}

	logout() {
		this.eventManager.emit('logout', {manual: true});
	}

}

@NgModule({
	declarations: [
		RootComponent
	],
	exports: [
		RootComponent
	],
	imports: [
		UIRouterModule,
		ButtonModule,
		MenuModule,
		SidebarModule,
		GzMenubarModule,
		SearchWidgetModule,
	],
})
export class RootModule { }
