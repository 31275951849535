import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment, enseigne } from '@environments/environment';

export const prefixRoute = function(endpoint: string) {
	return `${environment[enseigne].api}${endpoint}`;
}

@Injectable()
export class EnseigneInterceptor implements HttpInterceptor {

	constructor() {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		const endpoint = request.url;
		const isApiUrl = endpoint.startsWith('/');

		if (isApiUrl) {
			request = request.clone({
				url: prefixRoute(endpoint),
				setHeaders: {
					enseigne: enseigne
				}
			});
		}
		return next.handle(request);
	}
}
