import { NgModule, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Location } from '@angular/common';

import { StateService } from '@uirouter/angular';
import { TargetState, UrlService } from '@uirouter/core';

import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';

import { AuthService } from '@app/auth/auth.service';
import { StateUtilsService } from '@helpers/state-utils.service';
import { environment, enseigne } from '@environments/environment';

@Component({
	selector: 'auth',
	templateUrl: './auth.html',
})
export class AuthComponent  implements OnInit {

	authorization_code?: string;
	redirect_state?: string;
	uiSref?: string;
	uiParams?: any;
	oauthResponse:any = null;
	me: any = null;
	urlFOC: string = environment[enseigne].foc;
	loading: boolean = false;
	access_denied: boolean = false;
	title: string = environment[enseigne].title;

	constructor(
		private authService: AuthService,
		private location: Location,
		private stateService: StateService,
		private stateUtilsService: StateUtilsService,
		private urlService: UrlService,
	) {

	}

	ngOnInit() {
		this.access_denied = this.stateService.params['error'] &&  this.stateService.params['error'] == 'access_denied';
		this.redirect_state = this.stateService.params['state'];
		this.authorization_code = this.stateService.params['code'];

		// faire sauter tous les paramètres de l'url pour permettre de refresh la page sans avoir de parasites
		let path = this.location.path();
		path = path.split('?')[0];
		this.location.replaceState(path);

		this.getAccessToken();
	}

	getAuthorizationCode() {
		this.authService.getAuthorizationCode();
	}

	getAccessToken() {
		if (this.authorization_code) {
			this.loading = true;
			this.authService.getAccessToken(this.authorization_code)
			.subscribe(
				(response: any) => {
					this.oauthResponse = response;
					// retrieve the target state
					if (this.redirect_state) {
						let match: TargetState|null = this.stateUtilsService.matchUrlToState(this.redirect_state);
						if (match) {
							this.urlService.url(this.redirect_state)
							// this.uiSref = match.name();
							// this.uiParams = match.params();
							return;
						}
					}
					this.stateService.go('home');
				},
				(error: any) => {
					this.access_denied = true;
				}
			);
		}
	}

}

@NgModule({
	declarations: [
		AuthComponent
	],
	imports: [
		CommonModule,
		ButtonModule,
		CardModule,
		ProgressSpinnerModule,
		ToastModule,
	],
	exports: [AuthComponent],
})
export class AuthModule { }
