import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { StateService } from '@uirouter/angular';
import { Transition } from '@uirouter/core';

import { AuthService } from '@app/auth/auth.service';

import { clone, isDefined, isEmpty } from '@helpers/utils';
import { environment, enseigne } from '@environments/environment';

declare const gtag: {
	(...args: any[]): () => void;
};

export interface EventProperties {
	[key: string]: any;
	timestamp: number;
	page?: string ;
	mag_id?: number;
	uti_email?: string;
	uti_id?: number;
	uti_uid?: string;
}

@Injectable({ providedIn: 'root' })
export class AnalyticsService {

	constructor(
		private authService: AuthService,
		private http: HttpClient,
		private stateService: StateService
	) {
		this.loadAnalytics();
	}

	public loadAnalytics() {
		if (environment[enseigne].analyticsId) {
			// register google tag manager
			const gTagManagerScript = document.createElement('script');
			gTagManagerScript.async = true;
			gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment[enseigne].analyticsId}`;
			document.head.appendChild(gTagManagerScript);

			// register google analytics
			const gaScript = document.createElement('script');
			gaScript.innerHTML = `
				window.dataLayer = window.dataLayer || [];
				function gtag() { dataLayer.push(arguments); }
				gtag('js', new Date());
				gtag('config', '${environment[enseigne].analyticsId}', {
					'enseigne': '${enseigne}'
				});
			`;
			document.head.appendChild(gaScript);
		}
	}

	private setCommonEventProperties(eventProperties: EventProperties) {
		const now = new Date();
		eventProperties.timestamp = Math.round(now.getTime()/1000);
		eventProperties.page = this.stateService.current.url;
		if (this.authService.user) {
			eventProperties.uti_email = this.authService.user.uti_email;
			eventProperties.uti_id = this.authService.user.uti_id;
		}
	}

	public trackEvent(name: string, eventProperties: any = {}) {
		if (window.hasOwnProperty('gtag')) {
			let tmpData = clone(eventProperties);
			this.setCommonEventProperties(tmpData);
			gtag('event', name, tmpData);
		}
	}


}
