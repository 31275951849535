<div class="grid browse-container">

	<!-- <div class="col max-h-full max-w-30rem"> -->
	<div class="col browse-section-tree ">
		<section-tree
			#sectionTree
			selectionMode="single"
			[expandedPath]="expandedPath"
			[selectFirstWithStuff]="selectFirstWithStuff"
			(onNodeSelect)="onSectionSelect($event)"
			(onNodeExpand)="onSectionExpand($event)"
			(onNodeCollapse)="onSectionCollapse($event)"
		></section-tree>
	</div>

<!-- 	<div class="col max-h-full">
		<ng-container *ngIf="selectedSection">
			<h2 class="mt-0 flex align-items-center">
				<vignette-display [vignette]="selectedSection.vig_id" class="mr-2 flex-shrink-0"></vignette-display>
				<span>{{selectedSection.sec_titre}}</span>
			</h2>
			<dataview-ressources [sec_id]="selectedSection.sec_id"></dataview-ressources>
		</ng-container>
	</div> -->

	<div class="col browse-display">
		<ui-view name="col2" class="">
			<p-card>Sélectionnez une section pour afficher son contenu</p-card>
		</ui-view>
	</div>

</div>
