

<div class="p-input-icon-left p-input-icon-right" #searchWidget>

	<i class="pi pi-search"></i>
	<input
		type="text"
		pInputText
		[(ngModel)]="search"
		(ngModelChange)="searchOverlay.show($event, searchWidget);"
		(onDebounce)="load()"
		(keyup)="onKeyUp($event)"
		(click)="searchOverlay.show($event, searchWidget)"
		placeholder="Rechercher..."
	/>
	<i class="pi pi-times" (click)="reset();" *ngIf="search"></i>
	<i *ngIf="!search"></i>
	<i *ngIf="!search"></i>
</div>

<p-overlayPanel #searchOverlay styleClass="search-overlay" appendTo="body" (onShow)="onOverlayShow($event)">
	<ng-template pTemplate>

		<div class="text-center" *ngIf="!loading && search.length < minChars ">
			Saisissez au moins {{minChars}} caractères
		</div>

		<div class="flex" *ngIf="loading" >
			<p-skeleton shape="square" size="3rem" styleClass="mr-2"></p-skeleton>
			<div class="w-full">
				<p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
				<p-skeleton width="75%"></p-skeleton>
			</div>
		</div>

		<div class="text-center" *ngIf="!loading && search.length >= minChars && results.length == 0">
			Aucun résultat
		</div>

		<div class="search-widget-results" *ngIf="!loading && search.length >= minChars && results.length > 0">
			<div class="grid grid-nogutter item" *ngFor="let item of results">
				<div class="col-fixed liste-media-thumb center-all">
					<vignette-display [vignette]="item.vig_id" *ngIf="!item.med_id"></vignette-display>

					<ng-container *ngIf="item.med_id" [ngSwitch]="item.tym_code">
						<div *ngSwitchCase="'image'" class="center-all">
							<media-display [media]="item" [showTitle]="false"></media-display>
						</div>

						<ng-container *ngSwitchDefault="">
							<media-icon [tym_code]="item.tym_code"></media-icon>
						</ng-container>
					</ng-container>
				</div>

				<div class="col flex align-items-center">
					<div class="w-full flex" *ngIf="!item.med_id && item.res_id">
						<div class="flex-grow-1">
							<a class="ressource-name text-color font-bold" [uiSref]="item._uiSref" [uiParams]="item._uiParams">{{item.res_titre}}</a>
							<div class="ressource-date-modification text-sm font-italic font-normal">Mis à jour le {{item.res_date_modification | date }}</div>
						</div>
						<favori-toggle-button class="flex-shrink-0" [ressource]="item"></favori-toggle-button>
					</div>
					<div *ngIf="item.med_id">
						<a class="ressource-name text-color font-bold" [uiSref]="item._uiSref" [uiParams]="item._uiParams">{{item.med_titre}}</a>
					</div>
				</div>

			</div>

			<div class="text-center text-sm item" *ngIf="!loading && search && total > limit">
				<a uiSref="recherche" [uiParams]="{search: search}" [uiOptions]="{reload: true}">
					<button
						type="button"
						pButton
						class="p-button p-button-sm p-button-text"
						[label]="showMoreLabel"
					></button>
				</a>
			</div>
		</div>


		<div class="text-center mt-2">
			<a uiSref="recherche" [uiParams]="{search: search}" [uiOptions]="{reload: true}">
				<button
					type="button"
					pButton
					class="p-button p-button-sm p-button-text"
					[label]="advancedSearchLabel"
					icon="pi pi-sliders-h"
				></button>
			</a>
		</div>

	</ng-template>
</p-overlayPanel>









<!--
<span class="autocomplete-input-icon-left">
	<label for="recherche-globale"><i class="pi pi-search"></i></label>
	<p-autoComplete
		inputId="recherche-globale"
		name="recherche-globale"
		[(ngModel)]="selected"
		[suggestions]="results"
		[lazy]="true"
		[showClear]="true"
		(completeMethod)="search($event)"
		placeholder="Rechercher..."
		appendTo="body"
		panelStyleClass="search-widget-result"
	>
		<ng-template let-item pTemplate="item">
			<div class="grid">
				<div class="col-fixed liste-media-thumb center-all">
					<vignette-display [vignette]="item.vig_id" *ngIf="!item.med_id"></vignette-display>

					<ng-container *ngIf="item.med_id" [ngSwitch]="item.tym_code">
						<div *ngSwitchCase="'image'" class="center-all">
							<media-display [media]="item" [showTitle]="false"></media-display>
						</div>

						<ng-container *ngSwitchDefault="">
							<media-icon [tym_code]="item.tym_code"></media-icon>
						</ng-container>
					</ng-container>
				</div>

				<div class="col">
					<div *ngIf="!item.med_id && item.res_id">
						<a class="ressource-name text-color font-bold" [uiSref]="item._uiSref" [uiParams]="item._uiParams">{{item.res_titre}}</a>
						<div class="ressource-date-modification text-sm font-italic font-normal">Mis à jour le {{item.res_date_modification | date }}</div>
					</div>
					<div *ngIf="item.med_id">
						{{item.med_titre}}
					</div>
				</div>

			</div>
		</ng-template>

		<ng-template pTemplate="footer">
			<div class="search-widget-footer">
				<a uiSref="search" uiParams="{search: selected}">
					<button
						type="button"
						pButton
						class="p-button label-md-none"
						label="Recherche avancée"
						icon="pi pi-search"
					></button>
				</a>
			</div>
		</ng-template>

		<ng-template pTemplate="loader">
			<div class="flex">
			<p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
				<div style="flex: 1">
					<p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
					<p-skeleton width="75%"></p-skeleton>
				</div>
			</div>
		</ng-template>

	</p-autoComplete>

</span>

 -->
