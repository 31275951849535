import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IEventListener, EventManagerService } from '@global/event-manager.service';

import { MenuItem } from 'primeng/api';
import { UtilisateurService } from '@app/utilisateur';

import { clone, uid } from '@helpers/utils';

export interface MenuItemExtended extends MenuItem {
	active?: boolean;
	uiParams?: string;
}

@Injectable({ providedIn: 'root' })
export class MenuService implements IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	private toggleMenuSubject = new Subject<boolean>();
	public toggleMenu$ = this.toggleMenuSubject.asObservable();

	private toggleMobileMenuSubject = new Subject<boolean>();
	public toggleMobileMenu$ = this.toggleMobileMenuSubject.asObservable();

	private menuItemsSubject: BehaviorSubject<MenuItemExtended[]> = new BehaviorSubject<MenuItemExtended[]>([]);
	public menuItems$ = this.menuItemsSubject.asObservable();

	constructor(
		private utilisateurService: UtilisateurService,
		private eventManager: EventManagerService
	) {
		this.eventManager.registerEvent('logout', this, (args: any) => {
				this.setMenuItems([]);
			}
		);
	}

	toggleMenu(toggle?: boolean): void {
		this.toggleMenuSubject.next(!!toggle);
	}

	toggleMobileMenu(toggle?: boolean): void {
		this.toggleMobileMenuSubject.next(!!toggle);
	}

	setMenuItems(items: any): void {
		const preppedItems = this.prepareItems(items);
		this.menuItemsSubject.next(preppedItems);
	}

	checkItemRight(item: any) {
		let hasRight = true;
		if (typeof item.droits != 'undefined') {
			hasRight = this.utilisateurService.checkIfHasRight(item.droits);
		}
		return hasRight;
	}

	prepareItems(items: any): MenuItemExtended[] {
		const tmp = clone(items);

		for (let i = tmp.length - 1; i >= 0 ; i--) {
			if (tmp[i].items && tmp[i].items.length) { // has sublevels
				tmp[i].items = this.prepareItems(tmp[i].items);
				if (tmp[i].items.length > 1) {
					// tmp[i]['expanded'] = true; // the "expanded" state is managed in the menu definition
				}
				else if (tmp[i].items.length == 1 && !!!tmp[i].keepIfHasSingleChild) { // if only one sublevel, and parent is replaceable, replace the parent
					tmp.splice(i, 1, tmp[i].items[0]);
				}
				else if (tmp[i].items.length == 0) { // if no sublevel, delete the parent
					tmp.splice(i, 1);
				}
			}
			else {
				if (!this.checkItemRight(tmp[i])) {
					tmp.splice(i, 1);
				}
				else {
					delete tmp[i].droits;
				}
			}
		}

		return tmp as MenuItemExtended[];
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('logout', this);
	}


}
