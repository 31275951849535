<ng-container *ngIf="!url && !iconOnly">
	<i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
</ng-container>


<ng-container *ngIf="media">
	<ng-container [ngSwitch]="media._type">

		<ng-container *ngSwitchCase="'image'">
			<ng-container *ngIf="!media._displayableImage || iconOnly">
				<ng-container *ngIf="showTitle">
					<a href="{{url}}" title="{{media.med_titre}}">
						<media-icon [tym_code]="media.tym_code" class="mr-2"></media-icon>
						<span *ngIf="showTitle">{{media.med_titre}}</span>
					</a>
				</ng-container>
				<ng-container *ngIf="!showTitle">
					<media-icon [tym_code]="media.tym_code" class=""></media-icon>
				</ng-container>
			</ng-container>

			<ng-container *ngIf="media._displayableImage && !iconOnly">
				<img
					[src]="url"
					(click)="onImgClicked(media.med_id)"
					[title]="media.med_titre"
					width="auto"
					height="auto"
					loading="lazy"
				>
			</ng-container>
		</ng-container>

		<ng-container *ngSwitchCase="'lien'">
			<a href="{{media.med_url}}" title="{{media.med_titre}}" target="_blank">
				<media-icon [tym_code]="media.tym_code" class="mr-2"></media-icon>
				<span *ngIf="showTitle">{{media.med_titre}}</span>
			</a>
		</ng-container>

		<ng-container *ngIf="showPdf">
			<ng-container *ngSwitchCase="'pdf'">
				<embed [src]="url" class="pdf-viewer" type="application/pdf">
			</ng-container>
		</ng-container>

		<ng-container *ngSwitchCase="'video'">
			<div [injectHTML]="media._embedded_video" *ngIf="media._embedded_video"></div>

			<video muted class="" controls *ngIf="!media._embedded_video">
				<source [src]="url" [type]="media.med_mimetype" />
			</video>
		</ng-container>

		<ng-container *ngSwitchDefault="">
			<a [href]="url" title="{{media.med_titre}}" target="_blank">
				<media-icon [tym_code]="media.tym_code" class="mr-2"></media-icon>
				<span *ngIf="showTitle">{{media.med_titre}}</span>
			</a>
		</ng-container>

	</ng-container>
</ng-container>
<!--<div>{{media|json}}</div>-->
