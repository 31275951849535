import { NgModule, Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { StateService, UIRouterModule } from '@uirouter/angular';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { SkeletonModule } from 'primeng/skeleton';

import { GlobalModule } from '@global/global.module';
import { BrowseService } from '@app/browse';
import { FavoriToggleButtonModule } from '@app/ressource/favori-toggle';
import { MediaDisplayModule } from '@app/media';
import { MediaIconModule } from '@app/media';
import { VignetteDisplayModule } from '@app/vignette/vignette-display';
import { IEventListener, EventManagerService } from '@global/event-manager.service';

import { uid } from '@helpers/utils';

@Component({
	selector: 'search-widget',
	templateUrl: './search-widget.html',
	providers: []
})
export class SearchWidgetComponent implements OnInit, IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	@ViewChild('searchOverlay', {static: true}) searchOverlay!: OverlayPanel;


	loading: boolean = false;
	total: number = 0;
	results: any[] = [];
	search: any = '';

	limit: number = 10;
	minChars: number = 3;

	showMoreLabel: string = 'Voir plus de résultats';
	advancedSearchLabel: string = 'Recherche avancée';

	constructor(
		private eventManager: EventManagerService,
		private browseService: BrowseService,
		private stateService: StateService
	) { }

	ngOnInit(): void {
		this.eventManager.registerEvent('hide-search', this, (args: any) => {
			this.searchOverlay.hide();
			this.reset();
		});
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('hide-search', this);
	}

	reset() {
		this.search = '';
		this.total = 0;
		this.results = [];
	}

	onOverlayShow(event: any) {
		this.load();
	}

	onKeyUp(event: any) {
		if (event.key === 'Enter') {
			this.stateService.go('recherche', {search: this.search}, {reload: true});
		}
	}

	load(event?: any) {
		this.total = 0;
		this.results = [];
		if (this.search && this.search.length >= this.minChars) {
			this.loading = true;
			this.browseService.getListSearch({search: this.search, limit: this.limit})
			.subscribe(
				(response: any) => {
					this.total = response.total;
					this.results = response.resultats;
				}
			)
			.add(() => { this.loading = false; });
		}
	};

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		UIRouterModule,
		AutoCompleteModule,
		ButtonModule,
		OverlayPanelModule,
		SkeletonModule,
		FavoriToggleButtonModule,
		GlobalModule,
		InputTextModule,
		MediaDisplayModule,
		MediaIconModule,
		VignetteDisplayModule,
	],
	exports: [SearchWidgetComponent],
	declarations: [SearchWidgetComponent]
})
export class SearchWidgetModule { }

