import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject, Subscriber } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfirmationService } from 'primeng/api';
import { TreeNode } from 'primeng/api';

import { IEventListener, EventManagerService } from '@global/event-manager.service';
import { RelationService } from '@app/relation';
import { Section } from '@app/section';

import {
	clone,
	countMaxBranchings,
	formatNodesPaths,
	listToTree,
	numericSort,
	treeToList,
	uid
} from '@helpers/utils';

import { environment } from '@environments/environment';


@Injectable({ providedIn: 'root' })
export class SectionService implements IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	private _sections: Section[] = [];
	public get sections(): Section[] {
		return [...this._sections];
	}

	private _sectionsTree: TreeNode[] = [];
	public get sectionsTree(): TreeNode[] {
		return [...this._sectionsTree];
	}

	private _mesSections: Section[] = [];
	public get mesSections(): Section[] {
		return [...this._mesSections];
	}

	private _mesSectionsTree: TreeNode[] = [];
	public get mesSectionsTree(): TreeNode[] {
		return [...this._mesSectionsTree];
	}

	constructor(
		private eventManager: EventManagerService,
		private confirmationService: ConfirmationService,
		private http: HttpClient,
		private relationService: RelationService,
	) {

	}

	ngOnInit() {
		this.eventManager.registerEvent('logout', this, (args: any) => {
			this.reset();
		});
		this.eventManager.registerEvent('reset-sections', this, (args: any) => {
			this.reset();
		});
	}

	ngOnDestroy() {
		this.eventManager.unregisterEvent('logout', this);
		this.eventManager.unregisterEvent('reset-sections', this);
	}

	public reset() {
		this._sections = [];
		this._sectionsTree = [];
		this._mesSections = [];
		this._mesSectionsTree = [];
	}

	public getList(refresh: boolean = false): Observable<any> {
		if (!refresh && this.sections.length > 0) {
			return new Observable<any>((subscriber: Subscriber<any>) => {
				subscriber.next(this.sections);
				subscriber.complete();
			});
		}
		else {
			const url: string = '/admin/sections';
			return this.http.get<any>(url)
			.pipe(map(
				(response: any) => {
					this._sections = response;
					numericSort(this._sections, 'sec_ordre');
					// préparation données
					this.formatLabels(this._sections, true);
					this.formatPaths(this._sections, false);
					this.formatPathsId(this._sections);
					this._sections = this.prepareSectionsFromServer(this.sections);
					// on fait notre arbre
					this._sectionsTree = listToTree(this.sections, 'sec_id', 'sec_id_parent', null, 'children', true);
					// on le remet en mode liste
					this._sections = treeToList(this.sectionsTree, 'children', 'data');
					// on ajoute un index sur chaque élément pour pouvoir conserver l'ordre de l'arbre
					this._sections.forEach((one: Section, index: number)=> {one.index = index;});
					return this.sections;
				}
			));
		}
	}

	public getSectionsTree(refresh: boolean = false): Observable<any> {
		if (!refresh && this.sectionsTree.length > 0) {
			return new Observable<any>((subscriber: Subscriber<any>) => {
				subscriber.next(this.sectionsTree);
				subscriber.complete();
			});
		}
		else {
			return this.getList(refresh)
			.pipe(map(
				(response: any) => {
					return this.sectionsTree;
				}
			));
		}
	}

	public get(sec_id: number, admin?: boolean) {
		let url: string = '/sections';
		if (admin) url = `/admin${url}`;
		url = `${url}/${sec_id}`;
		return this.http.get<any>(url)
		.pipe(map(
			(response: any) => {
				return this.prepareSectionFromServer(response);
			}
		));
	}

	public getByCode(sec_code: string) {
		let url: string = '/sections/code';
		url = `${url}/${sec_code}`;
		return this.http.get<any>(url)
		.pipe(map(
			(response: any) => {
				return this.prepareSectionFromServer(response);
			}
		));
	}

	public post(section: Section) {
		let tmp: any = this.prepareForPost(section);
		const url: string = '/admin/sections';
		return this.http.post<any>(url, tmp)
		.pipe(map(
			(response: any) => {
				this.reset();
				return response;
			})
		);
	}

	public put(section: Section) {
		let tmp: any = this.prepareForPost(section);
		const url: string = `/admin/sections/${section.sec_id}`;
		return this.http.put<any>(url, tmp)
		.pipe(map(
			(response: any) => {
				this.reset();
				return response;
			})
		);
	}

	private prepareForPost(section: Section) {
		let tmp: any = clone(section);
		tmp.restrictions = this.relationService.prepareForPost(tmp.restrictions);
		tmp.responsabilites = this.relationService.prepareForPost(tmp.responsabilites);
		delete tmp.sections_enfants;
		delete tmp.ressources;
		return tmp;
	}

	public maybeDelete(section: Section): Observable<any> {
		let message = `Souhaitez-vous vraiment supprimer la section <b>${section.sec_titre} </b>?`;

		if (section.nb_ressources) {
			message = `Cette section a des ressources liées.
				<br><br>
				Si ces ressources ne sont liées à aucune autre section, elles deviendront orphelines.
				<br><br>
				${message}
			`;
		}

		const subject = new Subject();
		this.confirmationService.confirm({
			defaultFocus: 'reject',
			message: message,
			accept: () => {
				this.delete(section.sec_id)
				.subscribe(
					(response: any) => {
						this.eventManager.emit('toast', {severity: 'success', summary: 'Section supprimée'});
						subject.next(true);
						subject.complete();
					}
				)
			},
			reject: () => {
				subject.error(false);
			}
		});
		return subject.asObservable();
	}

	public delete(sec_id: number) {
		const url: string = `/admin/sections/${sec_id}`;
		return this.http.delete<any>(url)
		.pipe(map(
			(response: any) => {
				this.reset();
				return response;
			})
		);
	}

	public prepareSectionFromServer(section: Section): Section {
		section = Object.assign(new Section(), section);
		if (section.restrictions) {
			this.relationService.format(section.restrictions);
		}
		if (section.responsabilites) {
			this.relationService.format(section.responsabilites);
		}
		Section.convertDateFieldsToDate(section, true);
		return section;
	}

	public prepareSectionsFromServer(sections: Section[]): Section[] {
		sections.forEach((section: Section, index: number) => {
			sections[index] = this.prepareSectionFromServer(section);
		});
		return sections;
	}

	public disableItemsFunction = (
		tree: TreeNode[],
		thisNode: TreeNode,
		maxBranchings?: number,
		selectable: boolean = true
	) => {

		// si aucun noeud de critère, on autorise tout
		// sinon, on ne ne permet pas d'affecter comme parent sur la section courante:
		// - la section elle-même et ses enfants
		// - toute autre section (et ses enfants) qui ne peut contenir la section courante sans que son nombre d'embranchement ne dépasse la profondeur max
		if (!thisNode) {
			selectable = true;
			maxBranchings = 0;
		}

		if (thisNode && typeof maxBranchings == 'undefined') {
			maxBranchings = countMaxBranchings(thisNode);
		}

		tree.forEach((node: any) => {
			let _selectable: boolean = true;

			if (typeof thisNode != 'undefined') {
				if (
					!selectable
					|| node.data.sec_id == thisNode.data.sec_id
					|| node.depth + maxBranchings >= environment.maxSectionDepth
				) {
					_selectable = false;
				}
			}

			node.selectable = _selectable;
			if (!node.selectable) {
				node.expanded = false;
			}

			if (node.children && node.children.length) {
				this.disableItemsFunction(node.children, thisNode, maxBranchings, _selectable)
			}
		});
	}

	public find(secCodeOrId: string|number, mine: boolean = false): Section|undefined {
		let attr: keyof Section = 'sec_id';
		if (typeof secCodeOrId == 'string') {
			attr = 'sec_code';
		}
		let array: Section[] = (mine)? this.mesSections : this.sections;
		return array.find((one: Section) => {
			return one[attr] == secCodeOrId;
		});
	}


	public formatLabel(section: Section, showCode: boolean = false) {
		let result: string = section.sec_titre;
		if (showCode) {
			result = `${result} (${section.sec_code})`;
		}
		return result;
	}

	public formatLabels(sections: Section[], showCode: boolean = false) {
		sections.forEach((one: any) => {
			one.label = this.formatLabel(one, showCode);
		});
	}

	public formatPaths(sections: Section[], showCode: boolean = false, pathSeparator: string = '/',) {
		formatNodesPaths(sections, 'sec_id', 'sec_id_parent', (item: any) =>{return this.formatLabel(item, showCode)}, pathSeparator);
	}

	public formatPathsId(sections: Section[]) {
		formatNodesPaths(sections, 'sec_id', 'sec_id_parent', function(item: Section){return item.sec_id;},  '/', 'path_id');
	}

	public getMesSections(refresh: boolean = false) {
		if (!refresh && this.mesSections.length > 0) {
			return new Observable<any>((subscriber: Subscriber<any>) => {
				subscriber.next(this.mesSections);
				subscriber.complete();
			});
		}
		else {
			const url: string = '/sections';
			return this.http.get<any>(url)
			.pipe(map(
				(response: any) => {
					this._mesSections = response;
					this.formatLabels(this._mesSections, true);
					this.formatPaths(this._mesSections, false);
					this.formatPathsId(this._mesSections);
					numericSort(this._mesSections, 'sec_ordre');
					this._mesSections = this.prepareSectionsFromServer(this.mesSections);
					this._mesSectionsTree = listToTree(this.mesSections, 'sec_id', 'sec_id_parent', null, 'children', true);
					this._mesSections = treeToList(this.mesSectionsTree, 'children', 'data');
					return this.mesSections;
				}
			));
		}
	}

	public getMesSectionsTree(refresh: boolean = false): Observable<any> {
		if (!refresh && this.mesSectionsTree.length > 0) {
			return new Observable<any>((subscriber: Subscriber<any>) => {
				subscriber.next(this.mesSectionsTree);
				subscriber.complete();
			});
		}
		else {
			return this.getMesSections(refresh)
			.pipe(map(
				(response: any) => {
					return this.mesSectionsTree;
				}
			));
		}
	}

	public canAccessSection(section: Section): boolean {
		return !this.mesSections.find((one: Section) => {return one.sec_id == section.sec_id;})
	}

	public getPathIdFromCode(sec_code: string) {
		let found: Section|undefined = this.mesSections.find((one: Section) => {return one.sec_code == sec_code; });
		if (found) {
			return found.path_id;
		}
		return null;
	}

}
