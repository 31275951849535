<gz-menubar class="app-menu" [model]="menuItems">
	<ng-template pTemplate="start">
		<a class="logo-enseigne logo-enseigne-topbar mr-3 block" uiSref="home"></a>
	<!-- 	<div><button type="button" pButton class="p-button-text" icon="pi pi-bars" (click)="display=!display"></button></div> -->
	</ng-template>

	<div class="white-space-nowrap">
		<search-widget></search-widget>
		<button
			type="button"
			pButton
			class="p-button-rounded p-button-text"
			icon="pi pi-user"
			(click)="userMenu.toggle($event)"
		></button>
		<p-menu #userMenu [popup]="true" [model]="userMenuItems" appendTo="body"></p-menu>
	</div>

</gz-menubar>

<div class="app-content">
<!-- 	<p-sidebar [(visible)]="display" position="left">
		<app-menu ></app-menu>
	</p-sidebar> -->
	<ui-view name="rootContent"></ui-view>
</div>

