<p-dataView
	#tableBrowse
	[value]="values"
	[lazy]="true"
	(onLazyLoad)="load($event)"
	[loading]="loading"
	[paginator]="true"
	[rows]="rows"
	[showCurrentPageReport]="true"
	[totalRecords]="totalRecords"
	currentPageReportTemplate="{first} à {last} de {{totalRecords | number}} élément(s)"
	[rowsPerPageOptions]="[10,25,50]"
>
	<ng-template pTemplate="header">
		<div class="p-fluid formgrid grid flex-grow-1">
			<div class="field p-inputgroup col-fixed max-width-md">
				<input pInputText type="text" [(ngModel)]="params.search" (onDebounce)="refresh()" placeholder="Rechercher..." />
				<button type="button" class="p-inputgroup-addon" icon="pi pi-times" pButton label="" [disabled]="!params.search || !params.search" (click)="reset();"></button>
			</div>

		<!-- 	<div class="field col-fixed" *ngIf="browseMode == 'recherche'">
				<section-selector
					name="sec_id"
					[(ngModel)]="params.sec_id"
					[objectsOfUserOnly]="true"
					[valuesAsIds]="true"
					[asTree]="true"
					[nullOption]="true"
					placeholder="Section..."
					[disabled]="loading"
					(ngModelChange)="refresh()"
				></section-selector>
			</div> -->

			<div class="field col-fixed" *ngIf="browseMode != 'favoris'">
				<type-media-selector
					name="sec_id"
					[(ngModel)]="params.tym_code"
					[nullOption]="true"
					placeholder="Type de média..."
					[disabled]="loading"
					(ngModelChange)="refresh()"
				></type-media-selector>
			</div>


			<div class="field-checkbox col-fixed" *ngIf="browseMode == 'recherche'">
				<p-checkbox
					[(ngModel)]="params.obsolete"
					name="obsolete"
					inputId="obsolete"
					[binary]="true"
					[disabled]="loading"
					(ngModelChange)="refresh()"
				></p-checkbox>
				<label for="obsolete" title="">Obsolètes et archives</label>
			</div>

		</div>
<!-- 		<div class="flex flex-column md:flex-row md:justify-content-between">
			<p-dataViewLayoutOptions></p-dataViewLayoutOptions>
		</div> -->
	</ng-template>

	<ng-template  pTemplate="empty">
		<div class="text-center">
			<span *ngIf="browseMode != 'recherche' || params.search && params.search.length >= minChars">
				Aucun résultat pour ces critères
			</span>
			<span *ngIf="browseMode == 'recherche' && (!params.search || params.search.length < minChars)">
				Veuillez saisir au moins {{minChars}} caractères pour lancer la recherche
			</span>
		</div>
	</ng-template>

	<ng-template let-ressource pTemplate="listItem">
		<div class="col-12 surface-card border-round-sm overflow-hidden mb-2 shadow-1">
			<div class="grid ressource-entry m-0 align-items-center" >

				<div class="col-fixed flex-shrink-0 ressource-entry-vignette">
					<vignette-display [vignette]="ressource.vig_id"></vignette-display>
				</div>

				<div class="col flex-grow-1 ressource-entry-title">
					<div class="grid align-items-center">
						<div class="col flex-grow-1">
							<h3 class="ressource-name flex align-items-center">
								<a class="text-color-inherit" uiSref=".ressource" [uiParams]="{res_code: ressource.res_code}">{{ressource.res_titre}}</a>
								<favori-toggle-button class="flex-shrink-0" [ressource]="ressource"></favori-toggle-button>
							</h3>
							<div class="ressource-date-modification text-sm font-italic font-normal">Mis à jour le {{ressource.res_date_modification | date }}</div>
						</div>
					</div>
				</div>
			</div>

			<div class="grid grid-nogutter pl-0 pr-0 col-12 align-items-stretch" *ngIf="ressource.nb_medias_telechargeables">
				<div class="col-fixed flex-shrink-0 liste-media-thumb-fake"></div>
				<div class="col">
					<medias-display [medias]="ressource.medias" layout="grid"></medias-display>
				</div>

			</div>

		</div>

	</ng-template>

	<ng-template let-ressource pTemplate="gridItem">

	</ng-template>
</p-dataView>

