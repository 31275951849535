import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subscriber } from 'rxjs';
import { map} from 'rxjs/operators';

import { AuthService } from '@app/auth/auth.service';
import { GroupeUtilisateur } from '@app/groupe-utilisateur';
import { IEventListener, EventManagerService } from '@global/event-manager.service';
import { Magasin } from '@app/magasin';
import { Utilisateur } from '@app/utilisateur';

import { intersects, uid } from '@helpers/utils';

@Injectable({ providedIn: 'root' })
export class UtilisateurService implements IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	private _utilisateurs: Utilisateur[] = [];
	private _mesUtilisateurs: Utilisateur[] = [];
	private _mesMagId: number[] = [];
	private _mesGrfId: number[] = [];

	public get utilisateurs() {
		return [...this._utilisateurs];
	}

	public get mesUtilisateurs() {
		return [...this._mesUtilisateurs];
	}

	public get mesMagId() {
		if (!this._mesMagId.length) {
			this._mesMagId = this.authService.user!.utilisateurs_magasins.map((one: Magasin) => { return one.mag_id; });
		}
		return [...this._mesMagId];
	}

	public get mesGrfId() {
		if (!this._mesGrfId.length) {
			this._mesGrfId = this.authService.user!.groupes_utilisateurs.map((one: GroupeUtilisateur) => { return one.grf_id; });
		}
		return [...this._mesGrfId];
	}

	constructor(
		private authService: AuthService,
		private eventManager: EventManagerService,
		private http: HttpClient,
	) {

	}

	ngOnInit() {
		this.eventManager.registerEvent('logout', this, (args: any) => {
			this.reset();
		});
	}

	ngOnDestroy() {
		this.eventManager.unregisterEvent('logout', this);
	}

	reset() {
		this._utilisateurs = [];
		this._mesUtilisateurs = [];
		this._mesMagId = [];
		this._mesGrfId = [];
	}

	getList(refresh: boolean = false): Observable<any> {
		if (!refresh && !!this._utilisateurs.length) {
			return new Observable<any>((subscriber: Subscriber<any>) => {
				subscriber.next(this.utilisateurs);
				subscriber.complete();
			});
		}
		else {
			let url: string = '/utilisateurs';
			return this.http.get<any>(url)
			.pipe(map(
				(response: any) => {
					this.formatLabels(response);
					this._utilisateurs = response;
					this._mesUtilisateurs = this.filterMine(this._utilisateurs);
					return this.utilisateurs;
				}
			));
		}
	}

	getMesUtilisateurs(refresh: boolean = false) {
		if (!refresh && !!this._mesUtilisateurs.length) {
			return new Observable<any>((subscriber: Subscriber<any>) => {
				subscriber.next(this.mesUtilisateurs);
				subscriber.complete();
			});
		}
		else {
			return this.getList(refresh)
			.pipe(map(
				(response: any) => {
					return this.mesUtilisateurs;
				}
			));
		}
	}

	filterMine(utilisateurs: Utilisateur[]) {
		return utilisateurs.filter((one: Utilisateur) => {
			return intersects(this.mesMagId, one.utilisateurs_magasins) && intersects(this.mesGrfId, one.groupes_utilisateurs);
		});
	}

	formatLabel(utilisateur: Utilisateur) {
		return `${utilisateur.uti_prenom} ${utilisateur.uti_nom} (${utilisateur.uti_email})`;
	}

	formatLabels(utilisateurs: Utilisateur[]) {
		utilisateurs.forEach((one: any) => {
			one.label = this.formatLabel(one);
		});
	}

	checkIfHasRight(droits: string[]|string): boolean {
		let result = false;
		if (droits instanceof Array) {
			for (let i = 0; i < droits.length; i++) {
				result = this.hasRightSingle(droits[i]);
				if (result === true) break;
			}
		}
		else {
			result = this.hasRightSingle(droits);
		}
		return result;
	}

	hasRightSingle(droit: string): boolean {
		const user = this.authService.user;
		if (user) {
			return user.groupes_utilisateurs.some((one: GroupeUtilisateur) => {
				return one.tyg_code == droit;
			});
		}
		return false;
	}

	isAdmin() {
		return this.hasRightSingle('administrateur');
	}

	isGestionnaire() {
		return this.checkIfHasRight(['editeur', 'publicateur', 'administrateur']);
	}

	canPublish() {
		return this.checkIfHasRight(['publicateur', 'administrateur']);
	}

	belongsToGroupeUtilisateur(groupe: string|number|GroupeUtilisateur, utilisateur: Utilisateur|null = this.authService.user): boolean {
		if (utilisateur) {
			let attr: keyof GroupeUtilisateur = 'grf_id';
			if (typeof groupe == 'object') {
				groupe = groupe.grf_id;
			}
			if (typeof groupe == 'string') {
				attr = 'grf_code';
			}
			return utilisateur.groupes_utilisateurs.some((one: GroupeUtilisateur) => {
				return one[attr] == groupe;
			});
		}
		return false;
	}

	belongsToMagasin(magasin: number|Magasin, utilisateur: Utilisateur|null = this.authService.user): boolean {
		if (utilisateur) {
			if (typeof magasin == 'object') {
				magasin = magasin.mag_id;
			}
			return utilisateur.utilisateurs_magasins.some((one: Magasin) => {
				return one.mag_id == magasin;
			});
		}
		return false;
	}

	isCollaborateur(utilisateur: Utilisateur): boolean {
		return intersects(this.mesMagId, utilisateur.utilisateurs_magasins) && intersects(this.mesGrfId, utilisateur.groupes_utilisateurs);
	}

}
