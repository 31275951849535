<p-toolbar class="mb-2 flex-shrink-0" styleClass="pb-0">

	<ng-template pTemplate="left" class="flex-grow-1">
		<div class="p-fluid formgrid grid">
			<div class="field p-inputgroup col-fixed max-width-md">
				<input pInputText type="text" [(ngModel)]="params.search" (ngModelChange)="filter($event)" placeholder="Rechercher..." />
				<button type="button" class="p-inputgroup-addon" icon="pi pi-times" pButton label="" [disabled]="!params.search || !params.search" (click)="reset();"></button>
			</div>
		</div>
	</ng-template>

	<ng-template pTemplate="right">
		<div class="p-fluid formgrid grid">
			<div class="field">
				<button
					type="button"
					pButton
					label="Créer"
					icon="pi pi-plus"
					class="p-button mr-2"
					(click)="showVignetteForm()"
				></button>
			</div>
		</div>
	</ng-template>

</p-toolbar>

<p-table
	#tableVignettes
	[value]="values"
	[loading]="loading"
	[paginator]="true"
	[rows]="rows"
	[showCurrentPageReport]="true"
	[totalRecords]="totalRecords"
	currentPageReportTemplate="{first} à {last} de {{totalRecords | number}} élément(s)"
	[rowsPerPageOptions]="[10,25,50]"
	dataKey="vig_id"
	[globalFilterFields]="['vig_libelle','vig_fichier','vig_mot_clef']"
	styleClass="p-datatable-sm p-datatable-gridlines table-scrollable-hover"
	[autoLayout]="true"
	responsiveLayout="scroll"
	[scrollable]="true"
	scrollHeight="flex"
>
	<ng-template pTemplate="header">
		<tr>
			<th></th>
			<th class="text-center">Nom</th>
			<th class="text-center">Mot clef</th>
			<th class="nowrap text-center">Standard</th>
			<th></th>
		</tr>
	</ng-template>
	<ng-template pTemplate="emptymessage">
		<tr>
			<td class="text-center" colspan="100">Aucun résultat pour ces critères</td>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-vignette>
		<tr class="ligne-vignette" uiSrefActive="active" [ngClass]="{'vignette-brouillon': !vignette.res_en_ligne, 'vignette-archive': vignette.res_date_archivage}">
			<td class="nowrap" pFrozenColumn>
				<vignette-display [vignette]="vignette.vig_id"></vignette-display>
			</td>
			<td class="vignette-titre" pFrozenColumn>
				<div class="cursor-pointer" (click)="showVignetteForm(vignette)">{{vignette.vig_libelle}}</div>
				<div class="text-sm" *ngIf="vignette.vig_libelle != vignette.vig_fichier">{{vignette.vig_fichier}}</div>
			</td>

			<td class="text-center">
				{{vignette.vig_mot_clef}}
			</td>

			<td class="text-center">
				<i class="pi pi-check" *ngIf="vignette.vig_standard"></i>
			</td>


			<td class="text-left nowrap" pFrozenColumn alignFrozen="right">
				<button
					type="button"
					pButton
					icon="pi pi-pencil"
					class="p-button-rounded p-button-text mr-2"
					(click)="showVignetteForm(vignette)"
				></button>
				<button
					type="button"
					pButton
					icon="pi pi-trash"
					class="p-button-rounded p-button-text p-button-danger"
					(click)="delete(vignette)"
				></button>
			</td>
		</tr>
	</ng-template>
</p-table>

