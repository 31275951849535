import { HttpParams } from '@angular/common/http';
import { TreeNode } from 'primeng/api';

export const isNumeric = function (value: any): boolean {
	value = typeof(value) === 'string' ? value.replace(',', '.') : value;
	return !isNaN(parseFloat(value)) && isFinite(value) && Object.prototype.toString.call(value).toLowerCase() !== '[object array]';
};

export const isNumber = function (value: any): boolean {
	return typeof value === 'number' && isFinite(value);
}

export const isDateString = function(value: any): boolean {
	let parsed = Date.parse(value);
	return isNaN(value) && !isNaN(parsed);
};

export const isDate = function(value: any): boolean {
	return value instanceof Date || isDateString(value);
}

export const isObject = function(value: any): boolean {
  return value !== null && typeof value === 'object' && Array.isArray(value) === false;
}

export const isDefined = function(value: any): boolean {
	return typeof value != 'undefined';
}

export class ExtensibleObject {
	[key: string]: any
}

export const networkAvailable = function(): boolean {
	// if(window.cordova) {
	// 	return $cordovaNetwork.isOnline();
	// }
	// else{// otherwise we're on a desktop web browser
		return window.navigator.onLine;
	// }
};

export const isEmpty = function(value: any): boolean {
	if (typeof value == 'undefined' || value === null) return true;
	for (let i in value) {
		return false;
	}
	return true;
};

export const simpleComparison = function(objA: Object, objB: Object): boolean {
	return JSON.stringify(objA) === JSON.stringify(objB);
};

export const addOrRemoveTrailingOrLeadingChars = function(str: string, chars: string = '/', remove: boolean = false, leading: boolean = false): string {
	let candidate: string;
	let result: string = str;
	let charsLength: number = chars.length;
	if (leading) {
		candidate = str.slice(0, charsLength);
		if (remove) {
			if (candidate == chars) result = str.slice(charsLength);
		}
		else {
			if (candidate != chars) result = `${chars}${str}`;
		}
	}
	else {
		candidate = str.slice(-charsLength);
		if (remove) {
			if (candidate == chars) result = str.slice(0, -charsLength);
		}
		else {
			if (candidate != chars) result = `${str}${chars}`;
		}
	}
	return result;
}

export const addLeadingChars = function(str: string, chars?: string): string {
	return addOrRemoveTrailingOrLeadingChars(str, chars, false, true);
};

export const removeLeadingChars = function(str: string, chars?: string): string {
	return addOrRemoveTrailingOrLeadingChars(str, chars, true, true);
};

export const addTrailingChars = function(str: string, chars?: string): string {
	return addOrRemoveTrailingOrLeadingChars(str, chars, false);
};

export const removeTrailingChars = function(str: string, chars?: string): string {
	return addOrRemoveTrailingOrLeadingChars(str, chars, true);
};

export const addTrailingSlash = function(str: string): string {
	return addOrRemoveTrailingOrLeadingChars(str)
};

export const removeTrailingSlash = function(str: string): string {
	return addOrRemoveTrailingOrLeadingChars(str, undefined, true);
};

export const extractFileExtension = function(filename: string, keepDot?: boolean): string {
	let dot = filename.lastIndexOf('.');
	let adjust = (keepDot)? 0 : 1;
	return (dot > -1)? filename.substr(dot + adjust).toLowerCase() : '';
};

export const extractBasename = function(filename: string): string {
	let dot = filename.lastIndexOf('.');
	return (dot > -1)? filename.substr(0, dot) : filename;
};

export const extractSlug = function(url: string): string {
	let str = (typeof url != 'undefined')? url : location.pathname;
	return removeTrailingSlash(str.substring(str.lastIndexOf('/', str.length - 2) + 1));
}

export const iOS = function(): boolean {
	var iDevices = [
		'iPad Simulator',
		'iPhone Simulator',
		'iPod Simulator',
		'iPad',
		'iPhone',
		'iPod'
	];
	if (!!navigator.platform) {
		while (iDevices.length) {
			if (navigator.platform === iDevices.pop()) { return true; }
		}
	}
	return false;
};

export const isChrome = function(): boolean {
	// please note,
	// that IE11 now returns undefined again for window.chrome
	// and new Opera 30 outputs true for window.chrome
	// but needs to check if window.opr is not undefined
	// and new IE Edge outputs to true now for window.chrome
	// and if not iOS Chrome check
	// @ts-ignore
	let isChromium = window.chrome;
	let winNav = window.navigator;
	let vendorName = winNav.vendor;
	// @ts-ignore
	let isOpera = typeof window.opr != 'undefined';
	let isIEedge = winNav.userAgent.indexOf('Edg') > -1;
	let isIOSChrome = winNav.userAgent.match('CriOS');

	if (isIOSChrome) {
		// is Google Chrome on IOS
		return false;
	} else if(
		typeof isChromium != 'undefined' &&
	  isChromium !== null &&
	  vendorName === 'Google Inc.' &&
	  isOpera === false &&
	  isIEedge === false
	) {
		// is Google Chrome
		return true;
	} else {
	 	// not Google Chrome
	 	return false;
	}
}

export const hasTouch = function(): boolean {
return 'ontouchstart' in document.documentElement
	|| navigator.maxTouchPoints > 0;
};

export const translateFilesizeToBytes = function(str: string|number): number|null {
	if (typeof str === 'string') {
		if (/^\d+k[bo]?/i.test(str)) {
			return (parseFloat(str) * 1024);
		}
		else if (/^\d+m[bo]?/i.test(str)) {
			return (parseFloat(str) * 1048576);
		}
		else if (/^\d+g[bo]?/i.test(str)) {
			return (parseFloat(str) * 1073741824);
		}
		if (isNumeric(str)) {
			return parseFloat(str);
		}
		return null
	}
	return str;
};

export const uid = function(): string {
		let d = new Date().getTime();
		let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
			let r = (d + Math.random()*16)%16 | 0;
			d = Math.floor(d/16);
			return (c=='x' ? r : (r&0x3|0x8)).toString(16);
		});
		return uuid;
	};

export const clone = function(obj: any): any {
	// Handle the 3 simple types, and null or undefined
	if (null == obj || 'object' != typeof obj) return obj;
	// Handle Date
	if (obj instanceof Date) {
		let copy: Date = new Date();
		copy.setTime(obj.getTime());
		return copy;
	}
	// Handle Array
	if (obj instanceof Array) {
		let copy: any[] = [];
		for (let i = 0, len = obj.length; i < len; i++) {
			copy[i] = clone(obj[i]);
		}
		return copy;
	}
	// Handle Object
	if (obj instanceof Object) {
		let copy: any = {};
		for (let attr in obj) {
			if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
		}
		return copy;
	}
	throw new Error('Unable to copy obj! Its type isn\'t supported: ' + typeof obj);
}

export const simpleArrayFilter = function(arrayOfObj: any[], attr: string, value: any): any[] {
	const results: any[] =  [];
	if (!!!value) return arrayOfObj;
	arrayOfObj.forEach(one => {
		if (one.hasOwnProperty(attr) && one[attr].toLowerCase().indexOf(value.toLowerCase()) >= 0) {
			results.push(one);
		}
	});
	return results;
}

export const numericSort = function(array: number[]|any[], attr?: string): void {
	array.sort((a, b) => {
		if (attr) {
			if (a[attr] < b[attr]) return -1;
			if (a[attr] > b[attr]) return 1;
		}
		else {
			if (a < b) return -1;
			if (a > b) return 1;
		}
		return 0;
	});
}

export const stringSort = function(array: any[], attr?: string): void {
	array.sort((a, b) => {
		if (attr) {
			if (!a[attr] && b[attr]) return -1;
			if (!a[attr] && !b[attr]) return 0;
			if (a[attr] && !b[attr]) return 1;
			return a[attr].localeCompare(b[attr]);
		}
		else {
			if (!a && b) return -1;
			if (!a && !b) return 0;
			if (a && !b) return 1;
			return a.localeCompare(b);
		}
	});
}

export const hasDuplicates = function(array: any[]): boolean {
	let duplicates = array.reduce((acc, currentValue, index, dups) => {
		if(dups.indexOf(currentValue) != index && !acc.includes(currentValue)) acc.push(currentValue);
		return acc;
	}, []);
	return !!duplicates.length;
}

export const padString = function(str: string, pad: string, length: number, toRight?: boolean): string {
	if (toRight) {
		return (str.length < length)? padString(str + pad, pad, length, toRight) : str;
	}
	return (str.length < length)? padString(pad + str, pad, length) : str;
}

export const capitalize = function(str: string|null, allWords?: boolean): string|null {
	if (str) {
		if (allWords) {
			return str.split(' ').map(x => x[0].toUpperCase() + x.substr(1)).join(' ');
		}
		return str.charAt(0).toUpperCase() + str.substr(1);
	}
	return null;
}

export const simpleEmailValidation = function(email: string): boolean {
	const re = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA_Z0-9-]*[a-zA-Z0-9])?$/;
	return re.test(String(email).toLowerCase());
}

export const startOfMonth = function(aDate: Date): Date {
	return startOrEndOfMonth(aDate);
}

export const endOfMonth = function(aDate: Date): Date {
	return startOrEndOfMonth(aDate, true);
}

export const startOrEndOfMonth = function(aDate: Date, end?: boolean): Date {
	if (end) {
		return new Date(aDate.getFullYear(), aDate.getMonth() + 1, 0);
	}
	return new Date(aDate.getFullYear(), aDate.getMonth(), 1);
}

export const startOfDay = function(aDate: Date): Date {
	return startOrEndOfDay(aDate);
}

export const endOfDay = function(aDate: Date): Date {
	return startOrEndOfDay(aDate, true);
}

export const startOrEndOfDay = function(aDate: Date, end?: boolean): Date {
	let tmp = new Date(aDate);
	if (end) tmp.setHours(23,59,59,999);
	else tmp.setHours(0,0,0,0);
	return tmp;
}

export const arrayOfMonths = function(year: number, x: number, start?: number): Date[] {
	let offset = 0;
	if (start) {
		offset = start;
	}
	return Array.from({length: x}, (v, k) => {
		return new Date(year, k + offset);
	});
}

export const monthFromNumber = function(month: number): Date {
	const months = arrayOfMonths(1970, 12);
	return months[month -1]
}

export const simpleUTCDateToString = function(aDate: Date|string, short?: boolean, dmy?: boolean): string {
	if (aDate instanceof Date) {
		let d = padString(aDate.getUTCDate().toString(), '0', 2);
		let m = padString((aDate.getUTCMonth() + 1 ).toString(), '0', 2);
		let y = aDate.getUTCFullYear().toString();
		if (short) {
			if (dmy) {
				return `${d}/${m}/${y}`;
			}
			return `${y}-${m}-${d}`;
		}
		return `${y}-${m}-${d}T00:00:00Z`;
	}
	return aDate;
}

export const simpleUTCDateTimeToString = function(aDate: Date|string, dmy?: boolean): string {
	if (aDate instanceof Date) {
		let d = padString(aDate.getUTCDate().toString(), '0', 2);
		let m = padString((aDate.getUTCMonth() + 1).toString(), '0', 2);
		let y = aDate.getUTCFullYear().toString();
		let h = padString((aDate.getUTCHours()).toString(), '0', 2);
		let min = padString((aDate.getUTCMinutes()).toString(), '0', 2);
		let s = padString((aDate.getUTCSeconds()).toString(), '0', 2);
		let datePart ;
		datePart = (dmy)? `${d}/${m}/${y}` : `${y}-${m}-${d}`;
		return `${datePart}T${h}:${min}:${s}Z`;
	}
	return aDate;
}

export const simpleDateToString = function(aDate: Date|string, short?: boolean, dmy?: boolean, utc?: boolean): string {
	if (utc) return simpleUTCDateToString(aDate, short, dmy);
	if (aDate instanceof Date) {
		let d = padString(aDate.getDate().toString(), '0', 2);
		let m = padString((aDate.getMonth() + 1 ).toString(), '0', 2);
		let y = aDate.getFullYear().toString();
		if (short) {
			if (dmy) {
				return `${d}/${m}/${y}`;
			}
			return `${y}-${m}-${d}`;
		}
		let h = padString((aDate.getHours()).toString(), '0', 2);
		let min = padString((aDate.getMinutes()).toString(), '0', 2);
		let s = padString((aDate.getSeconds()).toString(), '0', 2);
		return `${y}-${m}-${d}T${h}:${min}:${s}`;
	}
	return aDate;
}

export const dateUTCToDateCurrentTimezone = function(aDate: Date): Date|null {
	if (aDate) {
		return new Date(aDate.getUTCFullYear(), aDate.getUTCMonth(), aDate.getUTCDate());
	}
	return null;
}

export const calculateMonthPriorDay = function(currentDate: Date, end: Date, dayNumber?: number): Date {
	const tippingDay = (dayNumber && dayNumber > 0)? dayNumber : 15;
	if (currentDate < end) {
		let tippingDate = new Date(currentDate);
		tippingDate.setDate(tippingDay);
		tippingDate = startOfDay(tippingDate);
		// if current date is after tipping date, take the month that's before tipping date
		if (currentDate < tippingDate) {
			end = startOfMonth(currentDate);
			end.setDate(0);
		}
		else end = endOfMonth(currentDate);
	}
	return end;
}

export const downloadUrl = function(url: string, filename?: string): void {
	let a = document.createElement('a');
	document.body.appendChild(a);
	a.setAttribute('style', 'display: none');
	a.href = url;
	a.download = (filename)? `${filename}` : '';
	a.click();
	window.URL.revokeObjectURL(url);
	a.remove();
}

export const getFilenameFromHttpResponse = function(httpResponse: any): string {
	let contentDispositionHeader = httpResponse.headers.get('Content-Disposition');
	let result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
	return result.replace(/"/g, '');
}

export const createDownloadFromHttpResponse = function(httpResponse: any): void {
	const url = window.URL.createObjectURL(new Blob([httpResponse.body]));
	const filename = getFilenameFromHttpResponse(httpResponse);
	downloadUrl(url, filename);
}

export const copyTextToClipboard = function(content: string): boolean {
	let el = document.createElement('textarea');
	document.body.appendChild(el);
	el.setAttribute('style', 'display: none');
	el.setAttribute('id', 'copy');
	el.value = content;
	try {
		let iosCopyToClipboard = function(el: any) {
			let oldContentEditable = el.contentEditable,
				oldReadOnly = el.readOnly,
				range = document.createRange();
			el.contentEditable = true;
			el.readOnly = false;
			range.selectNodeContents(el);
			let s = window.getSelection();
			if (s) {
				s.removeAllRanges();
				s.addRange(range);
			}
			el.setSelectionRange(0, 999999);
			el.contentEditable = oldContentEditable;
			el.readOnly = oldReadOnly;
			document.execCommand('copy');
		};
		let copyListener = function(event: any) {
			document.removeEventListener('copy', copyListener, true);
			event.preventDefault();
			let clipboardData = event.clipboardData;
			clipboardData.clearData();
			clipboardData.setData('text/plain', content);
			clipboardData.setData('text/html', content);
		};
		iosCopyToClipboard(document.getElementById('copy')); // iOS workaround
		document.addEventListener('copy', copyListener, true);
		document.execCommand('copy');
	}
	catch(e) {
		console.log('could not copy to clipboard', e);
		return false;
	}
	finally{
		el.remove();
	}
	return true;
}

export const prepareQueryParams = function(event?: any, forDownload?: boolean) {

	let params: HttpParams = new HttpParams();
	let options: {[key: string]: any} = {};

	function addIfNotNull(key: string, value: any) {
		if (typeof value != 'undefined' && value != null) {
			if (Array.isArray(value) && value.length) {
				if (value.length === 1) {
					params = params.append(key, value[0]);
				}
				else {
					// params = params.append(`${key}[]`, JSON.stringify(value));
					let index: number = 0;
					for (let i = 0; i < value.length; i++) {
						if (typeof value[i] != 'undefined' && value[i] != null) {
							addIfNotNull(`${key}[]`, value[i]);
							index++;
						}
					}
				}
			}
			else {
				params = params.append(key, value);
			}
		}
	}

	if (event) {
		for (let prop in event) {
			switch (prop) {
				case 'multiSortMeta':
				case 'isTrusted':
				case 'forceUpdate':
					continue;
				case 'first':
					addIfNotNull('offset', event[prop]);
					break;
				case 'rows':
					addIfNotNull('limit', event[prop]);
					break;
				case 'sortField':
					addIfNotNull('order_by', event[prop]);
					break;
				case 'sortOrder':
					addIfNotNull('order', (event[prop] == 1)? 'ASC' : 'DESC');
					break;

				case 'filters':
					for (let field in event.filters) {
						if (field === 'global') continue;
						if (typeof event.filters[field].value != 'undefined' && event.filters[field].value != null) {
							addIfNotNull(field, event.filters[field].value)
						}
					}
					break;
				default:
					addIfNotNull(prop, event[prop]);
					break;
			}
		}
	}

	options['params'] = params;
	if (forDownload) {
		options['responseType'] = 'blob';
		options['observe'] = 'response';
	}
	return options;
}

export const convertDateFields = function(obj: any, fields: string[], toDate: boolean = false, utc: boolean = false, short: boolean = false){
	for (let attr in obj) {
		if (obj.hasOwnProperty(attr)) {
			if (obj[attr] instanceof Object) {
				convertDateFields(obj[attr], fields, toDate, utc);
			}
			if (obj[attr] instanceof Array) {
				for (let i = 0, len = obj[attr].length; i < len; i++) {
					if (obj[attr][i] instanceof Object) {
						convertDateFields(obj[attr][i], fields, toDate, utc);
					}
				}
			}
			else {
				if (fields.indexOf(attr) > -1) {
					if (toDate && isDateString(obj[attr])) {
						if (!utc && obj[attr].indexOf('+') > -1) {
							// remove timezone
							obj[attr] = obj[attr].substr(0, obj[attr].indexOf('+'));
						}
						obj[attr] = new Date(obj[attr]);
					}
					else if (!toDate && obj[attr] instanceof Date) {
						obj[attr] = simpleDateToString(obj[attr], short, false, utc);
					}
				}
			}
		}
	}
}

export const convertDateFieldsToDate = function(obj: any, fields: string[], utc: boolean = false, short: boolean = false): void {
	return convertDateFields(obj, fields, true, utc, short);
}

export const convertDateFieldsToString = function(obj: any, fields: string[], utc: boolean = false, short: boolean = false): void {
	return convertDateFields(obj, fields, false, utc, short);
}

export const cssVarValue = function(varName: string) {
	return getComputedStyle(document.documentElement).getPropertyValue(varName);
}

export const listToTree = function(
	list: any[],
	attribute_id: string,
	attribute_parent_id: string,
	parent_id: any,
	attribute_children: string = 'children',
	as_tree_node: boolean = false,
	attribute_label: string = 'label',
	depth: number = 1,
	itemFormatter?: Function
): any[] {
	let tree: any[] = [];
	list.forEach((item: any) => {
		if (item[attribute_parent_id] == parent_id) {
			let children = listToTree(list, attribute_id, attribute_parent_id, item[attribute_id], attribute_children, as_tree_node, attribute_label, depth +1, itemFormatter);
			if (as_tree_node) {
				let node: any = {
					key: item[attribute_id],
					label: item[attribute_label],
					data: Object.assign({depth: depth}, item),
					depth: depth
				}
				if (!children.length) {
					node['leaf'] = true;
				}
				node[attribute_children] = children;
				if (typeof itemFormatter != 'undefined') {
					itemFormatter(node);
				}
				tree.push(node);
			}
			else {
				if (!children.length) {
					item['leaf'] = true;
				}
				item[attribute_children] = children;
				if (typeof itemFormatter != 'undefined') {
					itemFormatter(item);
				}
				tree.push(Object.assign({depth: depth}, item));
			}
		}
	});
	return tree;
}

export const treeToList = function(node: any|any[], attribute_children: string = 'children', attribute_data?: string) {
	let result: any[] = [];
	if (Array.isArray(node)) {
		node.forEach((one: any) => {
			result = result.concat(treeToList(one, attribute_children, attribute_data));
		});
	}
	else {
		let tmp: any = (attribute_data)? Object.assign(node[attribute_data]) : Object.assign(node);
		delete tmp[attribute_children];
		result.push(tmp);
		if (node[attribute_children]) {
			node[attribute_children].forEach((one: any) => {
				result = result.concat(treeToList(one, attribute_children, attribute_data));
			});
		}
	}
	return result;
}

export const expandAllTreeNode = function(node: TreeNode|TreeNode[]) {
	if (Array.isArray(node)) {
		node.forEach((one: TreeNode) => {
			expandAllTreeNode(one);
		});
	}
	else {
		if (node.children) {
			node.children.forEach((one: TreeNode) => {
				expandAllTreeNode(one);
			});
		}
		node.expanded = true;
	}
}

export const arrayOfObjectsToArrayOfValues = function(arrayOfObjects: any[], attribute: string): any[] {
	if (!Array.isArray(arrayOfObjects)) return [];
	let result: any[] = [];
	arrayOfObjects.forEach((item: any) => {
		if (typeof item[attribute] != 'undefined') {
			result.push(item[attribute]);
		}
	});
	return result;
}


export const findSelectedNodes = function(
	nodes: TreeNode[],
	attribute_id: string,
	keys: any[],
	selectedNodes: TreeNode[],
	node?: TreeNode
): void {
	if (node) {
		if (isSelected(node, attribute_id, keys)) {
			selectedNodes.push(node);
			let keyindex: number = keys.findIndex((one: any) => { return one == node.data[attribute_id]; });
			keys.splice(keyindex, 1);
		}

		if (Object.keys(keys).length && node.children) {
			for (let childNode of node.children) {
				findSelectedNodes(nodes, attribute_id, keys, selectedNodes, childNode);
			}
		}
	}
	else {
		for (let childNode of nodes) {
			findSelectedNodes(nodes, attribute_id, keys, selectedNodes, childNode);
		}
	}
}

export const isSelected = function(node: TreeNode, attribute_id: string, keys: any[]): boolean {
	return findIndexInSelection(node, attribute_id, keys) != -1;
}

export const findIndexInSelection = function(node: TreeNode, attribute_id: string, keys: any[]) {
	let index: number = -1;
	for (let i = 0; i < keys.length; i++) {
		let selectedNode = keys[i];
		let areNodesEqual: boolean = isNodeEqual(node, attribute_id, selectedNode);
		if (areNodesEqual) {
			index = i;
			break;
		}
	}
	return index;
}

export const isNodeEqual = function(node: TreeNode, attribute_id: string, value: any): boolean {
	return value == node.data[attribute_id];
}

export const countMaxBranchings = function(node: TreeNode, depth: number = 0, deepest: number = 0): number {
	let res: number = depth;
	depth++;
	if (Array.isArray(node)) {
		for (let i = 0; i < node.length; i++) {
			let childMaxBranching = countMaxBranchings(node[i], depth)
			if (childMaxBranching >= res) {
				res = childMaxBranching;
			}
		}
	}
	else {
		if (node.children && node.children.length) {
			for (let i = 0; i < node.children.length; i++) {
				let childMaxBranching = countMaxBranchings(node.children[i], depth)
				if (childMaxBranching >= res) {
					res = childMaxBranching;
				}
			}
		}
	}
	return res;
}

export const formatNodePath = function(
	item: any,
	items: any[],
	attribute_id: string,
	attribute_parent_id: string,
	formatLabelFunc: Function,
	pathSeparator: string = '/',
) {
	let result: string = '';
	if (!pathSeparator) {
		result = formatLabelFunc(item);
	}
	else {
		let currentItem: any = item;
		while (currentItem && currentItem[attribute_parent_id] !== null) {
			currentItem = items.find((one: any) => {return currentItem[attribute_parent_id] == one[attribute_id]; });
			if (currentItem) {
				let parentLabel: string = formatLabelFunc(currentItem);
				result = `${parentLabel}${pathSeparator}${result}`;
			}
		}
		result += formatLabelFunc(item);
	}
	return result;
}

export const formatNodesPaths = function(
	items: any[],
	attribute_id: string,
	attribute_parent_id: string,
	formatLabelFunc: Function,
	pathSeparator: string = '/',
	attribute_path: string = 'path'
) {
	items.forEach((one: any) => {
		one[attribute_path] = formatNodePath(one, items, attribute_id, attribute_parent_id, formatLabelFunc, pathSeparator);
	});
}

export const slugify = function(str: string): string {
	if (str == null) return '';
	return str
		.replace(/\s|_|\W/g, '-')
		.replace(/-+/g, '-')
		.normalize('NFD').replace(/\p{Diacritic}/gu, '')
		.toLowerCase()
	;
}

export const intersect = function(array1: any[], array2: any[], attr?: string): any[] {
	return array1.filter((item: any) => {
		if (attr) {
			return array2.some((one: any) => { return item[attr] == one[attr]; });
		}
		return array2.indexOf(item) > -1;
	})
	.filter((item: any, index: number, array: any[]) => { // extra step to remove duplicates
		if (attr) {
			return array.findIndex((one: any) => { return item[attr] == one[attr]; }) == index;
		}
		return array.indexOf(item) === index;
	});

}

export const intersects = function(array1: any[], array2: any[], attr?: string): boolean {
	return array1.some((item: any) => {
		if (attr) {
			return array2.some((one: any) => { return item[attr] == one[attr]; });
		}
		return array2.indexOf(item) > -1;
	});
}


export const urlType = function(url: string): string {
	if (url) {
		// special links
		let match = url.match(/^(mailto|tel|export|action):/);
		if (match) {
			return match[1];
		}
		// internal
		if (
			!url.startsWith('http')
			|| url.startsWith(window.location.origin)
		) return 'internal';
		// external
		if (url.startsWith('http')) return 'external';
	}
	return 'unknown';
}

export const formatShortcode = function(props: any, innerContent?: string) {
	let shortcode: string = '';
	for (let prop in props) {
		let value = props[prop];
		if (typeof value == 'undefined' || value === null) continue;
		if (typeof value == 'string') {
			value = `"${value}"`
		}
		shortcode += (!shortcode.length)? '[' : ' ';
		shortcode += `${prop}=${value}`;
	}
	shortcode += ']';
	if (innerContent) {
		let firstProp: string = Object.keys(props)[0];
		shortcode += `${innerContent}[/${firstProp}]`;
	}
	return shortcode;
}


export const stripHTMLTags = function(str: string|HTMLElement): string {
	let tmpNode: HTMLElement = document.createElement('div');
	if (typeof str == 'string') {
		tmpNode.innerHTML = str;
	}
	else {
		tmpNode = str;
	}
	return tmpNode? tmpNode.innerText : '';
}

export const isHTMLEmpty = function(str: string|HTMLElement|null): boolean {
	if (str == null) return true;
	let tmp = stripHTMLTags(str);
	tmp.trim();
	return !!!tmp.length;
}

export const addUnbreakableSpaces = function(str: string): string {
	// add space before some symboles
	str = str.replace(/([a-zA-Z0-9])([:?!])/g, '$1 $2');
	// replace with unbreakable space
	str = str.replace(/ +([;:?!\/])/g, ' $1');
	return str;
}

export const trimHTML = function(str: string|HTMLElement): string|HTMLElement {
	if (!!!str) return '';
	let tmpNode: HTMLElement = document.createElement('div');
	if (typeof str == 'string') {
		tmpNode.innerHTML = str;
	}
	else {
		tmpNode = str;
	}
	let treeWalker: TreeWalker = document.createTreeWalker(tmpNode, NodeFilter.SHOW_ELEMENT);
	let currentNode: Node|null = null;
	let emptyNodes: Node[] = [];

	// find all empty nodes from the beginning and stop on first non-empty node
	currentNode = treeWalker.firstChild();
	while (currentNode) {
		if (!isHTMLEmpty(currentNode as HTMLElement)) {
			break;
		}
		emptyNodes.push(currentNode);
		currentNode = treeWalker.nextNode();
	}

	// find all empty nodes from the end and stop on first non-empty node
	currentNode = treeWalker.lastChild();
	while (currentNode) {
		if (!isHTMLEmpty(currentNode as HTMLElement)) {
			break;
		}
		emptyNodes.push(currentNode);
		currentNode = treeWalker.previousNode();
	}

	// remove found empty nodes
	emptyNodes.forEach((node: Node) => {
		if (node.parentNode) {
			node.parentNode.removeChild(node)
		}
	});

	return (typeof str == 'string')? tmpNode.innerHTML : tmpNode;
}
