import { NgModule, Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { UIRouterModule } from '@uirouter/angular';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { LazyLoadEvent } from 'primeng/api';
import { TableModule, Table } from 'primeng/table';
import { ToolbarModule } from 'primeng/toolbar';

import { Vignette } from '@app/vignette/vignette.model';
import { VignetteService } from '@app/vignette/vignette.service';
import { VignetteDialogComponent } from '@app/vignette/vignette-dialog';
import { GlobalModule } from '@global/global.module';
import { VignetteDisplayModule } from '@app/vignette/vignette-display';

import { EventManagerService } from '@global/event-manager.service';
import { StorageService } from '@global/storage.service';
import { environment } from '@environments/environment';
import { clone, ExtensibleObject } from '@helpers/utils';

@Component({
	selector: 'liste-vignettes',
	templateUrl: './liste-vignettes.html'
})
export class ListeVignettesComponent implements OnInit {

	@ViewChild('tableVignettes', {static: true}) table!: Table;

	values: Vignette[]= [];
	totalRecords: number = 0;
	loading: boolean = false;
	rows: number = environment.default_rows;

	params: ExtensibleObject = {
		search: null,
		standard: null
	}

	constructor(
		private confirmationService: ConfirmationService,
		private dialogService: DialogService,
		private eventManager: EventManagerService,
		private storageService: StorageService,
		private vignetteService: VignetteService,
	) {

	}

	ngOnInit(): void {
		this.getParamSet();
		this.load({});
	}

	getParamSet() {
		let tableParams = this.storageService.getForCurrentState('tableParams', undefined, true);
		if (tableParams) {
			for (let prop in this.params) {
				if (typeof tableParams[prop] != 'undefined') this.params[prop] = tableParams[prop];
			}
			if (tableParams.first) this.table.first = tableParams.first;
			if (tableParams.rows) this.rows = tableParams.rows;
		}
		this.table.rows = this.rows;
	}

	saveParamSet() {
		if (this.table) {
			let tableParams = Object.assign({}, this.params, {
				first: this.table.first,
				rows: this.table.rows
			});
			this.storageService.setForCurrentState('tableParams', tableParams, true);
		}
	}

	reset() {
		this.params.search = null;
		this.filter('');
	}

	refresh(stayOnPage?: boolean) {
		if (!stayOnPage) this.table._first = 0;
		// this.table.onLazyLoad.emit(this.table.createLazyLoadMetadata());
		this.load({});
	}

	load(event: LazyLoadEvent) {
		// https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
		Promise.resolve(null).then(() => this.loading = true);

		// this.saveParamSet();
		this.vignetteService.getCachedList(true)
		.subscribe(
			(response: any) => {
				this.totalRecords = response.length;
				this.values = response;
				this.filter(this.params.search)
			}
		)
		.add(() => { this.loading = false; });
	}

	filter(str: any) {
		this.table.filterGlobal(str, 'contains');
		this.saveParamSet();
	}

	showVignetteForm(vignette?: Vignette) {
		let header: string = 'Nouvelle vignette';
		if (vignette) {
			vignette = clone(vignette);
			header = 'Modification de vignette';
		}
		else {
			vignette = new Vignette();
		}

		const ref = this.dialogService.open(VignetteDialogComponent, {
			data: {
				vignette: vignette
			},
			header: header,
			width: '50%'
		});

		ref.onClose.subscribe(dialogResult => {
			if (dialogResult) {
				this.refresh()
			}
		});
	}

	delete(vignette: Vignette) {
		let message = `Souhaitez-vous vraiment supprimer la vignette ${vignette.vig_libelle} ?`;
		this.confirmationService.confirm({
			defaultFocus: 'reject',
			message: message,
			accept: () => {
				this.vignetteService.delete(vignette.vig_id)
				.subscribe(
					(response: any) => {
						this.eventManager.emit('toast', {severity: 'success', summary: 'Vignette supprimée'});
						this.refresh();
					}
				)
			}
		});
	}



}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		UIRouterModule,
		ButtonModule,
		CheckboxModule,
		InputTextModule,
		TableModule,
		ToolbarModule,
		GlobalModule,
		VignetteDisplayModule,
	],
	exports: [ListeVignettesComponent],
	declarations: [ListeVignettesComponent]
})
export class ListeVignettesModule { }

