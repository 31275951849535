import { UIRouter, Category } from '@uirouter/core';
// import { Visualizer } from '@uirouter/visualizer';

import * as hooks from '@global/app-routing.hooks';

export function routerConfigFn(uiRouter: UIRouter) {
	const transitionService = uiRouter.transitionService;
	const stateService = uiRouter.stateService;
	const urlService = uiRouter.urlService;
	const globals = uiRouter.globals;

	// register transition hooks
	Object.values(hooks).map((hook: any) => hook(transitionService));

	// allow optional trailing slash on urls. Easier redirectTo this way
	// uiRouter.urlMatcherFactory.strictMode(false);

	// declare a custom "path fragment" state param type,
	// to make sure string params don't get bundled up in one big string...
	urlService.config.type('pathFragment', {
		raw: true,
		encode: (link: string): string => {
			return encodeURIComponent(link);
		},
		decode: (link: string): string => {
			return decodeURIComponent(link);
		},
		pattern: /[^/]*/,
		is: (link: string): boolean => {
			return typeof link == 'string';
		},
		equals: (link: string, link2: string): boolean => {
			return link == link2;
		}
	});


	stateService.defaultErrorHandler(function(error: any) {
		setTimeout(function(){
			console.log('stateService error handler', error, stateService);
			// todo gérer correctement les types 6
			// RejectType.SUPERSEDED (2)
			// RejectType.ABORTED (3)
			// RejectType.INVALID (4)
			// RejectType.IGNORED (5)
			// RejectType.ERROR (6)
			if (error && error.type != 2) {
				// stay on current state
				if (globals.current.name) stateService.go(globals.current.name);
				// otherwise, return to home
				else stateService.go('home', undefined, {reload: true});
			}
		}, 5000);
	})

	// uiRouter.trace.enable(Category.TRANSITION);
	// uiRouter.plugin(Visualizer);
}
