import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from "@angular/platform-browser";

import { UIRouter, Transition } from '@uirouter/core';

import { MessageService } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { ScrollTop } from 'primeng/scrolltop'

import { IEventListener, EventManagerService } from '@global/event-manager.service';
import { localeFR } from '@global/locale-fr';

import { AnalyticsService } from '@global/analytics.service'
import { uid } from '@helpers/utils';
import { environment, enseigne } from '@environments/environment';

import { StateUtilsService } from '@helpers/state-utils.service';

import { Crumb } from '@helpers/crumb';

// Add `breadcrumb` to typescript definitions for StateObject and StateDeclaration
declare module "@uirouter/core/lib/state/stateObject" {
	interface StateObject {
		breadcrumb?: (trans: Transition) => string;
	}
}

declare module "@uirouter/core/lib/state/interface" {
	interface StateDeclaration {
		breadcrumb?: (trans: Transition) => string;
	}
}


@Component({
	selector: 'app',
	template: `
	<p-toast [preventOpenDuplicates]="true"></p-toast>
	<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" styleClass="col-12 md:col-6" appendTo="body"></p-confirmDialog>
	<ui-view class="{{appClass}}"></ui-view>
	<p-scrollTop #scrolltop></p-scrollTop>
	`,
	providers: [ MessageService ]
})
export class AppComponent implements OnInit, IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	@ViewChild('scrolltop', {static: true}) scrolltop!: ScrollTop;

	private crumbs: Crumb[] = [];

	baseTitle: string = environment[enseigne].title;
	appClass: string = '';

	constructor(
		private analyticsService: AnalyticsService,
		private eventManager: EventManagerService,
		private messageService: MessageService,
		private primeNGConfig: PrimeNGConfig,
		private titleService: Title,
		private uiRouter: UIRouter,
		private stateUtilsService: StateUtilsService
	) {
		this.updateTitle(uiRouter.globals.successfulTransitions.peekTail());
		this.updateAppClass(uiRouter.globals.successfulTransitions.peekTail());

		this.uiRouter.transitionService.onSuccess({}, (transition: Transition) => {
			this.updateTitle(transition);
			this.updateAppClass(transition);
			// this.analyticsService.trackEvent('page_view');
		});

		this.eventManager.registerEvent('logout', this, (args: any) => {
				this.resetTitle();
			}
		);

		this.eventManager.registerEvent('toast', this, (args: any) => {
				this.toast(args);
			}
		);

		this.eventManager.registerEvent('scroll-top', this, (args: any) => {
				this.scrolltop.onClick();
			}
		);
	}

	ngOnInit() {
		this.primeNGConfig.setTranslation(localeFR);
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('toast', this);
		this.eventManager.unregisterEvent('logout', this);
		this.eventManager.unregisterEvent('scroll-top', this);
	}

	private updateTitle(trans: Transition) {
		let tmp = trans.treeChanges('to');
		this.crumbs = (!tmp)? [] : tmp.filter((node: any) => node.state.breadcrumb)
			.map((node: any) => {
				return {
					state: node.state,
					label: node.state.breadcrumb?.(trans)
				} as Crumb
			});
		let titleParts: string[] = this.crumbs
			.filter((one: Crumb) => { return one.label != 'Dashboard'; })
			.map((one: Crumb) => { return one.label; });
		this.setTitle(titleParts);
	}

	private updateAppClass(trans: Transition) {
		let tmp = trans.treeChanges('to');
		if (!tmp.length) {
			this.appClass = '';
		}
		else {
			const stateName: string = tmp.slice(-1)[0].state.name;
			this.appClass = `state-${stateName}`;
		}

	}

	public setTitle(parts: string[]) {
		let title = [...parts, this.baseTitle].join(' | ');
		this.titleService.setTitle(title);
	}

	public resetTitle() {
		this.titleService.setTitle(this.baseTitle);
	}

	private toast(toast: any): void {
		if (toast instanceof Array) {
			toast.forEach(one => this.prepareToast(one));
		}
		else {
			this.prepareToast(toast);
		}
		this.messageService.add(toast);
	}

	private prepareToast(toast: any): void {
		toast.severity = toast.severity || 'info';
		if (toast.severity == 'error') toast.life = 10000;
	}

	logout() {
		this.eventManager.emit('logout', {manual: true});
	}
}
